import React, { memo } from 'react';
import styled from "styled-components";
import Clock from "./Clock";
import { navigate } from '@reach/router';
import UserImage from '../components/UserImage'
import NftImage from '../components/NftImage'
import { useMoralisCloudFunction } from "react-moralis";
import { createGlobalStyle } from 'styled-components';
import { Link } from '@reach/router';

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

//react functional component
const NftCard = ({ nft, className = 'd-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4', clockTop = true, height, onImgLoad, theme }) => {

    const dark = theme;
    const GlobalStyles = createGlobalStyle`
    h1, h2, h4, h3, h5, h6, .h1_big, .h1, .h2, .h3, .h4, .h5, .h6, span, p {
        color: ${dark === 'true' ? "#202225" : "white"}
    }
    .nft__item {
        border: ${dark === 'true' ? "solid 1px #ddd" : "solid 1px grey"};
    }
    `;



    const { data: dataUnlock } = useMoralisCloudFunction("queryUnlockData",
        {"tokenId": nft.nftLink && nft.nftLink.split("/")[1].split(":")[2], "contractAddress": nft.nftLink && nft.nftLink.split("/")[1].split(":")[1]},
    );


    const navigateTo = (link) => {
        navigate(link);
    }
    console.log(nft)

        return (

            <div className={className}>
                        <GlobalStyles/>
                <Link to={nft.nftLink}>
                <div className="nft__item m-0">
                { nft.item_type === 'single_items' ? (
                 <div className='icontype'><i className="fa fa-bookmark"></i></div>   
                 ) : (  
                 <div className='icontype'><i className="fa fa-shopping-basket"></i></div>
                    )
                }
                    { nft.deadline && clockTop &&
                        <div className="de_countdown">
                            <Clock deadline={nft.deadline} />
                        </div>
                    }
                    <div className="author_list_pp">
                        <Link to={nft.authorLink}>
                            {nft.author && <UserImage address={nft.author}/>} 
                        </Link>
                    </div>
                    <div className="nft__item_wrap" style={{height: `250px`}}>
                    <Outer>
                        {<NftImage previewImg={nft.previewImg} preview={nft.preview}/>}
                    </Outer>
                    </div>
                    { nft.deadline && !clockTop &&
                        <div className="de_countdown">
                            <Clock deadline={nft.deadline} />
                        </div>
                    }
                    {(nft.hasAdditionalMetadata || (dataUnlock && dataUnlock[0])) && (<div style={
                                {
                                    padding: "15px", 
                                    backgroundColor: "#F6F6F6", 
                                    borderRadius: "25px", 
                                    border: "1px solid black",
                                    height: "55px",
                                    width: "100%",
                                    overflow: "scroll",
                                    fontSize: "15px"
                                }}><p><i className="fa fa- fa-unlock-alt id-color-2 mr10" style={{marginRight: "5px", color: "black"}}></i><span style={{"font-weight": "bold", "color": "black"}}>Includes unlockable content</span></p></div>)}
                    <div className="nft__item_info">
                        <Link to={nft.nftLink}>
                            <h4>{nft.title}</h4>
                        </Link>
                        { nft.status === 'has_offers' ? (
                                <div className="has_offers">
                                    <span className='through'>{nft.priceover}</span> {nft.price}
                                </div> 
                            ) : (
                                !nft.soldOut ? (<div className="nft__item_price">
                                    <span style={{marginLeft: "0px"}}>{nft.price ? `${nft.price} ${nft.token}` : null}</span> {nft.price && <span id="itemRangePreview">1/{nft.editions}</span>}
                                    { nft.status === 'on_auction' && 
                                        (nft.bid > 0 ? <><div>Best Offer: {nft.bid} {nft.token}<span>1/{nft.editions}</span></div></> : <><div>No offers yet.</div></>)
                                    }
                                </div>) : <></>
                            )
                        }
                        {!nft.soldOut ? <div className="nft__item_action">
                            <span onClick={() => navigateTo(nft.bidLink)}>{ nft.status ? (nft.status === 'on_auction' ? 'Place a bid' : 'Buy Now') : 'View Item'}</span>
                        </div> : <div className="nft__item_action">
                            <span onClick={() => navigateTo(nft.bidLink)}>{ 'SOLD OUT' }</span>
                        </div>}
                        <div className="nft__item_like">
                            <i className="fa fa-heart"></i><span>{nft.likes}</span>
                        </div>     
                    </div> 

                                
                </div>
                </Link>
            </div>    
        );
 
};

export default memo(NftCard);