import React from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import { Link } from '@reach/router';

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const SliderRewards = () => (
 <div className="container">
    <div className="row align-items-center">
    <div className="col-md-6 xs-hide">
            <Reveal className='onStep' keyframes={fadeIn} delay={900} duration={1500} triggerOnce>
              <img src="./img/cryptocurrency_staking_portal_50f3a1ff41.png" className="lazy img-fluid" alt=""/>
            </Reveal>
          </div>
          <div className="col-md-6">
              <div className="spacer-single"></div>
              <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
              <h6 className=""><span style={{color: "#EA14BD"}}>REWARDS</span></h6>
              </Reveal>
              <div className="spacer-10"></div>
              <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600} triggerOnce>
              <h1 className="">Earn with $PLSV</h1>
              </Reveal>
              <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={600} triggerOnce>
              <p className=" lead">
                $PLSV is the native token of PulseVerse, PulseVerse has a platform fee of 2.5%. 1.5% of these platform fees could go to the stakers of $PLSV and 0.5% for buy-back and burn. Then, 0.5% to a wallet with no expectations. In addition, we will be holding special events from time to time where token holders can benefit from discounts on platform fees if they meet certain requirements. We also have future use cases planned for this token.
              </p>
              </Reveal>
              <div className="spacer-10"></div>
              <Reveal className='onStep' keyframes={fadeInUp} delay={800} duration={900} triggerOnce>
              <Link to={`/#`} className="btn-main lead">Open Dashboard</Link>
              <div className="mb-sm-30"></div>
              </Reveal>
          </div>

      </div>
    </div>
);
export default SliderRewards;