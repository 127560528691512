import React, { useState, useEffect } from 'react';
import { ThemeContext, themes } from './ThemeContext';

export default function ThemeContextWrapper(props) {
  let dark = localStorage.getItem('darkMode');
  const [theme, setTheme] = useState(themes.light);

  function changeTheme(theme) {
    setTheme(theme);
  }

  useEffect(() => {
    // alert(theme)
    switch (theme) {
      case themes.light:
        document.body.classList.add('white-content');
        document.getElementById("myHeader").classList.add('blacker');
        document.getElementById("myHeader").classList.remove('white-nav');
        if (document.getElementById("footer")) {
            document.getElementById("footer").classList.add('black');
            document.getElementById("footer").classList.remove('white');
            document.getElementById("App-logo-bottom").src = `${window.location.origin}/img/logo-wtrans.png`
        }
        var elLight = document.querySelectorAll('h1, h2, h4, h3, h5, h6, .h1_big, .h1, .h2, .h3, .h4, .h5, .h6, p, a, li');
        for(var i=0;i<elLight.length;i++){
            elLight[i].classList.add('white-text');
            elLight[i].classList.remove('black-text');
        }
        var inputsLight = document.querySelectorAll('.form-control, .dropbtn, .dropdownSelect div, .css-wenqhu-control');
        for(let i=0;i<inputsLight.length;i++){
          inputsLight[i].classList.remove('white-important');
          inputsLight[i].classList.add('black-important');
        }
        var spansLight = document.querySelectorAll('span');
        for(let i=0;i<spansLight.length;i++){
          spansLight[i].classList.add('white-text');
          spansLight[i].classList.remove('black-text');
        }  
        var itemsLight = document.querySelectorAll('.nft__item');
        for(let i=0;i<itemsLight.length;i++){
            itemsLight[i].classList.remove('white');
            itemsLight[i].classList.add('black');
            itemsLight[i].classList.add('black-border');
            itemsLight[i].classList.remove('white-border');
        }
        var popLight = document.querySelectorAll('.popshow');
        for(let i=0;i<popLight.length;i++){
            popLight[i].classList.remove('white');
            popLight[i].classList.add('black');
        }
        var menuLight = document.querySelectorAll('.menu-line, .menu-line1, .menu-line2');
        for(let i=0;i<menuLight.length;i++){
            menuLight[i].classList.add('black');
            menuLight[i].classList.remove('white');
        }
        break;
      case themes.dark:
      default:
        document.body.classList.remove('white-content');
        document.getElementById("myHeader").classList.remove('blacker');
        document.getElementById("myHeader").classList.add('white-nav');
        if (document.getElementById("footer")) {
            document.getElementById("footer").classList.add('white');
            document.getElementById("footer").classList.remove('black');
            document.getElementById("App-logo-bottom").src = `${window.location.origin}/img/logo-btrans.png`
        }
        var el = document.querySelectorAll('h1, h2, h4, h3, h5, h6, .h1_big, .h1, .h2, .h3, .h4, .h5, .h6, p, a, li');
        for(let i=0;i<el.length;i++){
            el[i].classList.remove('white-text');
            el[i].classList.add('black-text');
        }
        var inputs = document.querySelectorAll('.form-control, .dropbtn, .dropdownSelect div, .css-wenqhu-control');
        for(let i=0;i<inputs.length;i++){
          inputs[i].classList.add('white-important');
          inputs[i].classList.remove('black-important');
        }
        var spans = document.querySelectorAll('span');
        for(let i=0;i<spans.length;i++){
          spans[i].classList.remove('white-text');
          spans[i].classList.add('black-text');
        }  
        var items = document.querySelectorAll('.nft__item');
        for(let i=0;i<items.length;i++){
            items[i].classList.add('white');
            items[i].classList.remove('black');
            items[i].classList.remove('black-border');
            items[i].classList.add('white-border');
        }
        var pop = document.querySelectorAll('.popshow');
        for(let i=0;i<pop.length;i++){
            pop[i].classList.add('white');
            pop[i].classList.remove('black');
        }
        var menu = document.querySelectorAll('.menu-line .menu-line1 .menu-line2');
        for(let i=0;i<menu.length;i++){
            menu[i].classList.add('white');
            menu[i].classList.remove('black');
        }
        
        break;
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme: theme, changeTheme: changeTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
}