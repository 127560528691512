import React, { memo } from 'react';
import styled from "styled-components";
import Clock from "./Clock";
import { navigate } from '@reach/router';
import { createGlobalStyle } from 'styled-components';
import { Link } from '@reach/router';

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;



//react functional component
const NftCard = ({ nft, className = 'd-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4', clockTop = true, height, onImgLoad, theme }) => {

    const navigateTo = (link) => {
        navigate(link);
    }

    const dark = theme;
        const GlobalStyles = createGlobalStyle`
            .nft__item {
                border: ${dark === 'true' ? "solid 1px #ddd" : "solid 1px grey"};
            }
        `;

    return (
        <Link to={nft.nftLink}>
        <div className={className}>
        <GlobalStyles/>
            <div className="nft__item m-0">
                { nft.deadline && clockTop &&
                    <div className="de_countdown">
                        <Clock deadline={nft.deadline} />
                    </div>
                }
                <div className="nft__item_wrap" style={{height: `${height}px`, "margin-bottom": "35px"}}>
                <Outer>
                    <span>
                        <img onLoad={onImgLoad} style={{"max-height": "300px"}} src={nft.previewImg} className="lazy nft__item_preview" alt=""/>
                    </span>
                </Outer>
                </div>
                { nft.deadline && !clockTop &&
                    <div className="de_countdown">
                        <Clock deadline={nft.deadline} />
                    </div>
                }
                <div className="nft__item_info">
                    <span onClick={() => navigateTo(nft.nftLink)}>
                        <h3 style={{marginBottom: "0px"}}>{nft.title}</h3>
                        <p style={{marginBottom: "20px"}}>owned by {nft.authourName}</p>
                    </span>                   
                </div> 
            </div>
        </div>    
        </Link>         
    );
};

export default memo(NftCard);