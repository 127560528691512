import React from 'react';
import { Link } from '@reach/router';
import { createGlobalStyle } from 'styled-components';

const footer= (props) => {
    let dark = props.theme;

    const GlobalStyles = createGlobalStyle`
        h1, h2, h4, h3, h5, h6, .h1_big, .h1, .h2, .h3, .h4, .h5, .h6, span, p, a, small {
        color: ${dark === 'true' ? "#202225" : "white"}
        }
        .footer-light, .subfooter {
            border-top: solid 1px ${dark === 'true' ? "#eeeeee" : "#2f3033"};
        } 
    `;
 return <footer className="footer-light" id="footer">
     <GlobalStyles/>
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-sm-6 col-xs-1">
                        <div className="widget">
                            <img src={`${window.location.origin}/img/imageedit_1_9065930935.png`} width="50" height="50" style={{marginBottom: "10px"}} alt="footer"></img>
                            <h3 style={{marginBottom: "8px"}}>PulseVerse</h3>
                            <p>Unlock the power to create, trade, <br/> swap, fractionalize, <br/>on the world’s first decentralized<br/> NFT platform on PulseChain.</p>
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-6 col-xs-1">
                        <div className="widget">
                            <h5>Marketplace</h5>
                            <ul>
                                <li><Link to="">All NFTs</Link></li>
                                <li><Link to="">Art</Link></li>
                                <li><Link to="">Music</Link></li>
                                <li><Link to="">Domain Names</Link></li>
                                <li><Link to="">Virtual World</Link></li>
                                <li><Link to="">Collectibles</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-6 col-xs-1">
                        <div className="widget">
                            <h5>Resources</h5>
                            <ul>
                                <li><Link to="">Help Center</Link></li>
                                <li><Link to="">Partners</Link></li>
                                <li><Link to="">Suggestions</Link></li>
                                <li><Link to="">Discord</Link></li>
                                <li><Link to="">Docs</Link></li>
                                <li><Link to="">Newsletter</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-6 col-xs-1">
                        <div className="widget">
                            <h5>Community</h5>
                            <ul>
                                <li><Link to="">Community</Link></li>
                                <li><Link to="">Documentation</Link></li>
                                <li><Link to="">Brand Assets</Link></li>
                                <li><Link to="">Blog</Link></li>
                                <li><Link to="">Forum</Link></li>
                                <li><Link to="">Mailing List</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-6 col-xs-1">
                        <div className="widget">
                            <h5>Newsletter</h5>
                            <p>Signup for our newsletter to get the latest news in your inbox.</p>
                            <form action="#" className="row form-dark" id="form_subscribe" method="post" name="form_subscribe">
                                <div className="col text-center">
                                    <input className="form-control" id="txt_subscribe" name="txt_subscribe" placeholder="enter your email" type="text" /> 
                                    <Link to="" id="btn-subscribe">
                                      <i className="arrow_right bg-color-secondary"></i>
                                    </Link>
                                    <div className="clearfix"></div>
                                </div>
                            </form>
                            <div className="spacer-10"></div>
                            <small>Your email is safe with us. We don't spam.</small>
                        </div>
                    </div>
                </div>
            </div>
            <div className="subfooter">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="de-flex">
                                <div className="de-flex-col">
                                    <span onClick={()=> window.open("", "_self")}>
                                        <img alt="" className="f-logo d-1" src={dark === 'true' ? `${window.location.origin}/img/logo-btrans.png` : `${window.location.origin}/img/logo-wtrans.png`} id="App-logo-bottom"/>
                                        <span className="copy">&copy; Copyright 2023 - PulseVerse.</span>
                                    </span>
                                </div>
                                <div className="de-flex-col">
                                    <div className="social-icons">
                                        <span style={{marginLeft: "10px"}} onClick={()=> window.open("https://www.youtube.com/channel/UCKOSvI8P-NQFLXfUcPP61jw", "_blank")}><i className="fa fa-youtube fa-lg"></i></span>
                                        <span onClick={()=> window.open("https://twitter.com/PulseVerseio", "_blank")}><i className="fa fa-twitter fa-lg"></i></span>
                                        <span onClick={()=> window.open("https://www.reddit.com/r/pulseverse/", "_blank")}><i className="fa fa-reddit fa-lg"></i></span>
                                        <span onClick={()=> window.open("https://discord.gg/veUkyeVqPB", "_blank")}><i className="fa fa-lg"><svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="discord" class="svg-inline--fa fa-discord fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"></path></svg></i></span>
                                        <span onClick={()=> window.open("https://www.instagram.com/pulseverse/", "_blank")}><i className="fa fa-instagram fa-lg"></i></span>
                                        <span onClick={()=> window.open("https://t.me/PulseVerse/", "_blank")}><i className="fa fa-telegram fa-lg"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
};
export default footer;