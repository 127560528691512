import React, { useEffect, useState } from "react";
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import useOnclickOutside from "react-cool-onclickoutside";
import { useMoralis, useMoralisCloudFunction } from "react-moralis";
import { createGlobalStyle } from 'styled-components';
import Web3 from "web3";
import Identicon from 'identicon.js';
import WrongNetwork from "../components/WrongNetwork";
import Diamond from "../../contracts/Diamond.json";
import { ThemeContext, themes } from '../../contexts/ThemeContext';
import { Link } from '@reach/router';
import ethUtil from 'ethereumjs-util'; 
import SwapModal from "../components/SwapModal";

const sigUtil = require('@metamask/eth-sig-util')


setDefaultBreakpoints([
  { xs: 0 },
  { l: 1199 },
  { xl: 1200 }
]);

const NavLink = props => (
  <Link 
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);



const GlobalStylesConnected = createGlobalStyle`
  .mainside{
    .connect-wal {
      display: none;
    }
    .logout{
      display: flex;
      align-items: center;
    }
  }

`;

const GlobalStylesNotConnected = createGlobalStyle`
`;


const Header = function(props) {


  let dark = props.theme;

  const [darkMode, setDarkMode] = React.useState(dark === 'true' ? false : true);
  const { isAuthenticated, user, logout } = useMoralis()
  const [wrongNetwork, setWrongNetwork] = useState(false);
  const [listener, setListener] = React.useState(false);
  const [convert, setConvert] = React.useState(false);



  if (window.ethereum && isAuthenticated) {
    window.web3 = new Web3(window.ethereum);
    (async () => {
      let networkId = await window.web3.eth.net.getId();
      if (networkId !== 941 && wrongNetwork === false) {
        setWrongNetwork(true)
      }
    })()
  }
  else if (window.web3 && isAuthenticated) {
    window.web3 = new Web3(window.web3.currentProvider)
  } else {
    const infura = "https://rpc.v2b.testnet.pulsechain.com";
    const web3 = new Web3(new Web3.providers.HttpProvider(infura));
    console.log(web3)
    window.web3 = web3;
  }

  const signOut = () => {
    logout()
    if (document.getElementById("wrongnet")) {
      document.getElementById("wrongnet").style.display = "none";
    }
  }

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', function(networkId){
          isAuthenticated && logout();
      })
    }
  
    // if (window.ethereum && !listener) {
    //   window.ethereum.on('chainChanged', function(networkId){
    //     if (networkId !== "0x3ad") {
    //       setWrongNetwork(true);
    //     } else {
    //       setWrongNetwork(false);      
    //     }
    //   })
    //   setListener(true);
    // }
    const web3 = window.web3;

    // if (user) {
    //   const msgParams = JSON.stringify({
    //     domain: {
    //       name: 'LooksRareExchange',
    //       version: '1',
    //       chainId: 1337,
    //       verifyingContract: "0x12118aA2eBa7d98CFb190603ba3aC89d18df0D70",
    //     },
    //     message: {
    //       isOrderAsk: true,
    //       signer: "0xA73fFe52e4B09f9Bc5eF57d2f93669b88e9c5998",
    //       collection: "0x2C6bC313085cb7F3df2938B5A4b05E75bFa91fd4",
    //       price: "1000000000000000000",
    //       tokenId: "0",
    //       amount: "1",
    //       strategy: "0xb14F5BE7029e282716281aB77842eDfC2b5c6B85",
    //       currency: "0x3a53E231ee6762008C2d48Bb9758Ef19BC8E6667",
    //       nonce: "0",
    //       startTime: "0",
    //       endTime: "28182108218210128",
    //       minPercentageToAsk: "8500",
    //       params: "0x",
    //     },
    //     primaryType: 'MakerOrder',
    //     types: {
    //       EIP712Domain: [
    //         { name: 'name', type: 'string' },
    //         { name: 'version', type: 'string' },
    //         { name: 'chainId', type: 'uint256' },
    //         { name: 'verifyingContract', type: 'address' }
    //       ],
    //       MakerOrder: [
    //         { name: 'isOrderAsk', type: 'bool' },
    //         { name: 'signer', type: 'address' },
    //         { name: 'collection', type: 'address' },
    //         { name: 'price', type: 'uint256' },
    //         { name: 'tokenId', type: 'uint256' },
    //         { name: 'amount', type: 'uint256' },
    //         { name: 'strategy', type: 'address' },
    //         { name: 'currency', type: 'address' },
    //         { name: 'nonce', type: 'uint256' },
    //         { name: 'startTime', type: 'uint256' },
    //         { name: 'endTime', type: 'uint256' },
    //         { name: 'minPercentageToAsk', type: 'uint256' },
    //         { name: 'params', type: 'bytes' },
    //       ],
    //     }
    //   });
    
    //   var from = user.get('ethAddress')
    
    //   var params = [from, msgParams]
    //   var method = 'eth_signTypedData_v4'
    
    //   web3.currentProvider.sendAsync({
    //     method,
    //     params,
    //     from,
    //   }, async function (err, result) {
    //     if (err) {
    //       document.getElementById("error").innerHTML = err.message;
    //       console.error(err)
    //       document.getElementById("list").innerHTML = "Failed";
    //       return;
    //     }
  
    //     if (result.error) {
    //       document.getElementById("error").innerHTML = result.error.message;
    //       console.error(result.error)
    //       document.getElementById("list").innerHTML = "Failed";
    //       return;
    //     }
  
    //     console.log('TYPED SIGNED:' + JSON.stringify(result.result))
  
    //     var r = result.result.slice(0, 66)
    //     var s = '0x' + result.result.slice(66, 130)
    //     var v = '0x' + result.result.slice(130, 132)
    //     v = web3.utils.toDecimal(v);
  
    //     console.log(r)
    //     console.log(s)
    //     console.log(v)
    
    //   })
  
    // }

    const [balance, setBalance] = useState(0);
    const [biddingBalancePLS, setBiddingBalancePLS] = useState("Loading");
    const [biddingBalanceHEX, setBiddingBalanceHEX] = useState("Loading");

    const [, setOpenMenu] = React.useState(false);

    const { data } = useMoralisCloudFunction("queryUserData",
      user ? {"address": user.get('ethAddress')} : {"address": ""}
    );

    const { data: makerBidPLS, isLoading: isLoadingPLS } = useMoralisCloudFunction("queryMakerBidByTokenAndUser",
      {"currency": "0xa32dc6d50771ded7ddfc372348b6d06033ad452d", "signer": user && user.get('ethAddress')}
    );

    console.log(makerBidPLS)

    const { data: makerBidHEX, isLoading: isLoadingHEX } = useMoralisCloudFunction("queryMakerBidByTokenAndUser",
      {"currency": "0x2b591e99afe9f32eaa6214f7b7629768c40eeb39", "signer": user && user.get('ethAddress')}
    );

    let dataJson = JSON.parse(JSON.stringify(data, null, 2));

    let username;
    let profilePhoto;
    if (dataJson && data.length > 0) {
      username = dataJson[0]["username"];
      profilePhoto = dataJson[0]["moralisUpload"]
    }

    React.useEffect(() => {
      if (makerBidPLS && makerBidHEX) {
        let biddingBalancePLS = 0
        let biddingBalanceHEX = 0;
  
        for (let i = 0; i < makerBidPLS.length; i++) {
          let amount = makerBidPLS[i].get('price')
          biddingBalancePLS += parseInt(amount) / 10 ** 18;
        }
  
        setBiddingBalancePLS(biddingBalancePLS)
    
        for (let i = 0; i < makerBidHEX.length; i++) {
          let amount = makerBidHEX[i].get('price')
          biddingBalanceHEX += parseInt(amount) / 10 ** 18;
        }

        setBiddingBalanceHEX(biddingBalanceHEX)
      }  
    }, [makerBidPLS, makerBidHEX, user])






    if (user) {
      web3.eth.getBalance(user.get('ethAddress')).then((balance) => {
        setBalance(balance/10**18)
        window.balance = balance;
      });
    }


    const [showmenu, btn_icon] = useState(false);
    const [showpop, btn_icon_pop] = useState(false);
    const [showInfo, setShowInfo] = useState(false);

    const closeMenu = () => {
      setOpenMenu(false);
    };

    const closePop = () => {
      btn_icon_pop(false);
    };
    const refpop = useOnclickOutside(() => {
      closePop();
    });
    
    useEffect(() => {
    const header = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
        btn_icon(false);
        console.log(window.screen.width)
        if (window.screen.width > 1199) {
          if (window.pageYOffset > sticky) {
            totop.classList.add("show"); 
          } else {
            totop.classList.remove("show");
          } if (window.pageYOffset > sticky) {
            closeMenu();
          }
        }

      });
      return () => {
        window.removeEventListener("scroll", scrollCallBack);
      };
    }, []);
    return (
      <div>

        {isAuthenticated ? <GlobalStylesConnected/> : <GlobalStylesNotConnected/>}
        <header style={{marginTop: "29px"}} id="myHeader" className='navbar sticky'>

        <div className='container'>
          
          <div className='row w-100-nav'>
              <div className='logo px-0'>
              <div className='navbar-title navbar-item'>
                        <NavLink to="/home">
                          <img
                        src={dark === 'true' ? `${window.location.origin}/img/logo-btrans.png` : `${window.location.origin}/img/logo-wtrans.png`}
                        className="img-fluid d-block"
                        id="App-logo"
                        alt="#"
                      />
                          </NavLink>
                        </div>
          
              </div>

              <div className='search'>
              
                <input style={{fontSize: "18px", color: `${dark === 'true' ? `black` : `white`}`, "background-color": `${dark === 'true' ? `#eae5e5` : `#4C505C`}`}} id="quick_search" className="xs-hide" name="quick_search" placeholder="Search coming soon..." type="text" />
                <datalist id="browsers">
                  <option value="Internet Explorer"/>
                  <option value="Firefox"/>
                  <option value="Chrome"/>
                  <option value="Opera"/>
                  <option value="Safari"/>
                </datalist>
              </div>
                        
                  <BreakpointProvider>
                    <Breakpoint l down>
                      {showmenu && 
                        <div className='menu'>
                        <div className='navbar-item'>
                        <NavLink to="/home">
                          Home
                          <span className='lines'></span>
                          </NavLink>
                        </div>

                        <div className='navbar-item'>
                        <NavLink to="/#">
                          Explore
                          <span className='lines'></span>
                          </NavLink>
                        </div>


                        <div className='navbar-item'>
                        <NavLink to="/#">
                          Trending
                          <span className='lines'></span>
                          </NavLink>
                        </div>


                        <div  onClick={() => {window.open('https://t.me/PulseVerse/5183', '_blank')}} className='navbar-item'>
                        <NavLink to="/#">
                        Whitepaper
                          <span className='lines'></span>
                          </NavLink>
                        </div>

                        <div className='navbar-item'>
                        <NavLink to="/#">
                        Earn
                          <span className='lines'></span>
                          </NavLink>
                        </div>

                        <div className='navbar-item'>
                        <NavLink to="/#">
                        Play
                          <span className='lines'></span>
                          </NavLink>
                        </div>
                        {/* <div className='navbar-item'>
                        <a href="/explore" target="_blank">
                          Explore
                          <span className='lines'></span>
                          </a>
                        </div> */}


                        {/* <div className='navbar-item'>
                          <NavLink to="/activity">
                            Activity
                            <span className='lines'></span>
                          </NavLink>
                        </div> */}
                        <div className='navbar-item' onClick={() => {setShowInfo(!showInfo); btn_icon(false)}}>
                            My Account
                        </div>
                        {/* <ThemeContext.Consumer>
                          {({ changeTheme }) => (
                            <div className='navbar-item' onClick={() => {
                              setDarkMode(!darkMode);
                              localStorage.setItem('darkMode', darkMode)
                              changeTheme(!darkMode ? themes.light : themes.dark)}}>
                                {!darkMode ? "Dark Mode" : "Light Mode"} <i className={darkMode ? 'fa fa-sun-o' : 'fa fa-moon-o'}></i>
                            </div>

                          )}
                        </ThemeContext.Consumer> */}
                      </div>
                    }

                    {
                    showInfo && <div className='menu'>
                      <button className="btn-main" onClick={() => {setShowInfo(false); btn_icon(!showmenu)}}>Back</button>
                      <div className="popshow">
                      <br/>
                      {profilePhoto !== undefined ? <img style={{height: "50px", width: "50px"}} src={profilePhoto.url} alt=""/> : user && <img src={`data:image/png;base64,${new Identicon(user.get('ethAddress'), 30).toString()}`} alt=""/>}
                              <div className="d-name">
                                  <h4>{username}</h4>
                                  {user ? <span id="wallet" className="d-wallet-address">{user.get('ethAddress').substring(0, 20)}...</span> : <span id="wallet" className="d-wallet-address"></span>}
                              </div>
                              <div className="d-balance">
                                  <h4>Balance</h4>
                                  <h4>{balance} PLS</h4>
                              </div>
                              <div className="d-balance">
                                  <h4>Bidding Balance</h4>
                                  <h4>{biddingBalancePLS} WPLS</h4>
                                  <h4>{biddingBalanceHEX} HEX</h4>
                              </div>
                              <button className="btn-main" style={{width: "100%"}} onClick={() => null}>Convert PLS/WPLS</button>
                              <div className="d-line"></div>
                                  {isAuthenticated && <Link className="btn-main" to={"/user/"+user.get('ethAddress')}>
                                  <i className="fa fa-user"></i> My profile
                                    </Link>}
                                  <br/>
                                  {isAuthenticated && <Link className="btn-main" to="/#">
                                    <i className="fa fa-th-list"></i> My collections
                                    </Link>}
                                  <br/>
                                  {isAuthenticated && <Link className="btn-main" to="/#">
                                  <i className="fa fa-pencil"></i> Edit profile
                                    </Link>}
                                  <span>
                                  <br/>
                                  {isAuthenticated ? <button className="btn-main" onClick={() => logout()}>
                                    <i className="fa fa-sign-out"></i> Sign Out
                                  </button > : <NavLink className="btn-main" to="/#">
                                  <i className="fa fa-sign-out"></i> Sign In
                                    </NavLink>}
                                  </span>
                            </div>
                    </div>
                    }
                    </Breakpoint>

                    <Breakpoint xl>
                      <div className='menu'>
                      <div className='navbar-item' style={{marginRight: "15px", marginTop: "7px"}}>
                        <NavLink to="/#" style={{fontSize: "18px"}}>
                          Explore
                          <span className='lines'></span>
                          </NavLink>
                        </div>

                        <div className='navbar-item' style={{marginRight: "15px", marginTop: "7px"}}>
                        <NavLink to="/#" style={{fontSize: "18px"}}>
                          Trending
                          <span className='lines'></span>
                          </NavLink>
                        </div>

                        {user && <div className='navbar-item' style={{marginRight: "15px", marginTop: "7px"}}>
                        <NavLink to={`/#`} style={{fontSize: "18px"}}>
                         My profile
                          <span className='lines'></span>
                          </NavLink>
                        </div>}

                        <div onClick={() => {window.open('https://t.me/PulseVerse/5183', '_blank')}} className='navbar-item' style={{marginRight: "15px", marginTop: "7px"}}>
                        <NavLink to="/#" style={{fontSize: "18px"}}>
                        Whitepaper
                          <span className='lines'></span>
                          </NavLink>
                        </div>
                        <div className='navbar-item' style={{marginRight: "15px", marginTop: "7px"}}>
                        <NavLink to="/#" style={{fontSize: "18px"}}>
                        Earn
                          <span className='lines'></span>
                          </NavLink>
                        </div>
                        
                        <div className='navbar-item' style={{marginRight: "15px", marginTop: "7px"}}>
                        <NavLink to="/#" style={{fontSize: "18px"}}>
                        Play
                          <span className='lines'></span>
                          </NavLink>
                        </div>
                        
                        {/* <div className='navbar-item' style={{marginRight: "15px", marginTop: "7px"}}>
                        <a href="/explore" target="_self" style={{fontSize: "18px"}}>
                          Explore
                          <span className='lines'></span>
                          </a>
                        </div> */}

 

                        {/* <div className='navbar-item' style={{marginRight: "15px", marginTop: "7px"}}>
                        <NavLink to="/explore" style={{fontSize: "18px"}}>
                          Collections
                          <span className='lines'></span>
                          </NavLink>
                        </div> */}
                        {/* <div className='navbar-item' style={{marginRight: "15px", marginTop: "7px"}}>
                        <NavLink to="/earn" style={{fontSize: "18px"}}>
                          Earn
                          <span className='lines'></span>
                          </NavLink>
                        </div> */}
                        {/* <div className='navbar-item' style={{marginRight: "15px", marginTop: "7px"}}>
                          <NavLink to="/activity" style={{fontSize: "18px"}}>
                            Activity
                            <span className='lines'></span>
                          </NavLink>
                        </div> */}
                          <div className='mainside sign-in navbar-item' style={{marginTop: "7px"}}>
                            {isAuthenticated ?
                              /* eslint-disable-line */   <NavLink to="/#" style={{marginRight: "5px"}}>
                          Create
                          </NavLink> /* eslint-disable-line */ :   <NavLink to="/#" style={{marginRight: "5px"}}>
                          Create
                          </NavLink> }
                          </div>    
                        <div className='mainside navbar-item'>
                      
                    <div className='connect-wal' style={{marginTop: "7px"}}>

                       <NavLink to="/#">
                        Sign In
                          </NavLink>
                       {/* <ThemeContext.Consumer>
                          {({ changeTheme }) => (

                            <div id="de-click-menu-notification" className="de-menu-notification"  onClick={() => {
                              setDarkMode(!darkMode);
                              localStorage.setItem('darkMode',darkMode)
                              changeTheme(!darkMode ? themes.light : themes.dark);
                            }}>
                                <i style={{color: "#121212"}} className={darkMode ? 'fa fa-sun-o' : 'fa fa-moon-o'}></i>
                            </div>
                          )}
                        </ThemeContext.Consumer> */}
                    </div>

                    <div className="logout">
                    {/* <ThemeContext.Consumer>
                          {({ changeTheme }) => (

                            <div id="de-click-menu-notification" className="de-menu-notification"  onClick={() => {
                              setDarkMode(!darkMode);
                              localStorage.setItem('darkMode',darkMode)
                              changeTheme(!darkMode ? themes.light : themes.dark);
                            }}>
                                <i style={{color: "#121212"}} className={darkMode ? 'fa fa-sun-o' : 'fa fa-moon-o'}></i>
                            </div>
                          )}
                        </ThemeContext.Consumer> */}

                      {/* <div id="de-click-menu-notification" className="de-menu-notification" onClick={() => btn_icon_not(!shownot)} ref={refpopnot}> */}
                          {/* <div className="d-count"></div>
                          <i className="fa fa-bell"></i>
                          {shownot && 
                            <div className="popshow">
                              <div className="de-flex">
                                  <h4>Notifications</h4>
                                  <span className="viewaall">Show all</span>
                              </div>
                              <ul>
                                <li>
                                    <div className="mainnot">
                                        <img className="lazy" src="../../img/author/author-2.jpg" alt=""/>
                                        <div className="d-desc">
                                            <span className="d-name"><b>Mamie Barnett</b> started following you</span>
                                            <span className="d-time">1 hour ago</span>
                                        </div>
                                    </div>  
                                </li>
                                <li>
                                    <div className="mainnot">
                                        <img className="lazy" src="../../img/author/author-3.jpg" alt=""/>
                                        <div className="d-desc">
                                            <span className="d-name"><b>Nicholas Daniels</b> liked your item</span>
                                            <span className="d-time">2 hours ago</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="mainnot">
                                        <img className="lazy" src="../../img/author/author-4.jpg" alt=""/>
                                        <div className="d-desc">
                                            <span className="d-name"><b>Lori Hart</b> started following you</span>
                                            <span className="d-time">18 hours ago</span>
                                        </div>
                                    </div>    
                                </li>
                                <li>
                                    <div className="mainnot">
                                        <img className="lazy" src="../../img/author/author-5.jpg" alt=""/>
                                        <div className="d-desc">
                                            <span className="d-name"><b>Jimmy Wright</b> liked your item</span>
                                            <span className="d-time">1 day ago</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="mainnot">
                                        <img className="lazy" src="../../img/author/author-6.jpg" alt=""/>
                                        <div className="d-desc">
                                            <span className="d-name"><b>Karla Sharp</b> started following you</span>
                                            <span className="d-time">3 days ago</span>
                                        </div>
                                    </div>    
                                </li>
                            </ul>
                            </div>
                            } */}
                      {/* </div> */}
                      <div id="de-click-menu-profile" className="de-menu-profile" onClick={() => btn_icon_pop(!showpop)} ref={refpop}>                
                          {profilePhoto !== undefined ? <img style={{height: "38px", width: "38px"}} src={profilePhoto.url} alt=""/> : user && <img src={`data:image/png;base64,${new Identicon(user.get('ethAddress'), 30).toString()}`} alt=""/>}
                          {showpop && 
                            <div className="popshow" style={dark === 'true' ? {"background": "white", "color": "#121212"} : {"background": "#121212", "color": "white"}}>
                              
                              <div className="d-name" style={dark === 'true' ? {"background": "white", "color": "#121212"} : {"background": "#121212", "color": "white"}}>
                                  {profilePhoto !== undefined ? <img style={{height: "38px", width: "38px"}} src={profilePhoto.url} alt=""/> : user && <img src={`data:image/png;base64,${new Identicon(user.get('ethAddress'), 30).toString()}`} alt=""/>}
                                  <h4 style={dark === 'true' ? {"color": "#121212"} : {"color": "white"}}>{username}</h4>
                                  {user ? <span id="wallet" className="d-wallet-address" style={dark === 'true' ? {"color": "#121212"} : {"color": "white"}}>{user.get('ethAddress').substring(0, 20)}...</span> : <span id="wallet" className="d-wallet-address"></span>}
                              </div>
                              <div className="d-balance">
                                  <h4 style={dark === 'true' ? {"color": "#121212"} : {"color": "white"}}>Balance</h4>
                                  <h4 style={dark === 'true' ? {"color": "#121212"} : {"color": "white"}}>{balance} PLS</h4>
                              </div>
                              <div className="d-balance">
                                  <h4 style={dark === 'true' ? {"color": "#121212"} : {"color": "white"}}>Bidding Balance</h4>
                                  <h4 style={dark === 'true' ? {"color": "#121212"} : {"color": "white"}}>{biddingBalancePLS} WPLS</h4>
                                  <h4 style={dark === 'true' ? {"color": "#121212"} : {"color": "white"}}>{biddingBalanceHEX} HEX</h4>
                              </div>
                              <button className="btn-main" style={{width: "100%"}} onClick={() => null}>Convert PLS/WPLS</button>
                              <div className="d-line"></div>
                              <ul className="de-submenu-profile">
                                <li>
                                <Link to={user && "/#"}>
                                  <span style={{"color": "white"}}>
                                    <i className="fa fa-user" style={{color: "white"}}></i> My profile
                                  </span>
                                  </Link>
                                </li>
                                
                                <li>
                                <Link to="/#">
                                  <span style={{"color": "white"}}>
                                    <i className="fa fa-th-list" style={{color: "white"}}></i> My collections
                                  </span>
                                  </Link>
                                </li>

                                <li>
                                <Link to="/#">
                                <span style={{"color": "white"}}>
                                    <i className="fa fa-pencil" style={{color: "white"}}></i> Edit profile
                                  </span>
                                    </Link>
                                </li>
                                <li>
                                  <span>
                                  {isAuthenticated ? <span onClick={() => logout()} style={dark === 'true' ? {"color": "#121212"} : {"color": "white"}}>
                                    <i className="fa fa-sign-out" style={{color: "#121212"}}></i> Sign Out
                                  </span> : <span style={dark === 'true' ? {"color": "#121212"} : {"color": "white"}}>
                                    <i className="fa fa-sign-out" style={{color: "#121212"}}></i> Sign Out
                                  </span>}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          }
                      </div>
                    </div>
                  </div>
                        
                      </div>

                    </Breakpoint>
                  </BreakpointProvider>
                      
          </div>

            <button className="nav-icon" onClick={() => {setShowInfo(false); btn_icon(!showmenu)}}>
              <div style={dark === 'true' ? {"background": "#121212", "color": "white"} : {"background": "white", "color": "#121212"}} className="menu-line"></div>
              <div style={dark === 'true' ? {"background": "#121212", "color": "white"} : {"background": "white", "color": "#121212"}}className="menu-line1"></div>
              <div style={dark === 'true' ? {"background": "#121212", "color": "white"} : {"background": "white", "color": "#121212"}} className="menu-line2"></div>
            </button>

          </div>     
        </header>
        <a href="https://mysacrifice.io" target="_blank">
        <header style={{background: "#E915BC", textAlign: "center", padding: "2px"}} id="myHeader" className='navbar sticky'>
          <p style={{margin: "0 auto", fontSize: "17px", "font-weight": "bold"}}>Sacrifice Coming Soon - <span style={{"text-decoration": "underline"}}>MySacrifice.io</span></p>
        </header>
        </a>

        { wrongNetwork && isAuthenticated &&
            <div className='wrongnet'>
                <WrongNetwork signOut={signOut}/>
            </div>
        }
        { convert &&
          <div className='checkoutNft' style={{"z-index": "9000"}}>
          <div className='maincheckout'>
          <button className='btn-close' onClick={() => setConvert(false)}>x</button>
              <div className='heading'>
                  <h3>Convert PLS/WPLS</h3>
              </div>
            <SwapModal/>
          </div>
          </div>
      }
      </div>
    
    );
}
export default Header;