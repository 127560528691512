import React from "react";
// import ColumnZero from '../components/ColumnZero';
// import ColumnZeroTwo from '../components/ColumnZeroTwo';
import { Link } from '@reach/router';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import ColumnNewListing from "../components/ColumnNewListing";
import ColumnNewRedux from "../components/ColumnNewRedux";
import ColumnNewItems from "../components/ColumnNewItems";
import WrongNetwork from "../components/WrongNetwork";
import UserCollection from "../components/UserCollection";
import CollectionDesc from "../components/CollectionDesc";
import { useMoralisCloudFunction, useMoralis, useMoralisQuery } from "react-moralis";
import ERC721 from "../../contracts/ERC721.json";
import DiamondNFTToken from "../../contracts/DiamondNFTToken.json";
import Diamond from "../../contracts/Diamond.json";
import Ownable from "../../contracts/Ownable.json";
import ERC721Enumerable from "../../contracts/ERC721Enumerable.json";

import BuyNowMarketplace from "../../contracts/BuyNowMarketplace.json";
import SteppedPricingMarketplace from "../../contracts/SteppedPricingMarketplace.json";
import OffersSaleMarketplace from "../../contracts/OffersSaleMarketplace.json";
import ReserveAuctionMarketplace from "../../contracts/ReserveAuctionMarketplace.json";
import IPFSGatewayTools from '@pinata/ipfs-gateway-tools/dist/browser';
import Token from "../../contracts/KnownOriginDigitalAssetV3.json";

import BuyNowSecondaryMarketplace from "../../contracts/BuyNowSecondaryMarketplace.json";
import OffersSaleSecondaryMarketplace from "../../contracts/OffersSaleSecondaryMarketplace.json";
import ReserveAuctionSecondaryMarketplace from "../../contracts/ReserveAuctionSecondaryMarketplace.json";
import tokens from "../../tokens.json";

const ipfsGatewayTools = new IPFSGatewayTools();
const desiredGatewayPrefix = 'https://rarible.mypinata.cloud'

const Collection = function(props) {
  const dark = props.theme;
  const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #fff;
  }
  @media only screen and (max-width: 1199px) {
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #111;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
  }
  h1, h2, h4, h3, h5, h6, .h1_big, .h1, .h2, .h3, .h4, .h5, .h6, span, p, a, input {
    color: ${dark === 'true' ? "#121212" : "white"}
  }
`;
	const { isAuthenticated, user, logout } = useMoralis()
  const [wrongNetwork, setWrongNetwork] = React.useState(false);

  const [onSaleIsLoading, setOnSaleIsLoading] = React.useState(true);
  const [itemIsLoading, setItemIsLoading] = React.useState(true);
  const [isOwner, setIsOwner] = React.useState(false);
  const [subjectAddress, ] = React.useState(window.location.pathname.split("/")[2])

  const { data } = useMoralisCloudFunction("queryCollection",
	  {"address": subjectAddress}
  );
  
  const { data: listingData } = useMoralisCloudFunction("queryCollectionListing",
	  {"contractAddress": subjectAddress}
  );

  const { data: nftData } = useMoralisQuery("NFTCreated");

  console.log(listingData)

  console.log(nftData)

  const web3 = window.web3;
  const digitalAsset = new web3.eth.Contract(Token.abi, DiamondNFTToken.networks["941"].address);
  const buyNowMarketplace = new web3.eth.Contract(BuyNowMarketplace.abi, Diamond.networks["941"].address)
  const reserveAuctionMarketplace = new web3.eth.Contract(ReserveAuctionMarketplace.abi, Diamond.networks["941"].address)
  const offersSaleMarketplace = new web3.eth.Contract(OffersSaleMarketplace.abi, Diamond.networks["941"].address)
  const steppedSaleMarketplace = new web3.eth.Contract(SteppedPricingMarketplace.abi, Diamond.networks["941"].address)

  const buyNowSecondaryMarketplace = new web3.eth.Contract(BuyNowSecondaryMarketplace.abi, Diamond.networks["941"].address)
  const offersSaleSecondaryMarketplace = new web3.eth.Contract(OffersSaleSecondaryMarketplace.abi, Diamond.networks["941"].address)
  const reserveAuctionSecondaryMarketplace = new web3.eth.Contract(ReserveAuctionSecondaryMarketplace.abi, Diamond.networks["941"].address)

  const [collectionName, setCollectionName] = React.useState("...");
  const [owner, setOwner] = React.useState("");
  const [onSale, setOnSale] = React.useState([]);
  const [collected, setCollected] = React.useState([]);
  const [listener, setListener] = React.useState(false);

  console.log(data)

  if (window.ethereum && !listener) {
    window.ethereum.on('chainChanged', function(networkId){
      if (networkId !== "0x3ac") {
        setWrongNetwork(true);
      } else {
        setWrongNetwork(false);      
      }
    })
    setListener(true);
  }

  const signOut = () => {
    logout()
    if (document.getElementById("wrongnet")) {
      document.getElementById("wrongnet").style.display = "none";
    }
  }
  
const [openMenu, setOpenMenu] = React.useState(true);
const [openMenu1, setOpenMenu1] = React.useState(false);
const handleBtnClick = () => {
  setOpenMenu(!openMenu);
  setOpenMenu1(false);
  document.getElementById("Mainbtn").classList.add("active");
  document.getElementById("Mainbtn1").classList.remove("active");
};
const handleBtnClick1 = () => {
  setOpenMenu1(!openMenu1);
  setOpenMenu(false);
  document.getElementById("Mainbtn1").classList.add("active");
  document.getElementById("Mainbtn").classList.remove("active");
};

const copyText = (user) => {
  window.navigator.clipboard.writeText(subjectAddress)
  document.getElementById("btn_copy").innerHTML = "Copied!";
  setTimeout(function(){
    document.getElementById("btn_copy").innerHTML = "Copy";
  }, 2000);
}

const populateData = async () => {


  if (user) {
    if (user.get('ethAddress') === owner.toLowerCase()) {
      setIsOwner(true)
    }
  }

  let contract = new web3.eth.Contract(ERC721.abi, subjectAddress);
  let ownable = new web3.eth.Contract(Ownable.abi, subjectAddress)
  let name = await contract.methods.name().call()
  let ownerPass;
  try {
    ownerPass = await ownable.methods.owner().call();
    setOwner(ownerPass);

  } catch (error) {}

  

  if (collectionName !== name) {
    setCollectionName(name);
  }

// On Sale

if (listingData && listingData[0]) { 
  let nftsInfo = []
  for (let i = 0; i < listingData.length; i++) {
    let nftInfo = {}

    let nft = listingData[i].get('newNFT');
    console.log(nft)

    let listingType = listingData[i].get('listing')

    if (nft && nft.get('creator')) {

        console.log(nft.get('lazyMinted'))

        if (!nft.get('lazyMinted')) {
          let editionId = nft.get('editionId')
          let editionIsSoldOut;
          if (editionId) {
            editionIsSoldOut = await digitalAsset.methods.isEditionSoldOut(editionId).call();
          } else {
            editionIsSoldOut = false;
          }
  
          nftInfo["soldOut"] = editionIsSoldOut
        } else {
          if (nft.get('editionId')) {
            console.log(nft.get('editionId'))
            let editionIsSoldOut = await digitalAsset.methods.isEditionSoldOut(nft.get('editionId')).call(); 
              nftInfo["soldOut"] = editionIsSoldOut
          } else {
            nftInfo["soldOut"] = false
          }
                
        }
        let editionId;
        if (nft.get('lazyMinted')) {
          editionId = nft.id
          if (listingType === "buy" && editionId) {
            const buyNowMarketplace = new web3.eth.Contract(BuyNowMarketplace.abi, Diamond.networks["941"].address)
            let price = await buyNowMarketplace.methods["editionOrTokenListings(string)"](editionId).call();
            nftInfo["token"] = tokens[price.token.toLowerCase()][0]
            nftInfo["price"] = price.price / 10 ** tokens[price.token.toLowerCase()][1]
          } else if (listingType === "reserve" && editionId) {
            let price = await reserveAuctionMarketplace.methods["editionOrTokenWithReserveAuctions(string)"](editionId).call();
            nftInfo["deadline"] = new Date(price.startDate)
            nftInfo["token"] = tokens[price.token.toLowerCase()][0]
            nftInfo["price"] = price.reservePrice / 10 ** tokens[price.token.toLowerCase()][1]
            nftInfo["bid"] = price.bid / 10 ** tokens[price.token.toLowerCase()][1]
          } else if (listingType === "offers" && editionId) {
            let price = await offersSaleMarketplace.methods["getOffersInfo(string)"](editionId).call();
            nftInfo["deadline"] = new Date(price[0])
            nftInfo["token"] = tokens[price[1].toLowerCase()][0]
            nftInfo["bid"] = price[4].offer / 10 ** tokens[price[1].toLowerCase()][1]
          } else if (listingType === "stepped" && editionId){
            let nextPrice = await steppedSaleMarketplace.methods["getNextEditionSteppedPrice(string)"](editionId).call();
            let price = await steppedSaleMarketplace.methods["editionStep(string)"](editionId).call();
            nftInfo["token"] = tokens[price.token.toLowerCase()][0]
            nftInfo["price"] = nextPrice.price / 10 ** tokens[price.token.toLowerCase()][1]
          }
        } else {
          editionId = nft.get('editionId')     
          if (listingType === "buy" && editionId) {
            let price = await buyNowMarketplace.methods["editionOrTokenListings(uint256)"](editionId).call();
            nftInfo["token"] = tokens[price.token.toLowerCase()][0]
            nftInfo["price"] = price.price / 10 ** tokens[price.token.toLowerCase()][1]
          } else if (listingType === "reserve" && editionId) {
            let price = await reserveAuctionMarketplace.methods["editionOrTokenWithReserveAuctions(uint256)"](editionId).call();
            nftInfo["deadline"] = new Date(price.startDate)
            nftInfo["token"] = tokens[price.token.toLowerCase()][0]
            nftInfo["price"] = price.reservePrice / 10 ** tokens[price.token.toLowerCase()][1]
            nftInfo["bid"] = price.bid / 10 ** tokens[price.token.toLowerCase()][1]
          } else if (listingType === "offers" && editionId) {
            let price = await offersSaleMarketplace.methods["getOffersInfo(uint256)"](editionId).call();
  
            nftInfo["deadline"] = new Date(price[0])
            nftInfo["token"] = tokens[price[1].toLowerCase()][0]
            nftInfo["bid"] = price[3].offer / 10 ** tokens[price[1].toLowerCase()][1]     

          } else if (listingType === "stepped" && editionId) {
            let nextPrice = await steppedSaleMarketplace.methods["getNextEditionSteppedPrice(uint256)"](editionId).call();
            let price = await steppedSaleMarketplace.methods["editionStep(uint256)"](editionId).call();
            nftInfo["token"] = tokens[price.token.toLowerCase()][0]
            nftInfo["price"] = nextPrice.price / 10 ** tokens[price.token.toLowerCase()][1]
          }
        }
  
        console.log(editionId)
        console.log(nft)
        let metadata = nft.get('metadata')
        let image = nft.get('moralisUpload')
        let imagePreview = nft.get('moralisUploadPreview')
  
        console.log(imagePreview)
  
        if (imagePreview) {
          nftInfo["preview"] = `https://rarible.mypinata.cloud/ipfs/${imagePreview._name.split(".")[0]}`;
        }
  
        console.log(image)
        nftInfo["title"] = metadata ? metadata["title"] : undefined
        if (image) {
          let imageIPFS = `https://rarible.mypinata.cloud/ipfs/${image._name.split(".")[0]}`;
          console.log(imageIPFS)
          nftInfo["previewImg"] = imageIPFS;
        } else {
          nftInfo["previewImg"] = undefined;  
        }
  
        nftInfo["authorLink"] = `/${nft.get('creator')}`
        nftInfo["author"] = nft.get('creator')
        if (listingType === "reserve" || listingType === "offers") {
          nftInfo["status"] = 'on_auction' 
        } else if (listingType === "buy" || listingType === "stepped") {
          nftInfo["status"] = 'buy' 
        }
        nftInfo["editions"] = nft.get('editionAmount')
        if (!nft.get('lazyMinted')) {
          nftInfo["nftLink"] = `/edition/${listingData[i].get('contractAddress')}:${nft.get('editionId')}`
        } else {
          nftInfo["nftLink"] = `/edition/${listingData[i].get('contractAddress')}:${nft.id}`
        }

          if (nft.get('additionalMetadata')) {
            nftInfo["hasAdditionalMetadata"] = true;
          }
        console.log(nftInfo)
  
        nftsInfo.push(nftInfo)


    } else if (!nft) {


      let editionId = listingData[i].get('tokenId');
      let contractAddress = listingData[i].get('contractAddress');
      let digitalAsset = new web3.eth.Contract(ERC721.abi, listingData[i].get('contractAddress'));
      console.log(listingData)
      console.log(listingData[i])
      let uri = await digitalAsset.methods.tokenURI(listingData[i].get('tokenId')).call();
      let finalURI;
      try {
        finalURI = `https://thawing-peak-27665.herokuapp.com/${ipfsGatewayTools.convertToDesiredGateway(uri, desiredGatewayPrefix)}`;
      } catch (error) {
          if (!uri.startsWith("data:application")) {
            finalURI = `https://thawing-peak-27665.herokuapp.com/${uri}`;
          } else {
            finalURI = uri
          }
      }
      await fetch(finalURI
        ,{
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }
        }
        )
          .then(function(response){
            console.log(response)
            return response.json();
          })
          .then(async function(myJson) {
            console.log(myJson)
            if (myJson["name"]) {
              nftInfo["title"] = myJson["name"]
            } else {
              nftInfo["title"] = `#${editionId}`
            }

            let imagePreview;
            if (myJson["image_preview"] !== "") {

              try {
                  imagePreview = ipfsGatewayTools.convertToDesiredGateway(myJson["image_preview"], desiredGatewayPrefix);
              } catch (error) {
                  imagePreview = myJson["image_preview"];
              }
            }

            nftInfo["editions"] = "1"
            let image;
            if (myJson["image"]) {
              try {
                  image = ipfsGatewayTools.convertToDesiredGateway(myJson["image"], desiredGatewayPrefix);
              } catch (error) {
                  image = myJson["image"];
              }
            } else if (myJson["image_url"]) {
                try {
                    image = ipfsGatewayTools.convertToDesiredGateway(myJson["image_url"], desiredGatewayPrefix);
                } catch (error) {
                    image = myJson["image_url"];
                }  
            }

            console.log(image)
            
            if (listingType === "reserve" || listingType === "offers") {
              nftInfo["status"] = 'on_auction' 
            } else if (listingType === "buy") {
              nftInfo["status"] = 'buy'
            }

            nftInfo["previewImg"] = image
    if (imagePreview) {
      nftInfo["preview"] = `https://rarible.mypinata.cloud/ipfs/${imagePreview._name.split(".")[0]}`;
    }
            nftInfo["authorLink"] = `/${listingData[i].get('userAddress')}`
            nftInfo["nftLink"] = `/token/${listingData[i].get('contractAddress')}:${listingData[i].get('tokenId')}`
            nftInfo["author"] = listingData[i].get('userAddress')

            if (listingType === "buy" && editionId) {
              let price = await buyNowSecondaryMarketplace.methods["editionOrTokenListingsSecondary(uint256,address)"](editionId,contractAddress).call();
              nftInfo["token"] = tokens[price.token.toLowerCase()][0]
              nftInfo["price"] = price.price / 10 ** tokens[price.token.toLowerCase()][1]
            } else if (listingType === "reserve" && editionId) {
              let price = await reserveAuctionSecondaryMarketplace.methods["editionOrTokenWithReserveAuctionsSecondary(uint256,address)"](editionId,contractAddress).call();
              nftInfo["deadline"] = new Date(price.startDate)
              nftInfo["token"] = tokens[price.token.toLowerCase()][0]
              nftInfo["price"] = price.reservePrice / 10 ** tokens[price.token.toLowerCase()][1]
              nftInfo["bid"] = price.bid / 10 ** tokens[price.token.toLowerCase()][1]
            } else if (listingType === "offers" && editionId) {
              let price = await offersSaleSecondaryMarketplace.methods["getOffersInfoSecondary(uint256,address)"](editionId,contractAddress).call();
              nftInfo["deadline"] = new Date(price[0])
              nftInfo["token"] = tokens[price[1].toLowerCase()][0]
              nftInfo["bid"] = price[3].offer / 10 ** tokens[price[1].toLowerCase()][1]     

            }
            console.log(nftInfo)
            nftsInfo.push(nftInfo)
          })    



    }

  }

  console.log(onSale.length)
  console.log(listingData.length)

      if (onSale.length !== listingData.length) {
        await setOnSale(nftsInfo)
        setOnSaleIsLoading(false);
      }


} else if (listingData && listingData.length === 0) {
  setOnSaleIsLoading(false); 
}

// Collected

if (subjectAddress !== DiamondNFTToken.networks["941"].address) {
  let collectedInfo = []
  let digitalAsset = new web3.eth.Contract(ERC721.abi, subjectAddress);
  let digitalAssetEnumerable = new web3.eth.Contract(ERC721Enumerable.abi, subjectAddress);

  let balanceOf = await digitalAssetEnumerable.methods.totalSupply().call()


  console.log(balanceOf)
  if (balanceOf >= 100) {
    for (let i = 0; i < 100; i++) {
      let token = await digitalAssetEnumerable.methods.tokenByIndex(i).call();
      try {
        let owner = await digitalAsset.methods.ownerOf(token).call();
        let info = {"tokenId": token, "address": digitalAsset._address, "owner": owner};
        // let digitalAsset = new web3.eth.Contract(ERC721.abi, info["address"]);
        let uri = await digitalAsset.methods.tokenURI(info["tokenId"]).call();
        let name = await digitalAsset.methods.name().call();
        console.log(uri)
        let finalURI;
        try {
          finalURI = `https://thawing-peak-27665.herokuapp.com/${ipfsGatewayTools.convertToDesiredGateway(uri, desiredGatewayPrefix)}`;
        } catch (error) {
            if (!uri.startsWith("data:application")) {
              finalURI = `https://thawing-peak-27665.herokuapp.com/${uri}`;
            } else {
              finalURI = uri
            }
        }
        let collInfo = {}
        await fetch(finalURI
          ,{
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
          }
          )
            .then(function(response){
              console.log(response)
              return response.json();
            })
            .then(function(myJson) {
              console.log(myJson)
      
              if (myJson["name"]) {
                collInfo["title"] = myJson["name"]
              } else {
                collInfo["title"] = `${name} #${info["tokenId"]}`
              }
      
              let image;
              let imagePreview;
              if (myJson["image"]) {
                try {
                    image = ipfsGatewayTools.convertToDesiredGateway(myJson["image"], desiredGatewayPrefix);
                } catch (error) {
                    image = myJson["image"];
                }
              } else if (myJson["image_url"]) {
                  try {
                      image = ipfsGatewayTools.convertToDesiredGateway(myJson["image_url"], desiredGatewayPrefix);
                  } catch (error) {
                      image = myJson["image_url"];
                  }  
              }
              try {
                  imagePreview = ipfsGatewayTools.convertToDesiredGateway(myJson["image_preview"], desiredGatewayPrefix);
              } catch (error) {
                  imagePreview = myJson["image_preview"];
              }
      
              collInfo["previewImg"] = image
              console.log(imagePreview)
            if (imagePreview) {
              collInfo["preview"] = imagePreview;
            }
      
              collInfo["authorLink"] = `/${info["owner"]}`
              collInfo["author"] = info["owner"];
              collInfo["nftLink"] = `/token/${info["address"]}:${info["tokenId"]}`
              collInfo["editions"] = "1"
      
          
      
      
            })
            collectedInfo.push(collInfo)
            if (collectedInfo.length === i+1) {
              setCollected(collectedInfo); 
              setItemIsLoading(false)
              console.log(collectedInfo)
              console.log(collected)
            }
            console.log(collectedInfo)
      } catch (error) {
        console.error(error)
      }
  
    }
  } else {
    for (let i = 0; i < balanceOf; i++) {
      let token = await digitalAssetEnumerable.methods.tokenByIndex(i).call();
      try {
        let owner = await digitalAsset.methods.ownerOf(token).call();
        let info = {"tokenId": token, "address": digitalAsset._address, "owner": owner};
        // let digitalAsset = new web3.eth.Contract(ERC721.abi, info["address"]);
        let uri = await digitalAsset.methods.tokenURI(info["tokenId"]).call();
        let name = await digitalAsset.methods.name().call();
        console.log(uri)
        let finalURI;
        try {
          finalURI = `https://thawing-peak-27665.herokuapp.com/${ipfsGatewayTools.convertToDesiredGateway(uri, desiredGatewayPrefix)}`;
        } catch (error) {
            if (!uri.startsWith("data:application")) {
              finalURI = `https://thawing-peak-27665.herokuapp.com/${uri}`;
            } else {
              finalURI = uri
            }
        }
        let collInfo = {}
        await fetch(finalURI
          ,{
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
          }
          )
            .then(function(response){
              console.log(response)
              return response.json();
            })
            .then(function(myJson) {
              console.log(myJson)
      
              if (myJson["name"]) {
                collInfo["title"] = myJson["name"]
              } else {
                collInfo["title"] = `${name} #${info["tokenId"]}`
              }
      
              let image;
              let imagePreview;
              if (myJson["image"]) {
                try {
                    image = ipfsGatewayTools.convertToDesiredGateway(myJson["image"], desiredGatewayPrefix);
                } catch (error) {
                    image = myJson["image"];
                }
              } else if (myJson["image_url"]) {
                  try {
                      image = ipfsGatewayTools.convertToDesiredGateway(myJson["image_url"], desiredGatewayPrefix);
                  } catch (error) {
                      image = myJson["image_url"];
                  }  
              }
      
              try {
                  imagePreview = ipfsGatewayTools.convertToDesiredGateway(myJson["image_preview"], desiredGatewayPrefix);
              } catch (error) {
                  imagePreview = myJson["image_preview"];
              }
      
              collInfo["previewImg"] = image
              console.log(imagePreview)
            if (imagePreview) {
              collInfo["preview"] = imagePreview;
            }
      
              collInfo["authorLink"] = `/${info["owner"]}`
              collInfo["author"] = info["owner"];
              collInfo["nftLink"] = `/token/${info["address"]}:${info["tokenId"]}`
              collInfo["editions"] = "1"
      
          
      
      
            })
            collectedInfo.push(collInfo)
            if (collectedInfo.length === i+1) {
              setCollected(collectedInfo); 
              setItemIsLoading(false)
              console.log(collectedInfo)
              console.log(collected)
            }
            console.log(collectedInfo)
      } catch (error) {
        console.error(error)
      }
  
    }
  }


// let collectedInfo = []

// for (let i = 0; i < userTokens.length; i++) {

// }



console.log(collectedInfo)
setItemIsLoading(false) 
} else {
  if (nftData) {
    console.log(nftData)
    let nftsInfo = []
    for (let i = 0; i < nftData.length; i++) {
      let nftInfo = {}
      let nft = nftData[i]
      let metadata = nft.get('metadata')
      let image = nft.get('moralisUpload')
      let imagePreview = nft.get('moralisUploadPreview')

      nftInfo["title"] = metadata ? metadata["title"] : ""
      if (imagePreview) {
        nftInfo["preview"] = `https://rarible.mypinata.cloud/ipfs/${imagePreview._name.split(".")[0]}`;
      }

      if (image) {
        let imageIPFS = `https://rarible.mypinata.cloud/ipfs/${image._name.split(".")[0]}`;
        nftInfo["previewImg"] = imageIPFS;
      } else {
        nftInfo["previewImg"] = undefined;     
      }

      nftInfo["authorLink"] = `/${nft.get('creator')}`
      nftInfo["author"] = nft.get('creator')
      nftInfo["editions"] = nft.get('editionAmount')

      if (!nft.get('lazyMinted')) {
        let editionIsSoldOut = await digitalAsset.methods.isEditionSoldOut(nft.get('editionId')).call();
        nftInfo["soldOut"] = editionIsSoldOut
      } else {
        if (nft.get('editionId')) {
          console.log(nft.get('editionId'))
          let editionIsSoldOut = await digitalAsset.methods.isEditionSoldOut(nft.get('editionId')).call(); 
          nftInfo["soldOut"] = editionIsSoldOut
        } else {
          nftInfo["soldOut"] = false
        }
      }


      if (!nft.get('lazyMinted')) {
        nftInfo["nftLink"] = `/edition/${DiamondNFTToken.networks["941"].address}:${nft.get('editionId')}`

      } else {
        nftInfo["nftLink"] = `/edition/${DiamondNFTToken.networks["941"].address}:${nft.id}`
      }

          if (nft.get('additionalMetadata')) {
            nftInfo["hasAdditionalMetadata"] = true;
          }
      console.log(nftInfo)
      nftsInfo.push(nftInfo)

    }
    console.log(nftsInfo)
    if (nftsInfo.length !== collected.length) {
      console.log(nftsInfo.length)
      console.log(collected.length)
      setCollected([...nftsInfo]);
      setItemIsLoading(false)
    }
  } else if (nftData && nftData.length === 0) {
    setItemIsLoading(false); 
  }
}

}
React.useEffect(() => {
  (async () => {
    await populateData();
  })()
}, [listingData, data, nftData]) /* eslint-disable-line */

return (
  <div>
  <GlobalStyles/>

    {data && data.length > 0 && data[0].get('profilePhotoBanner') ? <section id='profile_banner' className='jumbotron banner_withimage breadcumb no-bg' style={{backgroundImage: `url('${data[0].get('profilePhotoBanner')._url}')`}}>
      <div className='mainbreadcumb'>
      </div>
    </section> : (subjectAddress !== DiamondNFTToken.networks["941"].address ? <section id='profile_banner' className='jumbotron breadcumb no-bg banner'>
      <div className='mainbreadcumb'>
      </div>
    </section> : <section id='profile_banner' className='jumbotron breadcumb no-bg banner_withimage' style={{backgroundImage: `url('${window.location.origin}/img/logo.jpg')`}}>
      <div className='mainbreadcumb'>
      </div>
    </section>)}

    <section className='container d_coll no-top no-bottom'>
      <div className='row'>
        <div className="col-md-12">
          <div className="d_profile">
            <div className="profile_avatar">
              
                <div className="d_profile_img">
                  <UserCollection address={subjectAddress}/>
                </div>
                {isOwner ? <Link style={{margin: "0 auto", marginBottom: "15px"}} className="btn-main" to={`/editCollection?contract_address=${subjectAddress}`}>Edit Collection</Link> : null}
                <div className="profile_name">
                  <h4>
                      {collectionName}                                                
                      <div className="clearfix"></div>
                      <span id="wallet" className="profile_wallet">{subjectAddress}</span>
                      <button id="btn_copy" onClick={() => copyText()} title="Copy Text">Copy</button>
                  </h4>
                  
                  <CollectionDesc address={subjectAddress}/>
                </div>
              </div>
            </div>
        </div>
      </div>
    </section>

    <section className='container no-top'>
          <div className='row'>
            <div className='col-lg-12'>
                <div className="items_filter">
                  <ul className="de_nav">
                      <li id='Mainbtn' className="active"><span onClick={handleBtnClick}>On Sale</span></li>
                      <li id='Mainbtn1' className=""><span onClick={handleBtnClick1}>Items</span></li>
                  </ul>
              </div>
            </div>
          </div>
        <div>
          {openMenu && (DiamondNFTToken.networks["941"].address !== subjectAddress ? (!onSaleIsLoading ?   
            <div id='zero1' className='onStep fadeIn'>
              <ColumnNewRedux shuffle showLoadMore={false} nfts={onSale} listingData={listingData} theme={dark}/>
            </div>
           : <img src="../img/loader.gif" className="image-center" alt=""/>) : <ColumnNewListing shuffle showLoadMore={false} nfts={onSale} listingData={listingData} theme={dark}/>)}
          { wrongNetwork && isAuthenticated &&
              <div className='wrongnet'>
                  <WrongNetwork signOut={signOut}/>
              </div>
          }
          {openMenu1 && (DiamondNFTToken.networks["941"].address !== subjectAddress ? (!itemIsLoading ?
            <div id='zero2' className='onStep fadeIn'>
              <ColumnNewRedux shuffle showLoadMore={false} nfts={collected} theme={dark}/>
            </div>
           : <img src="../img/loader.gif" className="image-center" alt=""/>) : <ColumnNewItems shuffle showLoadMore={false} listingData={nftData}/>)}
        </div>
        </section>
    <Footer theme={dark}/>
  </div>
);

}
export default Collection;