import { React, useEffect, useState } from "react";
import ColumnNewRedux from '../components/ColumnNewRedux';
import ColumnNewListing from "../components/ColumnNewListing"
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import { useMoralisCloudFunction, useMoralis, useNewMoralisObject } from "react-moralis";
import Identicon from 'identicon.js';

import WrongNetwork from "../components/WrongNetwork";

import ERC721 from "../../contracts/ERC721.json";
import ERC1155 from "../../contracts/CustomCollectionERC1155.json";
import IERC165 from "../../contracts/IERC165.json";
import CurrencyManager from "../../contracts/CurrencyManager.json"
import SharedStorefront from "../../contracts/SharedStorefront.json";
import PulseVerseExchange from "../../contracts/PulseVerseExchange.json";
import TransferManagerERC721 from "../../contracts/TransferManagerERC721.json";
import TransferManagerERC1155 from "../../contracts/TransferManagerERC1155.json";
import StrategyStandardSaleForFixedPrice from "../../contracts/StrategyStandardSaleForFixedPrice.json";

import WETH9 from "../../contracts/WETH9.json";
import IPFSGatewayTools from '@pinata/ipfs-gateway-tools/dist/browser';
import tokens from "../../tokens.json";

const ipfsGatewayTools = new IPFSGatewayTools();
const desiredGatewayPrefix = 'https://rarible.mypinata.cloud'


// const shuffle = (array) => {
//   let currentIndex = array.length,  randomIndex;

//   // While there remain elements to shuffle...
//   while (currentIndex != 0) {

//     // Pick a remaining element...
//     randomIndex = Math.floor(Math.random() * currentIndex);
//     currentIndex--;

//     // And swap it with the current element.
//     [array[currentIndex], array[randomIndex]] = [
//       array[randomIndex], array[currentIndex]];
//   }

//   return array;
// }

const copyText = (user) => {
  const subjectAddress = window.location.pathname.split("/")[2];
  window.navigator.clipboard.writeText(subjectAddress)
  document.getElementById("btn_copy").innerHTML = "Copied!";
  setTimeout(function(){
    document.getElementById("btn_copy").innerHTML = "Copy";
  }, 2000);
}

var followIsRunning = false;

const follow = async (user, subjectUser, setFollowed, setUnFollowed, save, fetch) => {
  const subjectAddress = window.location.pathname.split("/")[2];
  if (!followIsRunning) {
    followIsRunning = true;
    if (user.length > 0) {
      await user[0].save('followers', user[0].get('followers')+1);
      let followerList = await user[0].get('followerList');
      followerList.push(subjectUser)
      await user[0].save('followerList', followerList);
      await setFollowed(true);
      await setUnFollowed(false);
      followIsRunning = false;      
    } else {
      let followers = 1;
      let followerList = [subjectUser];
      let ethAddress = subjectAddress.toLowerCase()
      await save({followers, ethAddress, followerList})
      await fetch();
      followIsRunning = false;
    }

  }
}


var unfollowIsRunning = false;
const unfollow = async (user, subjectUser, setUnFollowed, setFollowed, save) => {
    if (!unfollowIsRunning) {
      unfollowIsRunning = true;
      if (user.length > 0) {
        let followerList = await user[0].get('followerList');
        await user[0].save('followers', user[0].get('followers')-1);
        var removedFollower = followerList.filter(function(value, index, arr){ 
          return value !== subjectUser;
        });
        
        await user[0].save('followerList', removedFollower);
        await setUnFollowed(true);
        await setFollowed(false);
        unfollowIsRunning = false;
      }
    }

}

const Colection = function(props) {


  const dark = props.theme;
  const GlobalStyles = createGlobalStyle`
    header#myHeader.navbar.white {
      background: #fff;
    }
    @media only screen and (max-width: 1199px) {
      .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
        background: #111;
      }
      .item-dropdown .dropdown a{
        color: #111 !important;
      }
    }
    h1, h2, h4, h3, h5, h6, .h1_big, .h1, .h2, .h3, .h4, .h5, .h6, span, p, a, input {
      color: ${dark === 'true' ? "#121212" : "white"}
    }
    .maincheckout {
      background: ${dark === 'true' ? "white" : "#121212"};
      color: ${dark === 'true' ? "#121212" : "white"};
    }
  `;

	const { user } = useMoralis()
  const { save } = useNewMoralisObject('Followers');

  const [followed, setFollowed] = useState(false);
  const [unfollowed, setUnFollowed] = useState(false);
  const [subjectAddress, ] = useState(window.location.pathname.split("/")[2])

  const [openMenu, setOpenMenu] = useState(true);
  const [openMenu1, setOpenMenu1] = useState(false);
  const [openMenu2, setOpenMenu2] = useState(false);
  // const [openMenu3, setOpenMenu3] = useState(false);
  const [openMenu4, setOpenMenu4] = useState(false);

  const [created, setCreated] = useState([]);
  const [collected, setCollected] = useState([]);
  const [loadingCollected, setLoadingCollected] = useState(true);
  const [limit, setLimit] = useState(12);

  // const [onSaleIsLoading, setOnSaleIsLoading] = useState(true);
  // const [createdIsLoading, setCreatedIsLoading] = useState(true);
  // const [collectedIsLoading, setCollectedIsLoading] = useState(true);
  // const [collectionsIsLoading, setCollectionsIsLoading] = useState(true);

  const [createdIsLoading, setCreatedIsLoading] = useState(true);
  const [collectedIsLoading, setCollectedIsLoading] = useState(true);
  const [loadingCollection, setLoadingCollection] = useState("")

  // const [pageIsLoading, setPageIsLoading] = useState(true);
  const [collections, ] = useState([])
  const [imageSet, ] = useState("false");

  // const [iterID, setIterID] = useState("")
  // const [caID, setCaID] = useState("")
  // const [edID, setEdID] = useState("")
  const [listener, setListener] = useState(false);

  const [wrongNetwork, setWrongNetwork] = useState(false);
  
  if (window.ethereum && !listener) {
    window.ethereum.on('chainChanged', function(networkId){
      if (networkId !== "0x3ac") {
        setWrongNetwork(true);
      } else {
        setWrongNetwork(false);      
      }
    })
    setListener(true);
  }

  const web3 = window.web3;

  const digitalAsset = new web3.eth.Contract(SharedStorefront.abi, SharedStorefront.networks["941"].address);

            //  "item_type": "", 
            //  "likes": 1,
            //  "deadline": "",
            //  "authorLink": "",
            //  "authorImg": "",
            //  "previewImg": "",
            //  "nftLink": "",
            //  "title": "",
            //  "price": "",
            //  "status": "",
            //  "bidLink": "",
            //  "likes": ""

const { data, isLoading } = useMoralisCloudFunction("queryUserData",
  {"address": subjectAddress.toLowerCase()}
);

// const { fetch: collectionFetch, data: collectionData, error: collectionError, isLoading: loadingCollection } = useMoralisCloudFunction("queryCollectionData",
//   {"address": subjectAddress.toLowerCase()}
// );

// console.log(collectionData)

const { fetch: followerFetch, data: followerData } = useMoralisCloudFunction("queryFollowerData",
  {"address": subjectAddress.toLowerCase()}
);

console.log(followerData)

console.log(data)


const { data: nftData, isLoading: nftIsLoading } = useMoralisCloudFunction("queryNFTData",
  {"address": subjectAddress.toLowerCase()}
);

console.log(nftIsLoading)

const { fetch: listingFetch, data: listingData, error: listingError } = useMoralisCloudFunction("queryMakerAskAll", 
{
  "contractAddress": null,
  "userAddress": subjectAddress.toLowerCase(),
  "listing": null,
  limit
}
);

let username;
let address;
let bio;
let followers;
let followerList = [];
let profilePhoto;
if (followerData) {
  followerList = followerData[0] ? followerData[0].get('followerList') : []
  followers = followerData[0] ? followerData[0].get('followers') : 0
}
let verified;
let profilePhotoBanner;
let personalSite;

let dataJson = JSON.parse(JSON.stringify(data, null, 2));
console.log(dataJson)
if (data !== null) {
  if (dataJson[0]) {
    username = dataJson[0]["username"]
    address = dataJson[0]["ethAddress"]
    console.log(address)
    bio = dataJson[0]["bio"]
    profilePhoto = dataJson[0]["moralisUpload"]
    verified = dataJson[0]["verified"]
    profilePhotoBanner = dataJson[0]["moralisUploadBanner"]
    personalSite = dataJson[0]["personalSite"]

  }
}

// if (collectionData && collectionData[0]) {
//   let collectionsList = collectionData[0].get('collections');

//   if (!collectionsList.includes(SharedStorefront.networks["941"].address)) {
//     collectionsList.push(SharedStorefront.networks["941"].address);
//   }
//   for (let i = 0; i < collectionsList.length; i++) {
//     (async () => {
//       let collection = new web3.eth.Contract(ERC721.abi, collectionsList[i]);
//       let name = await collection.methods.name().call()
//       if (!collections.some(col => (col.name === name && col.address === collectionsList[i]))) {
//         collections.push({"address": collectionsList[i], "name": name, "link": `/collection:${collectionsList[i]}`})
//       }
//     })()
//   }

//   if (collectionsIsLoading) {
//     setCollectionsIsLoading(false);
//   }

// }


console.log(collections);


useEffect(() => {
  (async () => {



// Created Data
  if (nftData) {
    let nftsInfo = []
    for (let i = 0; i < nftData.length; i++) {
      let nftInfo = {}
      let nft = nftData[i]
      let metadata = nft.get('metadata')
      let image = nft.get('moralisUpload')
      let imagePreview = nft.get('moralisUploadPreview')
      let listing = nft.get('listing')

      nftInfo["title"] = metadata ? metadata["title"] : ""
      if (imagePreview) {
        nftInfo["preview"] = `https://rarible.mypinata.cloud/ipfs/${imagePreview._name.split(".")[0]}`;
      }

      if (image) {
        let imageIPFS = `https://rarible.mypinata.cloud/ipfs/${image._name.split(".")[0]}`;
        nftInfo["previewImg"] = imageIPFS;
      } else {
        nftInfo["previewImg"] = undefined;     
      }

      nftInfo["authorImg"] = profilePhoto ? profilePhoto.url : `data:image/png;base64,${new Identicon(subjectAddress.toLowerCase(), 250).toString()}`;
      nftInfo["authorLink"] = `/${nft.get('creator')}`
      nftInfo["author"] = nft.get('creator')
      nftInfo["editions"] = nft.get('editionAmount')

        if (nft.get('additionalMetadata')) {
          nftInfo["hasAdditionalMetadata"] = true;
        }

      if (!nft.get('lazyMinted')) {
        nftInfo["nftLink"] = `/edition/${SharedStorefront.networks["941"].address}:${nft.get('editionId')}`

      } else {
        nftInfo["nftLink"] = `/edition/${SharedStorefront.networks["941"].address}:${nft.id}`
      }

      if (listing) {
        let listingType = listing.get('listing')
        
        if (listingType === "reserve" || listingType === "offers") {
          nftInfo["status"] = 'on_auction' 
        } else if (listingType === "buy" || listingType === "stepped") {
          nftInfo["status"] = 'buy' 
        }

        let editionId = nft.id

        if (nft.get('lazyMinted')) {
          if (listingType === "buy" && editionId) {
            nftInfo["token"] = "0x"
            nftInfo["price"] = "1"
          } else if (listingType === "reserve" && editionId) {
          }
        } else {
          editionId = nft.get('editionId')  
          if (listingType === "buy" && editionId) {
            nftInfo["token"] = "0x"
            nftInfo["price"] = "1"

          } else if (listingType === "reserve" && editionId) {

          }
      } 
      }
      console.log(nftInfo)
      nftsInfo.push(nftInfo)

    }
    if (nftsInfo.length !== created.length) {
      setCreated(nftsInfo); 
      if (createdIsLoading) {
        setCreatedIsLoading(false)
      }
    }
  } else if (!nftIsLoading) {
      if (createdIsLoading) {
        setCreatedIsLoading(false)
      }
  }

  // On Sale Data
  // if (listingData && listingData[0] && !listingIsLoading) {

  //   let nftsInfo = []
  //   for (let i = 0; i < listingData.length; i++) {
  //     let nftInfo = {}
  //     let nft = listingData[i].get('newNFT');
  //     let listingType = listingData[i].get('listing')

  //     if (nft && nft.get('creator')) {

  //       if (!nft.get('lazyMinted')) {
  //         let editionId = nft.get('editionId')
  //         let editionIsSoldOut;
  //         if (editionId) {
  //           editionIsSoldOut = await digitalAsset.methods.isEditionSoldOut(editionId).call();
  //         } else {
  //           editionIsSoldOut = false;
  //         }

  //         nftInfo["soldOut"] = editionIsSoldOut
  //       } else {
  //         if (nft.get('editionId')) {
  //           console.log(nft.get('editionId'))
  //           let editionIsSoldOut = await digitalAsset.methods.isEditionSoldOut(nft.get('editionId')).call(); 
  //             nftInfo["soldOut"] = editionIsSoldOut
  //         } else {
  //           nftInfo["soldOut"] = false
  //         }
                
  //       }
  //       let editionId;
  //       if (nft.get('lazyMinted')) {
  //         editionId = nft.id
  //         if (listingType === "buy" && editionId) {
  //           const buyNowMarketplace = new web3.eth.Contract(BuyNowMarketplace.abi, Diamond.networks["941"].address)
  //           let price = await buyNowMarketplace.methods["editionOrTokenListings(string)"](editionId).call();
  //           if (price.token !== "0x0000000000000000000000000000000000000000") {
  //             if (price.token.toLowerCase() === HEX_CONTRACT_ADDRESS) {
  //               nftInfo["token"] = "HEX";
  //               nftInfo["price"] = price.price / 10 ** 8;
  //             }
  //           } else {
  //             nftInfo["token"] = "PLS";
  //             nftInfo["price"] = web3.utils.fromWei(price.price, "ether");
  //           }
  //           console.log(nftInfo["token"])
  //         } else if (listingType === "reserve" && editionId) {
  //           let price = await reserveAuctionMarketplace.methods["editionOrTokenWithReserveAuctions(string)"](editionId).call();

  //           console.log(price.startDate)
  //           nftInfo["deadline"] = new Date(price.startDate)
  //           if (price.token !== "0x0000000000000000000000000000000000000000") {
  //             if (price.token.toLowerCase() === HEX_CONTRACT_ADDRESS) {
  //               nftInfo["token"] = "HEX";
  //               nftInfo["price"] = price.reservePrice / 10 ** 8;
  //               nftInfo["bid"] = price.bid / 10 ** 8;
  //             }
  //           } else {
  //             nftInfo["token"] = "PLS";
  //             nftInfo["price"] = web3.utils.fromWei(price.reservePrice, "ether");
  //             nftInfo["bid"] = web3.utils.fromWei(price.bid, "ether");
  //           }
  //         } else if (listingType === "offers" && editionId) {
  //           let price = await offersSaleMarketplace.methods["getOffersInfo(string)"](editionId).call();
  //           nftInfo["deadline"] = new Date(price[0])
  //           if (price[1] !== "0x0000000000000000000000000000000000000000") {
  //             if (price[1].toLowerCase() === HEX_CONTRACT_ADDRESS) {
  //               nftInfo["token"] = "HEX";
  //               nftInfo["bid"] = price[3].offer / 10 ** 8;
  //             }
  //           } else {
  //             nftInfo["token"] = "PLS";
  //             nftInfo["bid"] = web3.utils.fromWei(price[3].offer);
  //           }           
  //         } else if (listingType === "stepped" && editionId){
  //           let nextPrice = await steppedSaleMarketplace.methods["getNextEditionSteppedPrice(string)"](editionId).call();
  //           let price = await steppedSaleMarketplace.methods["editionStep(string)"](editionId).call();
  //           if (price.token !== "0x0000000000000000000000000000000000000000") {
  //             if (price.token.toLowerCase() === HEX_CONTRACT_ADDRESS) {
  //               nftInfo["token"] = "HEX";
  //               nftInfo["price"] = nextPrice / 10 ** 8;
  //             }
  //           } else {
  //             nftInfo["token"] = "PLS";
  //             nftInfo["price"] = web3.utils.fromWei(nextPrice, "ether");
  //           }
  //         }
  //       } else {
  //         editionId = nft.get('editionId')     
  //         if (listingType === "buy" && editionId) {
  //           let price = await buyNowMarketplace.methods["editionOrTokenListings(uint256)"](editionId).call();
  //           if (price.token !== "0x0000000000000000000000000000000000000000") {
  //             if (price.token.toLowerCase() === HEX_CONTRACT_ADDRESS) {
  //               nftInfo["token"] = "HEX";
  //               nftInfo["price"] = price.price / 10 ** 8;
  //             }
  //           } else {
  //             nftInfo["token"] = "PLS";
  //             nftInfo["price"] = web3.utils.fromWei(price.price, "ether");
  //           }
  //           console.log(nftInfo["token"])
  //         } else if (listingType === "reserve" && editionId) {
  //           let price = await reserveAuctionMarketplace.methods["editionOrTokenWithReserveAuctions(uint256)"](editionId).call();


  //           nftInfo["deadline"] = new Date(price.startDate)
  //           if (price.token !== "0x0000000000000000000000000000000000000000") {
  //             if (price.token.toLowerCase() === HEX_CONTRACT_ADDRESS) {
  //               nftInfo["token"] = "HEX";
  //               nftInfo["price"] = price.reservePrice / 10 ** 8;
  //               nftInfo["bid"] = price.bid / 10 ** 8;
  //             }
  //           } else {
  //             nftInfo["token"] = "PLS";
  //             nftInfo["price"] = web3.utils.fromWei(price.reservePrice, "ether");
  //             nftInfo["bid"] = web3.utils.fromWei(price.bid, "ether");
  //           }
  //         } else if (listingType === "offers" && editionId) {
  //           let price = await offersSaleMarketplace.methods["getOffersInfo(uint256)"](editionId).call();
  //           nftInfo["deadline"] = new Date(price[0])
  //           if (price[1] !== "0x0000000000000000000000000000000000000000") {
  //             if (price[1].toLowerCase() === HEX_CONTRACT_ADDRESS) {
  //               nftInfo["token"] = "HEX";
  //               nftInfo["bid"] = price[3].offer / 10 ** 8;
  //             }
  //           } else {
  //             nftInfo["token"] = "PLS";
  //             nftInfo["bid"] = web3.utils.fromWei(price[3].offer);
  //           }               
  //         } else if (listingType === "stepped" && editionId) {
  //           let nextPrice = await steppedSaleMarketplace.methods["getNextEditionSteppedPrice(uint256)"](editionId).call();
  //           let price = await steppedSaleMarketplace.methods["editionStep(uint256)"](editionId).call();
  //           if (price.token !== "0x0000000000000000000000000000000000000000") {
  //             if (price.token.toLowerCase() === HEX_CONTRACT_ADDRESS) {
  //               nftInfo["token"] = "HEX";
  //               nftInfo["price"] = nextPrice / 10 ** 8;
  //             }
  //           } else {
  //             nftInfo["token"] = "PLS";
  //             nftInfo["price"] = web3.utils.fromWei(nextPrice, "ether");
  //           }
  //         }
  //       }

  //       let metadata = nft.get('metadata')
  //       let image = nft.get('moralisUpload')
  //       let imagePreview = nft.get('moralisUploadPreview')


  //       if (imagePreview) {
  //         nftInfo["preview"] = `https://rarible.mypinata.cloud/ipfs/${imagePreview._name.split(".")[0]}`;
  //       }

  //       nftInfo["title"] = metadata ? metadata["title"] : undefined
  //       if (image) {
  //         let imageIPFS = `https://rarible.mypinata.cloud/ipfs/${image._name.split(".")[0]}`;
  //         nftInfo["previewImg"] = imageIPFS;

  //       } else {
  //         nftInfo["previewImg"] = undefined;  
  //       }

  //       nftInfo["authorImg"] = profilePhoto ? profilePhoto.url : `data:image/png;base64,${new Identicon(subjectAddress.toLowerCase(), 250).toString()}`;
  //       nftInfo["authorLink"] = `/${subjectAddress.toLowerCase()}`
  //       nftInfo["author"] = subjectAddress.toLowerCase();
  //       if (listingType === "reserve" || listingType === "offers") {
  //         nftInfo["status"] = 'on_auction' 
  //       } else if (listingType === "buy" || listingType === "stepped") {
  //         nftInfo["status"] = 'buy' 
  //       }
  //       nftInfo["editions"] = nft.get('editionAmount')
  //       if (!nft.get('lazyMinted')) {
  //         nftInfo["nftLink"] = `/edition/${listingData[i].get('contractAddress')}:${nft.get('editionId')}`
  //       } else {
  //         nftInfo["nftLink"] = `/edition/${listingData[i].get('contractAddress')}:${nft.id}`
  //       }

  //       if (nft.get('additionalMetadata')) {
  //         nftInfo["hasAdditionalMetadata"] = true;
  //       }
  //             console.log(nftInfo)
  //     nftsInfo.push(nftInfo)
  //     if (nftsInfo.length !== onSale.length) {
  //       setOnSale(nftsInfo)
  //       if (onSaleIsLoading) {
  //         setOnSaleIsLoading(false)
  //       }
  //     }
  //     } else if (!nft) {

  //       let editionId = listingData[i].get('tokenId');
  //       let contractAddress = listingData[i].get('contractAddress');
  //       let digitalAsset = new web3.eth.Contract(ERC721.abi, listingData[i].get('contractAddress'));
  //       let uri = await digitalAsset.methods.tokenURI(listingData[i].get('tokenId')).call();
  //       let finalURI;
  //       try {
  //         finalURI = `https://thawing-peak-27665.herokuapp.com/${ipfsGatewayTools.convertToDesiredGateway(uri, desiredGatewayPrefix)}`;
  //       } catch (error) {
  //           if (!uri.startsWith("data:application")) {
  //             finalURI = `https://thawing-peak-27665.herokuapp.com/${uri}`;
  //           } else {
  //             finalURI = uri
  //           }
  //       }
  //       let response = await fetch(finalURI
  //         ,{
  //           headers : { 
  //             'Content-Type': 'application/json',
  //             'Accept': 'application/json'
  //            }
  //         }
  //         )

  //       let myJson = await response.json()
  //             if (myJson["name"]) {
  //               nftInfo["title"] = myJson["name"]
  //             } else {
  //               nftInfo["title"] = `#${editionId}`
  //             }

  //             let imagePreview;
  //             if (myJson["image_preview"] !== "") {

  //               try {
  //                   imagePreview = ipfsGatewayTools.convertToDesiredGateway(myJson["image_preview"], desiredGatewayPrefix);
  //               } catch (error) {
  //                   imagePreview = myJson["image_preview"];
  //               }
  //             }

  //             nftInfo["editions"] = "1"
  //             let image;
  //             try {
  //               nftInfo["previewImg"] = ipfsGatewayTools.convertToDesiredGateway(myJson["image"], desiredGatewayPrefix);
  //             } catch (error) {
  //               nftInfo["previewImg"] = myJson["image"];
  //             }
              
  //             if (listingType === "reserve" || listingType === "offers") {
  //               nftInfo["status"] = 'on_auction' 
  //             } else if (listingType === "buy" || listingType === "stepped") {
  //               nftInfo["status"] = 'buy'
  //             }



  //             if (imagePreview && imagePreview._name) {
  //               nftInfo["preview"] = `https://rarible.mypinata.cloud/ipfs/${imagePreview._name.split(".")[0]}`;
  //             }
  //             nftInfo["authorImg"] = profilePhoto ? profilePhoto.url : `data:image/png;base64,${new Identicon(subjectAddress.toLowerCase(), 250).toString()}`;
  //             nftInfo["authorLink"] = `/${subjectAddress.toLowerCase()}`
  //             nftInfo["author"] = subjectAddress.toLowerCase()
  //             nftInfo["nftLink"] = `/token/${listingData[i].get('contractAddress')}:${listingData[i].get('tokenId')}`

  //             if (listingType === "buy" && editionId) {
  //               let price = await buyNowSecondaryMarketplace.methods["editionOrTokenListingsSecondary(uint256,address)"](editionId,contractAddress).call();
  //               if (price.token !== "0x0000000000000000000000000000000000000000") {
  //                 if (price.token.toLowerCase() === HEX_CONTRACT_ADDRESS) {
  //                   nftInfo["token"] = "HEX";
  //                   nftInfo["price"] = price.price / 10 ** 8;
  //                 }
  //               } else {
  //                 nftInfo["token"] = "PLS";
  //                 nftInfo["price"] = web3.utils.fromWei(price.price, "ether");
  //               }
  //             } else if (listingType === "reserve" && editionId) {
  //               let price = await reserveAuctionSecondaryMarketplace.methods["editionOrTokenWithReserveAuctionsSecondary(uint256,address)"](editionId,contractAddress).call();

  //               nftInfo["deadline"] = new Date(price.startDate)
  //               if (price.token !== "0x0000000000000000000000000000000000000000") {
  //                 if (price.token.toLowerCase() === HEX_CONTRACT_ADDRESS) {
  //                   nftInfo["token"] = "HEX";
  //                   nftInfo["price"] = price.reservePrice / 10 ** 8;
  //                   nftInfo["bid"] = price.bid / 10 ** 8;
  //                 } else {
  //                 }
  //               } else {
  //                 nftInfo["token"] = "PLS";
  //                 nftInfo["price"] = web3.utils.fromWei(price.reservePrice, "ether");
  //                 nftInfo["bid"] = web3.utils.fromWei(price.bid, "ether");
  //               }
  //             } else if (listingType === "offers" && editionId) {
  //               let price = await offersSaleSecondaryMarketplace.methods["getOffersInfoSecondary(uint256,address)"](editionId,contractAddress).call();

  //               nftInfo["deadline"] = new Date(price[0])
  //               if (price[1] !== "0x0000000000000000000000000000000000000000") {
  //                 if (price[1].toLowerCase() === HEX_CONTRACT_ADDRESS) {
  //                   nftInfo["token"] = "HEX";
  //                   nftInfo["bid"] = price[3].offer / 10 ** 8;
  //                 }
  //               } else {
  //                 nftInfo["token"] = "PLS";
  //                 nftInfo["bid"] = web3.utils.fromWei(price[3].offer, "ether");
                  
  //               }
 
  //             }

               
  //     console.log(nftInfo)
  //     nftsInfo.push(nftInfo)
  //     if (nftsInfo.length !== onSale.length) {
  //       setOnSale(nftsInfo)
  //       if (onSaleIsLoading) {
  //         setOnSaleIsLoading(false)
  //       }
  //     }  
  //     }

  //   }


  // } else if (!listingIsLoading) {
  //     if (onSaleIsLoading) {
  //       setOnSaleIsLoading(false)
  //     }
  // }




console.log(collections)


})();
}, [data, nftData, listingData, followerData]) /* eslint-disable-line */

useEffect(() => {
  (async () => {
// Collected


let userTokens = [];
let collectedInfo = []

if (collectedIsLoading) {
  const response = await fetch(`https://scan.v2b.testnet.pulsechain.com/api?module=account&action=tokenlist&address=${subjectAddress}`)
  const json = await response.json();
  const result = json["result"]
  
  console.log(result)

  // let oneExists = false;
  
  // let balanceOf = await digitalAsset.methods.totalTokenSupply().call()
  // for (let j = 0; j < balanceOf; j++) {
  //   console.log(j)
  //   console.log(collectedIsLoading)
  //   let token = await digitalAsset.methods.tokenOfOwnerByIndex(subjectAddress.toLowerCase(), j).call();
  //   let exists = await digitalAsset.methods.exists(token).call();
  //   if (exists) {
  //     oneExists = true;
  //   }
  //   await digitalAsset.methods.getCreatorOfToken(token).call();
  //   console.log(token)
  //   console.log(exists)
  //   if (exists) {
  //     let info = {"tokenId": token, "address": digitalAsset._address}
  //     let uri = await digitalAsset.methods.tokenURI(info["tokenId"]).call();
  //     console.log(uri)
  //     let finalURI;
  //     try {
  //       finalURI = `https://thawing-peak-27665.herokuapp.com/${ipfsGatewayTools.convertToDesiredGateway(uri, desiredGatewayPrefix)}`;
  //     } catch (error) {
  //         if (!uri.startsWith("data:application")) {
  //           finalURI = `https://thawing-peak-27665.herokuapp.com/${uri}`;
  //         } else {
  //           finalURI = uri
  //         }
  //     }
  //     let collInfo = {}
  //     console.log()
  //     let response = await fetch(finalURI
  //       ,{
  //         headers : { 
  //           'Content-Type': 'application/json',
  //           'Accept': 'application/json'
  //          }
  //       }
  //       )
  
  //       try {
  //         if (response.ok) {
  //           console.log(response)
  //           let myJson = await response.json()
    
  //           console.log(myJson)
    
  //           if (myJson["name"]) {
  //             collInfo["title"] = myJson["name"]
  //           } else {
  //             collInfo["title"] = `#${info["tokenId"]}`
  //           }
    
  //           let image;
  //           let imagePreview;

  //           if (myJson["image"]) {
  //             try {
  //                 image = ipfsGatewayTools.convertToDesiredGateway(myJson["image"], desiredGatewayPrefix);
  //             } catch (error) {
  //                 image = myJson["image"];
  //             }
  //           } else if (myJson["image_url"]) {
  //               try {
  //                   image = ipfsGatewayTools.convertToDesiredGateway(myJson["image_url"], desiredGatewayPrefix);
  //               } catch (error) {
  //                   image = myJson["image_url"];
  //               }  
  //           }

    
  //           try {
  //               imagePreview = ipfsGatewayTools.convertToDesiredGateway(myJson["image_preview"], desiredGatewayPrefix);
  //           } catch (error) {
  //               imagePreview = myJson["image_preview"];
  //           }
    
  //           collInfo["previewImg"] = image
  //           console.log(imagePreview)
  //         if (imagePreview) {
  //           collInfo["preview"] = imagePreview;
  //         }
    
  //           collInfo["authorImg"] = profilePhoto ? profilePhoto.url : `data:image/png;base64,${new Identicon(subjectAddress.toLowerCase(), 250).toString()}`;
  //           collInfo["authorLink"] = `/${subjectAddress.toLowerCase()}`
  //           collInfo["author"] = subjectAddress.toLowerCase()
  //           collInfo["nftLink"] = `/token/${info["address"]}:${info["tokenId"]}`
  //           collInfo["editions"] = "1"
    
        
    
  //         collectedInfo.push(collInfo)
  //         console.log(collectedInfo)
  
  
  //         console.log(collectedInfo)

          
        
  //         console.log(userTokens)
  
  //         }
    
  //       } catch (error) {
          
  //       }
  //   }
  // }

  // if (oneExists) {
  //   setCollected([...collectedInfo]); 
  //   if (collectedIsLoading) {
  //     setCollectedIsLoading(false);
  //   }
  // }
  
  
  // for (let i = 0; i < result.length; i++) {
   
  
  //       setLoadingCollection(result[i]["name"])
  
  //       if (result[i]["type"] === "ERC-721" && result[i]["contractAddress"] !== SharedStorefront.networks["941"].address.toLowerCase()) { 
  //         console.log(result[i])
  //         console.log(SharedStorefront.networks["941"].address)
          
  //         // let balanceOf = await digitalAsset.methods.balanceOf(subjectAddress.toLowerCase()).call()
      
  //         for (let j = 0; j < parseInt(result[i]["balance"]); j++) {
      
  //             try {
  //                 let digitalAsset = new web3.eth.Contract(ERC721.abi, result[i]["contractAddress"]);
  //                 let name = await digitalAsset.methods.name().call();
  //                 let digitalAssetEnumerable = new web3.eth.Contract(ERC721.abi, result[i]["contractAddress"]);
  //                 let token = await digitalAssetEnumerable.methods.tokenOfOwnerByIndex(subjectAddress, j).call();
  //                 await digitalAsset.methods.ownerOf(token).call();
  //                 let info = {"tokenId": token, "address": digitalAsset._address}
  //                 let uri = await digitalAsset.methods.tokenURI(info["tokenId"]).call();
  //                 console.log(uri)
  //                 let finalURI;
  //                 try {
  //                   finalURI = `https://thawing-peak-27665.herokuapp.com/${ipfsGatewayTools.convertToDesiredGateway(uri, desiredGatewayPrefix)}`;
  //                 } catch (error) {
  //                     if (!uri.startsWith("data:application")) {
  //                       finalURI = `https://thawing-peak-27665.herokuapp.com/${uri}`;
  //                     } else {
  //                       finalURI = uri
  //                     }
  //                 }
  //                 let collInfo = {}
  //                 console.log()
  //                 let response = await fetch(finalURI
  //                   ,{
  //                     headers : { 
  //                       'Content-Type': 'application/json',
  //                       'Accept': 'application/json'
  //                      }
  //                   }
  //                   )
              
  //                   try {
  //                     if (response.ok) {
  //                       console.log(response)
  //                       let myJson = await response.json()
                
  //                       console.log(myJson)
                
  //                       if (myJson["name"]) {
  //                         collInfo["title"] = myJson["name"]
  //                       } else {
  //                         collInfo["title"] = `${name} #${info["tokenId"]}`
  //                       }
                
  //                       let image;
  //                       let imagePreview;

  //                       if (myJson["image"]) {
  //                         try {
  //                             image = ipfsGatewayTools.convertToDesiredGateway(myJson["image"], desiredGatewayPrefix);
  //                         } catch (error) {
  //                             image = myJson["image"];
  //                         }
  //                       } else if (myJson["image_url"]) {
  //                           try {
  //                               image = ipfsGatewayTools.convertToDesiredGateway(myJson["image_url"], desiredGatewayPrefix);
  //                           } catch (error) {
  //                               image = myJson["image_url"];
  //                           }  
  //                       }
                
  //                       try {
  //                           imagePreview = ipfsGatewayTools.convertToDesiredGateway(myJson["image_preview"], desiredGatewayPrefix);
  //                       } catch (error) {
  //                           imagePreview = myJson["image_preview"];
  //                       }
                
  //                       collInfo["previewImg"] = image
  //                       console.log(imagePreview)
  //                     if (imagePreview) {
  //                       collInfo["preview"] = imagePreview;
  //                     }
                
  //                       collInfo["authorImg"] = profilePhoto ? profilePhoto.url : `data:image/png;base64,${new Identicon(subjectAddress.toLowerCase(), 250).toString()}`;
  //                       collInfo["authorLink"] = `/${subjectAddress.toLowerCase()}`
  //                       collInfo["author"] = subjectAddress.toLowerCase()
  //                       collInfo["nftLink"] = `/token/${info["address"]}:${info["tokenId"]}`
  //                       collInfo["editions"] = "1"
                
                    
                
  //                     collectedInfo.push(collInfo)
                      
  //                     console.log(collectedInfo)
                    
  //                     console.log(userTokens)
      
  //                     }
                
  //                   } catch (error) {
                      
  //                   }
  //             } catch (error) {
  //             }
      
      
  //         }
  //       }
  //     if (collectedInfo.length > collected.length) {
  //       setCollected([...collectedInfo]); 
  //       if (collectedIsLoading) {
  //         setCollectedIsLoading(false);
  //       }
  //       console.log(collectedInfo)
  //       console.log(collected)
  //     }
      
    
  //     if (i === result.length - 1) {
  //       setLoadingCollected(false);
  //     }
      
  //     console.log(userTokens)
  
  
  
  // }
  
  
  if (result.length === 0) {
      setLoadingCollected(false);
      setCollectedIsLoading(false);
  }
  
}


  })()
}, []) /* eslint-disable-line */

const handleBtnClick = () => {
  setOpenMenu(!openMenu);
  setOpenMenu1(false);
  setOpenMenu2(false);
  // setOpenMenu3(false);
  setOpenMenu4(false);
  document.getElementById("Mainbtn").classList.add("active");
  document.getElementById("Mainbtn1").classList.remove("active");
  document.getElementById("Mainbtn2").classList.remove("active");
  // document.getElementById("Mainbtn3").classList.remove("active");
  document.getElementById("Mainbtn4").classList.remove("active");
};
const handleBtnClick1 = () => {
  setOpenMenu1(!openMenu1);
  setOpenMenu2(false);
  setOpenMenu(false);
  // setOpenMenu3(false);
  setOpenMenu4(false);
  document.getElementById("Mainbtn1").classList.add("active");
  document.getElementById("Mainbtn").classList.remove("active");
  document.getElementById("Mainbtn2").classList.remove("active");
  // document.getElementById("Mainbtn3").classList.remove("active");
  document.getElementById("Mainbtn4").classList.remove("active");
};
const handleBtnClick2 = () => {
  setOpenMenu2(!openMenu2);
  setOpenMenu(false);
  setOpenMenu1(false);
  // setOpenMenu3(false);
  setOpenMenu4(false);
  document.getElementById("Mainbtn2").classList.add("active");
  document.getElementById("Mainbtn").classList.remove("active");
  document.getElementById("Mainbtn1").classList.remove("active");
  // document.getElementById("Mainbtn3").classList.remove("active");
  document.getElementById("Mainbtn4").classList.remove("active");
  
};
// const handleBtnClick3 = () => {
//   setOpenMenu3(!openMenu3);
//   setOpenMenu(false);
//   setOpenMenu1(false);
//   setOpenMenu2(false);
//   setOpenMenu4(false);
//   document.getElementById("Mainbtn3").classList.add("active");
//   document.getElementById("Mainbtn").classList.remove("active");
//   document.getElementById("Mainbtn1").classList.remove("active");
//   document.getElementById("Mainbtn2").classList.remove("active");
//   document.getElementById("Mainbtn4").classList.remove("active");
// };

const handleBtnClick4 = () => {
  setOpenMenu4(!openMenu4);
  setOpenMenu(false);
  setOpenMenu1(false);
  setOpenMenu2(false);
  // setOpenMenu3(false);
  document.getElementById("Mainbtn4").classList.add("active");
  document.getElementById("Mainbtn").classList.remove("active");
  document.getElementById("Mainbtn1").classList.remove("active");
  document.getElementById("Mainbtn2").classList.remove("active");
  // document.getElementById("Mainbtn3").classList.remove("active");
};


  return (
    <div>
      <><GlobalStyles/><div className='onStep fadeIn' style={{paddingTop: "25px"}}>
          {profilePhotoBanner ? <section id='profile_banner' className='jumbotron breadcumb no-bg banner_withimage onStep fadeIn' style={{backgroundImage: `url('${profilePhotoBanner.url}')`}}>
          <div className='mainbreadcumb onStep fadeIn'>
          </div>
        </section> : <section id='profile_banner' className='jumbotron breadcumb no-bg banner onStep fadeIn'>
          <div className='mainbreadcumb onStep fadeIn'>
      
          </div>
        </section>
      }</div>
    
      <section className='container no-bottom' style={{paddingTop: "50px"}}>
        <div className='row'>
          <div className="col-md-12">
             <div className="d_profile de-flex">
                  <div className="de-flex-col">
                      <div className="profile_avatar onStep fadeIn">
                          {profilePhoto ? <img src={profilePhoto.url} style={{width: "150px", height: "150px"}} className='onStep' alt=""/> : <img src={`data:image/png;base64,${new Identicon(subjectAddress.toLowerCase(), 250).toString()}`} alt=""/>}
                          {verified ? <i className="fa fa-check"></i> : <></>}
                          <div className="profile_name">
                              <h4>
                                  {username}   
                                  <a target="_blank" rel="noreferrer" href={personalSite} style={{"text-decoration": "underline", "color": "#EA14BD"}}><span className="profile_username">{personalSite ? personalSite.split("//")[1] : <></>}</span></a>                                           
                                  <span className="profile_username">{bio}</span>
                                  <span id="wallet" className="profile_wallet">{subjectAddress.toLowerCase()}</span>
                                  <button id="btn_copy" onClick={copyText} title="Copy Text">Copy</button>
                              </h4>
                          </div>
                      </div>
                  </div>
                  <div className="profile_follow de-flex">
                      <div className="de-flex-col">
                          <div className="profile_follower"><span>{followers} followers</span></div>
                      </div>
                      <div className="de-flex-col">
                          {(user && !followerList.includes(user.get('ethAddress'))) || unfollowed ? <span id="follow" onClick={async () => await follow(followerData, user.get('ethAddress'), setFollowed, setUnFollowed, save, followerFetch)} className="btn-main">Follow</span> : <></>}
                          {(user && followerList.includes(user.get('ethAddress'))) || followed ? <span id="unfollow" onClick={async () => await unfollow(followerData, user.get('ethAddress'), setUnFollowed, setFollowed, save, followerFetch)} className="btn-main">Unfollow</span> : <></>}
                      </div>
                  </div>
    
              </div>
          </div>
        </div>
      </section>
    
      <section className='container no-top'>
            <div className='row'>
              <div className='col-lg-12'>
                  <div className="items_filter">
                    <ul className="de_nav text-right">
                        <li id='Mainbtn' className="active"><span onClick={handleBtnClick}>On Sale</span></li>
                        <li id='Mainbtn1' className=""><span onClick={handleBtnClick1}>Created</span></li>
                        <li id='Mainbtn2' className=""><span onClick={handleBtnClick2}>Collected</span></li>
                        {/* <li id='Mainbtn3' className=""><span onClick={handleBtnClick3}>Collections</span></li> */}
                        <li id='Mainbtn4' className=""><span onClick={handleBtnClick4}>Active Bids</span></li>
                    </ul>
                </div>
              </div>
            </div>
          {openMenu &&  
            <div id='zero1' className='onStep fadeIn'>
             <ColumnNewListing shuffle showLoadMore={false} listingData={listingData} imageSet={imageSet} theme={dark}/>
            </div>}
          {openMenu1 && ( !createdIsLoading ?
            <div id='zero2' className='onStep fadeIn'>
             <ColumnNewRedux shuffle showLoadMore={false} nfts={created} imageSet={imageSet} theme={dark}/>
            </div>
          : <img src="../img/loader.gif" className="image-center" alt=""/>)}
          {openMenu2 && ( !collectedIsLoading ? 
            <div id='zero3' className='onStep fadeIn'>
             <ColumnNewRedux shuffle showLoadMore={false} nfts={collected} imageSet={imageSet} theme={dark}/>
             {loadingCollected && <><img src="../img/loader.gif" style={{marginTop: "0px"}} className="image-center" alt=""/><p className="text-center">Loading your {loadingCollection} NFTs...</p></>}
            </div>
           : <><img src="../img/loader.gif" style={{marginTop: "0px"}} className="image-center" alt=""/><p className="text-center">Loading your {loadingCollection} NFTs...</p></>)}
          {/* {openMenu3 && ( collections.length > 0 ? ( !collectionsIsLoading ? 
            <div id='zero3' className='onStep fadeIn'>
              {user && user.get('ethAddress') === subjectAddress.toLowerCase() ? <ImportCollection user={collectionData} fetch={collectionFetch} initialState={true}/> : null}
              <CarouselCollection collections={collections}/>
            </div>        
          : <img src="../img/loader.gif" className="image-center"/>) : (<div id='import-sec' className='onStep fadeIn'>
            {user && user.get('ethAddress') === subjectAddress.toLowerCase() ? <ImportCollection user={collectionData} save={saveCollection} fetch={collectionFetch} initialState={false}/> : null}
          </div> ))} */}
          {/* {openMenu4 && (
            <BidData user={user}/>
          )} */}
          </section>
      <Footer theme={dark}/>
      { wrongNetwork &&
            <div className='wrongnet'>
                <WrongNetwork/>
            </div>
        }
      </>
    </div>
    );

}
export default Colection;