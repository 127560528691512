import React, { memo, useEffect, useState} from 'react';
import styled from "styled-components";
import Clock from "./Clock";
import { navigate } from '@reach/router';
import UserImage from '../components/UserImage'
import NftImage from '../components/NftImage'
import { useMoralisCloudFunction } from "react-moralis";

import Token from "../../contracts/KnownOriginDigitalAssetV3.json";

import DiamondNFTToken from "../../contracts/DiamondNFTToken.json";
 

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

//react functional component
const NftCard = ({ nft, className = 'd-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4', clockTop = true, height, onImgLoad }) => {
    const { data: dataUnlock } = useMoralisCloudFunction("queryUnlockData",
        {"tokenId": nft && nft.get('tokenId'), "contractAddress": nft && nft.get('contractAddress')},
    );

    const [nftData, setNftData] = useState({});
    const [nftLoading, setNftIsLoading] = useState(true)

    useEffect(() => {
        const web3 = window.web3;
        const digitalAsset = new web3.eth.Contract(Token.abi, DiamondNFTToken.networks["941"].address);

        (async () => {

    
            if (nft && nft.get('creator')) {
              let nftInfo = {}
              console.log(nft.get('editionId'))
              if (!nft.get('lazyMinted')) {
                let editionIsSoldOut = false;
                try {
                  console.log(nft.get('editionId'))
                  editionIsSoldOut = await digitalAsset.methods.isEditionSoldOut(nft.get('editionId')).call();
                } catch (error) {
                  console.error(error)
                  console.log(nft)
                }
      
                nftInfo["soldOut"] = editionIsSoldOut
              } else {
                if (nft.get('editionId')) {
    
                  console.log(nft.get('editionId'))
                  let editionIsSoldOut = await digitalAsset.methods.isEditionSoldOut(nft.get('editionId')).call(); 
                    nftInfo["soldOut"] = editionIsSoldOut
                } else {
                  nftInfo["soldOut"] = false
                }
                      
              }
              let editionId;
              if (nft.get('lazyMinted')) {
                editionId = nft.id
              } else {
                editionId = nft.get('editionId')     
              }
      
              console.log(editionId)
              console.log(nft)
              let metadata = nft.get('metadata')
              let image = nft.get('moralisUpload')
              let imagePreview = nft.get('moralisUploadPreview')
      
              console.log(imagePreview)
      
              if (imagePreview) {
                nftInfo["preview"] = `https://rarible.mypinata.cloud/ipfs/${imagePreview._name.split(".")[0]}`;
              }
      
              console.log(image)
              if (image) {
                nftInfo["title"] = metadata ? metadata["title"] : undefined
                let imageIPFS = `https://rarible.mypinata.cloud/ipfs/${image._name.split(".")[0]}`;
                nftInfo["previewImg"] = imageIPFS;
              }
    
      
              nftInfo["author"] = nft.get('creator');
              nftInfo["authorLink"] = `/${nft.get('creator')}`
              nftInfo["editions"] = nft.get('editionAmount')
              if (!nft.get('lazyMinted')) {
                nftInfo["nftLink"] = `/edition/${DiamondNFTToken.networks["941"].address}:${nft.get('editionId')}`
                
              } else {
                nftInfo["nftLink"] = `/edition/${DiamondNFTToken.networks["941"].address}:${nft.id}`
                
              }
    
              if (nft.get('additionalMetadata')) {
                nftInfo["hasAdditionalMetadata"] = true;
              }
    
              console.log("Dwass")
    
              console.log(nftInfo)
              setNftData(nftInfo)
              setNftIsLoading(false)
            }
        })()

    }, [nft])

    const navigateTo = (link) => {
        navigate(link);
    }
    // console.log(nft)

    if (!nftLoading) {
        return (

            <div className={className}>
                <a href={nftData.nftLink}>
                <div className="nft__item m-0">
                { nftData.item_type === 'single_items' ? (
                 <div className='icontype'><i className="fa fa-bookmark"></i></div>   
                 ) : (  
                 <div className='icontype'><i className="fa fa-shopping-basket"></i></div>
                    )
                }
                    { nftData.deadline && clockTop &&
                        <div className="de_countdown">
                            <Clock deadline={nftData.deadline} />
                        </div>
                    }
                    <div className="nft__item_wrap" style={{height: `250px`}}>
                    <Outer>
                        {<NftImage previewImg={nftData.previewImg} preview={nftData.preview}/>}
                    </Outer>
                    </div>
                    { nftData.deadline && !clockTop &&
                        <div className="de_countdown">
                            <Clock deadline={nftData.deadline} />
                        </div>
                    }
                    {(nftData.hasAdditionalMetadata || (dataUnlock && dataUnlock[0])) && (<div style={
                                {
                                    padding: "15px", 
                                    backgroundColor: "#F6F6F6", 
                                    borderRadius: "25px", 
                                    border: "1px solid black",
                                    height: "55px",
                                    width: "100%",
                                    overflow: "scroll",
                                    fontSize: "15px"
                                }}><p><i className="fa fa- fa-unlock-alt id-color-2 mr10" style={{marginRight: "5px", color: "black"}}></i><span style={{"font-weight": "bold", "color": "black"}}>Includes unlockable content</span></p></div>)}
                    <div className="nft__item_info">
                        <span onClick={() => window.open(nftData.nftLink, '_self')}>
                            <h4>{nftData.title}</h4>
                        </span>
                        { nftData.status === 'has_offers' ? (
                                <div className="has_offers">
                                    <span className='through'>{nftData.priceover}</span> {nftData.price}
                                </div> 
                            ) : (
                                !nftData.soldOut ? (<div className="nft__item_price">
                                    {nftData.price ? `${nftData.price} ${nftData.token}` : null} {nftData.price && <span id="itemRangePreview">1/{nftData.editions}</span>}
                                    { nftData.status === 'on_auction' && 
                                        (nftData.bid > 0 ? <><div>Best Offer: {nftData.bid} {nftData.token}<span>1/{nftData.editions}</span></div></> : <><div>No offers yet.</div></>)
                                    }
                                </div>) : <></>
                            )
                        }
                        {!nftData.soldOut ? <div className="nft__item_action">
                            <span onClick={() => navigateTo(nftData.bidLink)}>{ nftData.status ? (nftData.status === 'on_auction' ? 'Place a bid' : 'Buy Now') : 'View Item'}</span>
                        </div> : <div className="nft__item_action">
                            <span onClick={() => navigateTo(nftData.bidLink)}>{ 'SOLD OUT' }</span>
                        </div>}
                        <div className="nft__item_like">
                            <i className="fa fa-heart"></i><span>{nftData.likes}</span>
                        </div>     
                    </div> 

                                
                </div>
                </a>  
            </div>    
        );
    } else {
        return (
            <div className={className}>
                                <div className="nft__item m-0">
                                <img src="./img/loader.gif" className="image-center" alt=""/>
                                </div>
            </div>
        )
    }
 
 
};

export default memo(NftCard);