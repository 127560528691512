import React, { memo } from "react";
import { createGlobalStyle } from 'styled-components';
import WETH9 from "../../contracts/WETH9.json";
import { useMoralis } from "react-moralis";

const SwapModal = (props) => {
	const dark = localStorage.getItem('darkMode');
	const [inputCurrency, setInputCurrency] = React.useState("PLS");
	const [outputCurrency, setOutputCurrency] = React.useState("WPLS")
	const { isAuthenticated, user, logout } = useMoralis()
	const [wethBalance, setWethBalance] = React.useState(null)
	const [ethBalance, setEthBalance] = React.useState(null);
	const [converted, setConverted] = React.useState(false);

	React.useEffect(() => {
		const loadBalances = async () => {
			const web3 = window.web3;
			const weth = new web3.eth.Contract(WETH9.abi, WETH9.networks["941"].address)
			let balance = await weth.methods.balanceOf(user.get('ethAddress')).call();
			setWethBalance(balance)

			const userEthBalance = await web3.eth.getBalance(user.get('ethAddress'));
			setEthBalance(userEthBalance);
		}

		loadBalances();
	}, [user, converted])

	const GlobalStyles = createGlobalStyle`
	.maincheckout {
		background: ${dark === 'true' ? "white" : "#121212"};
		color: ${dark === 'true' ? "#202225" : "white"};
	  }
	  .action {
		fill: ${dark === 'true' ? "#666666" : "white"};
		width: 85px;
		height: 85px;
		margin-top: 10px;
	}
  `;

  const switchCurrencies = (e) => {
	  e.preventDefault()
	  if (inputCurrency === "PLS") {
		setInputCurrency("WPLS")
		setOutputCurrency("PLS")
	  } else if (inputCurrency === "WPLS") {
		setInputCurrency("PLS")
		setOutputCurrency("WPLS")	  
	  }
  }

  const changeOutput = (e) => {
	  let value = e.target.value;
	  document.getElementById("outputCurrency").value = value;
  }

  const convert = async () => {
	  const web3 = window.web3;
	  const weth = new web3.eth.Contract(WETH9.abi, WETH9.networks["941"].address)
	  if (inputCurrency === "PLS") {
		let inputValue = document.getElementById("inputCurrency").value;
		if (inputValue) {
		  await weth.methods.deposit().send({from: user.get('ethAddress'), value: web3.utils.toWei(inputValue)})
		}
	  } else if (inputCurrency === "WPLS") {
		let inputValue = document.getElementById("inputCurrency").value;
		if (inputValue) {
		  await weth.methods.withdraw(web3.utils.toWei(inputValue)).send({from: user.get('ethAddress')})
		}		  
	  }
	  setConverted(true);


  }
    return (
		<div id='landing' className='d-flex align-items-center justify-content-center'>
					<GlobalStyles/>
		<div className='box p-3'>
			<div className='white-box mt-3 p-3'>
				<div className='align-items-center'>
					<p className='from'>Convert from</p>
					<div style={{display: "flex"}}>
						<input
							id="inputCurrency"
							onChange={changeOutput}
							className="form-control" 
							placeholder={`Input Amount (${inputCurrency})`}
							style={{marginBottom: "5px"}}
						/>
					</div>
				</div>

				<div className='d-flex align-items-center justify-content-between' style={{marginTop: "0px"}}>
					<small>Balance: {inputCurrency === "WPLS" ? (wethBalance ? wethBalance/10**18 : "Loading") : (ethBalance ? ethBalance/10**18 : "Loading")} {inputCurrency}</small>
				</div>
			</div>
			<div onClick={switchCurrencies} className='d-flex align-items-center justify-content-center'>
				<button onClick={switchCurrencies} style={{background: "none", border: "none"}}>
					<img src='../img/arrow.png' alt='logo' width='55px' />
				</button>
			</div>

			<div className='white-box p-3'>
				<div className='align-items-center'>
				<p className='from'>Convert to</p>
					<div style={{display: "flex"}}>
						<input
							id="outputCurrency"
							className="form-control" 
							placeholder={`Output Amount (${outputCurrency})`}
							style={{marginBottom: "5px"}}
						/>
					</div>
				</div>

				<div className='d-flex align-items-end justify-content-between'>
					<small>Balance: {outputCurrency === "WPLS" ? (wethBalance ? wethBalance/10**18 : "Loading") : (ethBalance ? ethBalance/10**18 : "Loading")} {outputCurrency}</small>
				</div>
				<div className="spacer-20"></div>
				<div className="spacer-10"></div>
				<p>Rate: 1 PLS = 1 WPLS</p>
				<p>Platform Fees: 0</p>
			</div>

			<div className='d-flex align-items-center justify-content-center'>
				<button onClick={convert} className='btn-main' style={{marginTop: "0px"}}>
					Convert
				</button>
			</div>
		</div>
	</div>

    );
};

export default memo(SwapModal);