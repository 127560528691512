import React, { memo } from "react";
import { useMoralisCloudFunction } from "react-moralis";
import DiamondNFTToken from "../../contracts/DiamondNFTToken.json";

const UserCollection = (props) => {
  const { data } = useMoralisCloudFunction("queryCollection",
	  {"address": props.address}
  );

	console.log(props.address)
  console.log(DiamondNFTToken.networks["941"].address)
	console.log(data)
  if (props.address !== DiamondNFTToken.networks["941"].address) {
    return (
		  <><img className="lazy img-fluid" src={props.address !== "..." && (data && data[0] && data[0].get('profilePhotoBanner') ? data[0].get('profilePhotoBanner')._url : null)} alt=""/><br/></>
    );
  } else {
    return (
		  <><img style={{"max-height": "65px", "margin": "0 auto"}} className="lazy img-fluid" src={`${window.location.origin}/img/logo.jpg`} alt=""/><br/></>
    );    
  }


};

export default memo(UserCollection);