import React, { memo, useCallback } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { status } from './constants/filters';
import { filterNftTitle } from '../../store/actions';


const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const saleType = urlParams.get('saleType')

const parseSaleType = (saleType) => {
    if (saleType === "buy") {
        return "Buy Now"
    } else if (saleType === "reserve") {
        return "Reserve Auction"
    } else if (saleType === "offers") {
        return "Offers Only"
    } else if (saleType === "stepped") {
        return "Stepped Price"
    }
}

const TopFilterBar = (props) => {
    
    const dispatch = useDispatch();

    const handleCollection = (e) => {
        e.preventDefault();
        let url = new URL(window.location.href);
        url.searchParams.set('coll', document.getElementById("name_1").value);

        window.location.replace(url)

    }

    const handleUser = (e) => {
        e.preventDefault();
        let url = new URL(window.location.href);
        url.searchParams.set('user', document.getElementById("name_2").value);

        window.location.replace(url)

    }
    
    const handleStatus = useCallback((option) => {
        const { value } = option;
        let url = new URL(window.location.href);
        url.searchParams.set('saleType', value);

        window.location.replace(url)
    }, [dispatch]); /* eslint-disable-line */
    
    const filterNftTitles = useCallback((event) => {
        const value = event.target.value;
        dispatch(filterNftTitle(value));
    }, [dispatch]); /* eslint-disable-line */

    const customStyles = {
        
        option: (base, state) => ({
            ...base,
            borderRadius: state.isFocused ? "0" : 0,
        }),
        menu: base => ({
            ...base,
            borderRadius: 0,
            marginTop: 0
        }),
        menuList: base => ({
            ...base,
            padding: 0
        }),
        control: (base, state) => ({
            ...base,
            padding: 2,
        })
    };

    return (
        <div className="items_filter">
            <form className="row form-dark" id="form_quick_search" name="form_quick_search">
                <div className="col">
                    <input 
                        className="form-control" 
                        id="name_1" 
                        name="coll" 
                        placeholder="search collection address..." 
                        type="text"
                        onChange={filterNftTitles}
                        defaultValue={props.contractAddress}
                    /> 
                    <button id="btn-submit" onClick={handleCollection} style={{background: "none"}}>
                        <i className="fa fa-search bg-color-secondary"></i>
                    </button>
                    <div className="clearfix"></div>
                </div>
            </form>
            <form className="row form-dark" id="form_quick_search" name="form_quick_search">
                <div className="col">
                    <input 
                        className="form-control" 
                        id="name_2" 
                        name="coll" 
                        placeholder="search user address..." 
                        type="text"
                        onChange={filterNftTitles}
                        defaultValue={props.userAddress}
                    /> 
                    <button id="btn-submit" onClick={handleUser} style={{background: "none"}}>
                        <i className="fa fa-search bg-color-secondary"></i>
                    </button>
                    <div className="clearfix"></div>
                </div>
            </form>
            {/* <div className='dropdownSelect two'>
                <Select 
                    styles={customStyles} 
                    options={status}
                    onChange={handleStatus}
                    placeholder="Sale Type"
                    value={saleType && { label: parseSaleType(saleType), value: 0 }}
                />
            </div> */}
            {/* <div className='dropdownSelect three'>
                <Select 
                    styles={customStyles}
                    options={itemsType}
                    onChange={handleItemsType}
                    placeholder="Item Type"
                    value={itemType && { label: parseItemType(itemType), value: 0 }}
                />
            </div> */}
            <button className="btn-maininline" onClick={() => window.location.replace("/explore")}>
                        <i className="fa fa-times bg-color-secondary"></i>
                    </button>
        </div>
    );
}

export default memo(TopFilterBar);