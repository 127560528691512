import React from 'react';
// import SliderMainParticleGrey from '../components/SliderMainParticleGrey';
import SliderMain from '../components/SliderMain';
import SliderRewards from '../components/SliderRewards';
import SliderPlay from "../components/SliderPlay";
import SliderMusic from "../components/SliderMusic"
import FeatureBox from '../components/FeatureBox';
import { createGlobalStyle } from 'styled-components';
// import ColumnNewThreeCol from '../components/ColumnNewThreeCol';
// import CarouselCollectionRedux from '../components/CarouselCollectionRedux';
// import Cats from '../components/Cats';
// import Resources from '../components/Resources';
// import AuthorListRedux from '../components/AuthorListRedux';
import Footer from '../components/footer';
// import { createGlobalStyle } from 'styled-components';
// const GlobalStyles = createGlobalStyle`
//   .navbar{
//     background: #fff;
//   }
//   .col-white {
//       color: #0d0c22;
//   }
//   .de_count{
//     h3{
//       font-size: 36px;
//       margin-bottom: 0;
//       span {
//         font-size: 36px;
//       }
//     }
//   }
//   .btn-main.inline.white{
//     background: rgba(131,100,266, .2);
//   }
//   .yelbg{
//     background-color: rgb(255, 249, 225);
//   }
//   .yelbg-2{
//     background-color: rgb(247, 255, 231);
//   }
//   .greybg{
//     background-color: rgb(246, 245, 251);
//   }
//   h2{
//     font-size: 30px;
//     margin-bottom: 20px;
//     margin-top:0;
//   }
//   .pb60 {
//     padding-bottom: 60px;
//     padding-top: 60px;
//   }
//   @media only screen and (max-width: 1199px) { 
//     .breadcumb.h-vh{
//       background-image: none !important;
//     }
//   }
// `;
const home= (props) => {

  const dark = props.theme;
  const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #fff;
  }
  .mr40{
    margin-right: 40px;
  }
  .mr15{
    margin-right: 15px;
  }
  .btn2{
    background: #f6f6f6;
    color: #EA14BD !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #111;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
  }
  h1, h2, h4, h3, h5, h6, .h1_big, .h1, .h2, .h3, .h4, .h5, .h6, p, a, input {
    color: ${dark === 'true' ? "#121212" : "white"};
  }
  .form-control, textarea, .dropbtn {
    color: ${dark === 'true' ? "#202225" : "white"};
    background: ${dark === 'true' ? "white" : "#202225"};
  }
  .dropdown-content {
    color: black;
    background: #F1F1F1;
  }
  .maincheckout {
		background: ${dark === 'true' ? "white" : "#121212"};
		color: ${dark === 'true' ? "#121212" : "white"};
	}
  .nft__item {
    border: ${dark === 'true' ? "solid 1px #ddd" : "solid 1px grey"};
}
  `;
  return (
  <div>
        <GlobalStyles/>       
    

{/*style={{backgroundImage: `url(${'./img/bg-shape-1.jpg'})`}}*/ }



      <section className="jumbotron breadcumb no-bg h-vh" style={{paddingTop: "150px", paddingBottom: "0px"}}>
         <SliderMain theme={dark}/>
      </section>


      <section className="jumbotron breadcumb no-bg h-vh" style={{paddingTop: "100px", paddingBottom: "0px"}}>
         <SliderRewards theme={dark}/>
      </section>

      <section className="jumbotron breadcumb no-bg h-vh" style={{paddingTop: "100px", paddingBottom: "0px"}}>
         <SliderPlay theme={dark}/>
      </section>

      <section className='container' style={{paddingTop: "100px", paddingBottom: "0px"}}>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-center'>
              <h2>Protocol Overview</h2>
              <div className="small-border" style={{marginBottom: "25px"}}></div>
              <p className="centered mb-4" style={{width: "75%"}}>Our NFT marketplace will be the central place for all NFT trades and interactions. You will be able to trade and interact with any NFTs that you hold on PulseChain (including NFTs forked from Ethereum). This includes ERC721 and ERC1155 token standards. Our marketplace will also have additional features that support the current utility of NFTs and the future use cases we have planned for our platform.</p>
            </div>
          </div>
          <div className='col-lg-12'>
          <FeatureBox/>
        </div>
        </div>
      </section>
      

     {/* <section className='container no-top no-bottom'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-center'>
              <h2>Notable Drops</h2>
              <div className="small-border"></div>
            </div>
          </div>
          <div className='col-lg-12'>
            <ColumnNewThreeCol/>
          </div>
        </div>
      </section> */}

      {/* <section className='container no-bottom'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-center'>
              <h2>Top Collections This Week</h2>
              <div className="small-border"></div>
            </div>
          </div>
          <div className='col-lg-12'>
            <AuthorListRedux/>
          </div>
        </div>
      </section> */}


      {/* <section className='container no-bottom'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-center'>
              <h2>Trending in all categories</h2>
              <div className="small-border"></div>
            </div>
          </div>
          <div className='col-lg-12'>
            <CarouselCollectionRedux/>
          </div>
        </div>
      </section> */}



      {/* <section className='container no-bottom'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-center'>
              <h2>Resources for getting started</h2>
              <div className="small-border"></div>
            </div>
          </div>
          <div className='col-lg-12'>
            <Resources/>
          </div>
        </div>
      </section> */}

      {/* <section className='container no-bottom'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-center'>
              <h2>Browse by category</h2>
              <div className="small-border"></div>
            </div>
          </div>
          <div className='col-lg-12'>
            <Cats/>
          </div>
        </div>
      </section> */}

      <section className='container no-bottom'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-center'>
              <h2>Meet PulseVerse</h2>
              <p>The NFT marketplace that provides a home for all your NFTs</p>
              <div className="small-border"></div>
            </div>
          </div>
          <iframe id="meet" style={{marginBottom: "100px"}} width="50%" height="650" src="https://www.youtube.com/embed/gQpmcAE8rrE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </section>

    <Footer theme={dark}/>

  </div>);
};
export default home;