import React, { memo, useEffect, useState} from 'react';
import styled from "styled-components";
import Clock from "./Clock";
import { navigate } from '@reach/router';
import UserImage from '../components/UserImage'
import NftImage from '../components/NftImage'
import { useMoralisCloudFunction, useMoralisQuery } from "react-moralis";
import { Link } from '@reach/router';
import tokensBid from "../../tokensBid.json";

import ERC721 from "../../contracts/ERC721.json";
import ERC1155 from "../../contracts/CustomCollectionERC1155.json";
import IERC165 from "../../contracts/IERC165.json";
import CurrencyManager from "../../contracts/CurrencyManager.json"
import SharedStorefront from "../../contracts/SharedStorefront.json";
import PulseVerseExchange from "../../contracts/PulseVerseExchange.json";
import TransferManagerERC721 from "../../contracts/TransferManagerERC721.json";
import TransferManagerERC1155 from "../../contracts/TransferManagerERC1155.json";
import StrategyStandardSaleForFixedPrice from "../../contracts/StrategyStandardSaleForFixedPrice.json";
import WETH9 from "../../contracts/WETH9.json";

import IPFSGatewayTools from '@pinata/ipfs-gateway-tools/dist/browser';
import { createGlobalStyle } from 'styled-components';
import tokens from "../../tokens.json";

const ipfsGatewayTools = new IPFSGatewayTools();
const desiredGatewayPrefix = 'https://rarible.mypinata.cloud'
const INTERFACE_ID_ERC721 = "0x80ac58cd";
const INTERFACE_ID_ERC1155 = "0xd9b67a26";

const tokenToImage = {
  "0x7e8c767e460b51fd34ec2ce89d8c4b06bca525a3": "../img/pulsechain-logo.png",
  "0x2b591e99afe9f32eaa6214f7b7629768c40eeb39": "../img/hex-logo.png"
}

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;


//react functional component
const NftCard = ({ nftPass, className = 'd-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4', clockTop = true, height, onImgLoad, theme }) => {

  const dark = theme;
  const GlobalStyles = createGlobalStyle`
    h1, h2, h4, h3, h5, h6, .h1_big, .h1, .h2, .h3, .h4, .h5, .h6, p {
      color: ${dark === 'true' ? "#202225" : "white"}
    }
    span {
      color: ${dark === 'true' ? "#666666" : "white"}
    }

    #collection {
      color: ${dark === 'true' ? "#666666" : "#878D96"} !important;
    }
    
    .nft__item {
      border: ${dark === 'true' ? "solid 1px #ddd" : "solid 1px #4D5358"};
    }

    
`;

  const isNonceCancelled = async (user, nonce) => {
    const web3 = window.web3;
    let exchange = new web3.eth.Contract(PulseVerseExchange.abi, PulseVerseExchange.networks["941"].address)
    let cancelled = await exchange.methods.isUserOrderNonceExecutedOrCancelled(user, nonce).call();
    console.log(cancelled)
    return cancelled;
  }

  const isOrderValid = (order) => {
    let now = Date.now()
    return (now / 1000) < parseInt(order.get('endTime'));

  }


    const { data: dataUnlock } = useMoralisCloudFunction("queryUnlockData",
        {"tokenId": nftPass && nftPass.get('tokenId'), "contractAddress": nftPass && nftPass.get('collection')},
    );

    const { data: makerBidDataByPriceNotClean, isLoading: makerBidByPriceIsLoading } = useMoralisCloudFunction("queryMakerBidItemByPrice",
      {"tokenId": nftPass && nftPass.get('tokenId'), "contractAddress": nftPass && nftPass.get('collection')}
    );

    const [nftData, setNftData] = useState({});
    const [nftLoading, setNftIsLoading] = useState(true)
    const [nonceCancelled, isCancelled] = useState(null)
    const [valid, isValid] = useState(null);
    const [makerBidDataByPrice, setMakerBidDataByPrice] = React.useState(null);




    useEffect(() => {

  
        (async () => {

          const web3 = window.web3

          let makerBidDataCleanByPrice = []
          if (makerBidDataByPriceNotClean) {
            for (let i = 0; i < makerBidDataByPriceNotClean.length; i++) {
              let nonceCancelled = await isNonceCancelled(makerBidDataByPriceNotClean[i].get("signer"), makerBidDataByPriceNotClean[i].get("nonce"))
              let orderValid = isOrderValid(makerBidDataByPriceNotClean[i]);
                if (!nonceCancelled && orderValid) {
                  makerBidDataCleanByPrice.push(makerBidDataByPriceNotClean[i])
                }
      
            }
            setMakerBidDataByPrice([...makerBidDataCleanByPrice]);
          }
      
          console.log(makerBidDataCleanByPrice)    

          isNonceCancelled(nftPass.get('signer'), nftPass.get('nonce')).then((cancelled) => {
            isCancelled(cancelled);
          });

          let val = isOrderValid(nftPass);
          isValid(val)

            let listingType = nftPass.get('listing')

            let nftInfo = {}
            let editionId = nftPass.get('collection');
            let contractAddress = nftPass.get('contractAddress');


            let contract = new web3.eth.Contract(IERC165.abi, nftPass.get('collection'));
            let isERC721 = await contract.methods.supportsInterface(INTERFACE_ID_ERC721).call();
            let isERC1155 = await contract.methods.supportsInterface(INTERFACE_ID_ERC1155).call();

            if (isERC721) {
              let digitalAsset = new web3.eth.Contract(ERC721.abi, nftPass.get('collection'));
              let name = await digitalAsset.methods.name().call();
              let uri = await digitalAsset.methods.tokenURI(nftPass.get('tokenId')).call();
              let owner = await digitalAsset.methods.ownerOf(nftPass.get('tokenId')).call();
              let finalURI;
              try {
                  finalURI = `https://thawing-peak-27665.herokuapp.com/${ipfsGatewayTools.convertToDesiredGateway(uri, desiredGatewayPrefix)}`;
              } catch (error) {
                  if (!uri.startsWith("data:application")) {
                    finalURI = `https://thawing-peak-27665.herokuapp.com/${uri}`;
                  } else {
                    finalURI = uri
                  }
              }
              await fetch(finalURI
                ,{
                  headers : { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                   }
                }
                )
                  .then(function(response){
                    console.log(response)
                    return response.json();
                  })
                  .then(async function(myJson) {
                    console.log(myJson)
                    if (myJson["name"]) {
                      nftInfo["title"] = myJson["name"]
                    } else {
                      nftInfo["title"] = `${name} #${editionId}`
                    }
      
                    let imagePreview;
                    if (myJson["image_preview"] !== "") {
      
                      try {
                          imagePreview = ipfsGatewayTools.convertToDesiredGateway(myJson["image_preview"], desiredGatewayPrefix);
                      } catch (error) {
                          imagePreview = myJson["image_preview"];
                      }
                    }
      
                    nftInfo["editions"] = 1
                    let image;
                    if (myJson["image"]) {
                      try {
                          image = ipfsGatewayTools.convertToDesiredGateway(myJson["image"], desiredGatewayPrefix);
                      } catch (error) {
                          image = myJson["image"];
                      }
                    } else if (myJson["image_url"]) {
                        try {
                            image = ipfsGatewayTools.convertToDesiredGateway(myJson["image_url"], desiredGatewayPrefix);
                        } catch (error) {
                            image = myJson["image_url"];
                        }  
                    }
                    if (listingType === "reserve") {
                      nftInfo["status"] = 'on_auction' 
                    } else if (listingType === "buy") {
                      nftInfo["status"] = 'buy'
                    }
      
                    nftInfo["previewImg"] = image
                    nftInfo["collection"] = name

      
                    if (imagePreview) {
                      nftInfo["preview"] = `https://rarible.mypinata.cloud/ipfs/${imagePreview._name.split(".")[0]}`;
                    }
                    nftInfo["author"] = owner;
                    nftInfo["authorLink"] = `/user/${owner}`
                    nftInfo["nftLink"] = `/token/${nftPass.get('contractAddress')}:${nftPass.get('tokenId')}`
      
                    if (listingType === "buy" && editionId) {
  
                      nftInfo["price"] = nftPass.get('price') / 10 ** tokens[nftPass.get('currency').toLowerCase()][1]
                      nftInfo["token"] = tokens[nftPass.get('currency').toLowerCase()][0]
                    } else if (listingType === "reserve" && editionId) {

                    }
    
                  })   

            } else if (isERC1155) {
              let digitalAsset = new web3.eth.Contract(ERC1155.abi, nftPass.get('collection'));
              let name = await digitalAsset.methods.name().call();
              let uri = await digitalAsset.methods.uri(nftPass.get('tokenId')).call();
              let totalSupply = await digitalAsset.methods.totalSupply(nftPass.get('tokenId')).call()

              let finalURI;
              try {
                  finalURI = `https://thawing-peak-27665.herokuapp.com/${ipfsGatewayTools.convertToDesiredGateway(uri, desiredGatewayPrefix)}`;
              } catch (error) {
                  if (!uri.startsWith("data:application")) {
                    finalURI = `https://thawing-peak-27665.herokuapp.com/${uri}`;
                  } else {
                    finalURI = uri
                  }
              }
              await fetch(finalURI
                ,{
                  headers : { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                   }
                }
                )
                  .then(function(response){
                    console.log(response)
                    return response.json();
                  })
                  .then(async function(myJson) {
                    console.log(myJson)
                    if (myJson["name"]) {
                      nftInfo["title"] = myJson["name"]
                    } else {
                      nftInfo["title"] = `${name} #${editionId}`
                    }
      
                    let imagePreview;
                    if (myJson["image_preview"] !== "") {
      
                      try {
                          imagePreview = ipfsGatewayTools.convertToDesiredGateway(myJson["image_preview"], desiredGatewayPrefix);
                      } catch (error) {
                          imagePreview = myJson["image_preview"];
                      }
                    }
      
                    nftInfo["editions"] = totalSupply

                    let image;
                    if (myJson["image"]) {
                      try {
                          image = ipfsGatewayTools.convertToDesiredGateway(myJson["image"], desiredGatewayPrefix);
                      } catch (error) {
                          image = myJson["image"];
                      }
                    } else if (myJson["image_url"]) {
                        try {
                            image = ipfsGatewayTools.convertToDesiredGateway(myJson["image_url"], desiredGatewayPrefix);
                        } catch (error) {
                            image = myJson["image_url"];
                        }  
                    }
                    if (listingType === "reserve") {
                      nftInfo["status"] = 'on_auction' 
                    } else if (listingType === "buy") {
                      nftInfo["status"] = 'buy'
                    }
      
                    nftInfo["previewImg"] = image
                    nftInfo["collection"] = name

      
                    if (imagePreview) {
                      nftInfo["preview"] = `https://rarible.mypinata.cloud/ipfs/${imagePreview._name.split(".")[0]}`;
                    }
                    nftInfo["author"] = "0x178a2EA63b2BFF871034418400D2F4fE13bb41d4";
                    nftInfo["authorLink"] = `/user/${"0x178a2EA63b2BFF871034418400D2F4fE13bb41d4"}`
                    nftInfo["nftLink"] = `/token/${nftPass.get('collection')}:${nftPass.get('tokenId')}`
      
                    if (listingType === "buy" && editionId) {
                      nftInfo["price"] = nftPass.get('price') / 10 ** tokens[nftPass.get('currency').toLowerCase()][1]
                      nftInfo["token"] = tokens[nftPass.get('currency').toLowerCase()][0]
                    } else if (listingType === "reserve" && editionId) {

                    }
    
                  })      
            }
            console.log(nftInfo)
            setNftData(nftInfo)
            setNftIsLoading(false)


        })()

    }, [nftPass, makerBidDataByPriceNotClean])

    const navigateTo = (link) => {
        navigate(link);
    }
    // console.log(nft)

    if (!nftLoading && nonceCancelled !== null && !nonceCancelled && valid) {
        return (

            <div className={className}>
                <GlobalStyles/>
                <Link to={nftData.nftLink}>
                <div className="nft__item m-0">
                    { nftData.deadline && clockTop &&
                        <div className="de_countdown">
                            <Clock deadline={nftData.deadline} />
                        </div>
                    }
                    <div className="nft__item_wrap" style={{height: `150px`}}>
                    <Outer>
                        {<NftImage previewImg={nftData.previewImg} preview={nftData.preview}/>}
                    </Outer>
                    </div>
                    { nftData.deadline && !clockTop &&
                        <div className="de_countdown">
                            <Clock deadline={nftData.deadline} />
                        </div>
                    }
                    {(nftData.hasAdditionalMetadata || (dataUnlock && dataUnlock[0])) && (<div style={
                                {
                                    padding: "15px", 
                                    backgroundColor: "#F6F6F6", 
                                    borderRadius: "25px", 
                                    border: "1px solid black",
                                    height: "55px",
                                    width: "100%",
                                    overflow: "scroll",
                                    fontSize: "15px"
                                }}><p><i className="fa fa- fa-unlock-alt id-color-2 mr10" style={{marginRight: "5px", color: "black"}}></i><span style={{"font-weight": "bold", "color": "black"}}>Includes unlockable content</span></p></div>)}
                    <div className="nft__item_info">
                      <small id="collection">
                        {nftData.collection}
                      </small>
                    <Link to={nftData.nftLink}>
                          <h4>{nftData.title}</h4>
                        </Link>
                        { nftData.status === 'has_offers' ? (
                                <div className="has_offers">
                                    <span className='through'>{nftData.priceover}</span> {nftData.price}
                                </div> 
                            ) : (
                                !nftData.soldOut ? (<span style={{marginLeft: "0px"}}><div className="nft__item_price">
                                    <img width="25" style={{width: "25px"}} src={`${tokenToImage[nftPass.get('currency').toLowerCase()]}`} alt=""></img>&nbsp;{nftData.price ? `${nftData.price} ${nftData.token}` : null}
                                    { nftData.status === 'on_auction' && 
                                        (nftData.bid > 0 ? <><div>Best Offer: {nftData.bid} {nftData.token}</div></> : <><div>No offers yet.</div></>)
                                    }
                                </div></span>) : <></>
                            )
                        }
                        {/* {!nftData.soldOut ? <div className="nft__item_action">
                            <span onClick={() => navigateTo(nftData.bidLink)}>{ nftData.status ? (nftData.status === 'on_auction' ? 'Place a bid' : 'Buy Now') : 'View Item'}</span>
                        </div> : <div className="nft__item_action">
                            <span onClick={() => navigateTo(nftData.bidLink)}>{ 'SOLD OUT' }</span>
                        </div>} */}
                        <hr style={{margin: "10px 0px 25px 0px"}} />
                        <div className="nft__item_offer">
                          <span>{makerBidDataByPrice && (makerBidDataByPrice.length === 0 ? "No offers yet." : <span><span id="collection">Top Offer</span> <img width="25" style={{width: "25px"}} src={`${tokenToImage[makerBidDataByPrice[0].get("currency").toLowerCase()]}`} alt=""></img>&nbsp;<span></span><span style={{"font-weight": "bold"}}>{makerBidDataByPrice[0].get("price")/10**tokensBid[makerBidDataByPrice[0].get("currency").toLowerCase()][1]} {tokensBid[makerBidDataByPrice[0].get("currency").toLowerCase()][0]}</span></span>)}</span>
                          
                        </div>     
                        <div className="nft__item_like">
                            <i className="fa fa-heart"></i><span>{nftData.likes}</span>
                        </div>     
 
                    </div> 

                                
                </div>
                </Link>
            </div>    
        );
    } else if (nonceCancelled !== null && !nonceCancelled && valid) {
        return (
            <div className={className}>
                                <div className="nft__item m-0">
                                <img src="./img/loader.gif" className="image-center" alt=""/>
                                </div>
            </div>
        )
    } else {
      return null;
    }
 
 
};

export default memo(NftCard);