import React, { memo, useState } from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import PreviewCard from './PreviewCard';
import { useMoralis } from "react-moralis";
import { Link } from '@reach/router';
import Typewriter from 'typewriter-effect';
import { createGlobalStyle } from 'styled-components';
import { SocialIcon } from 'react-social-icons';

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const nft = {
  "category": "virtual_world",
  "status": "buy_now",
  "item_type": "single_items",
  "collections": "cartoonism",
  "deadline":"",
  "authorLink": "/#",
  "nftLink": "/#",
  "bidLink": "/#",
  "previewImg": "./img/bored.png",
  "title": "BoredApeYachtClub #6663",
  "price": "0.06 PLS",
  "bid": "1/22",
  "likes": 80,
  "authourName": "0x42d69F67a14753824fF1AE387a98cc0d490D0474"
};




const SliderMain = (props) => {
  const { isAuthenticated } = useMoralis()
  const [height, setHeight] = useState(0);
  const [count, setCount] = useState(1);

  const GlobalStyles = createGlobalStyle`
  .Typewriter__wrapper {
    color: #f94df7;
  }
  `;
  
  const onImgLoad = ({target:img}) => {
    let currentHeight = height;
    if(currentHeight < img.offsetHeight) {
        setHeight(img.offsetHeight);
    }
  };

  React.useEffect(() => {
    setCount(1);
  }, [count])
  
  return (
    <div className="container slider">
      <GlobalStyles/>
    <div className="row align-items-center">

          <div className="col-md-6">
          <SocialIcon url="https://twitter.com/PulseVerseio" target="_blank" />
          <SocialIcon url="https://t.me/PulseVerse" style={{marginLeft:"20px"}} network="telegram" target="_blank" />
          <SocialIcon url="https://discord.gg/veUkyeVqPB" style={{marginLeft:"20px"}} target="_blank"  />
              <div className="spacer-single"></div>
              <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
              <h6 className=""><span style={{color: "#EA14BD"}}>PULSEVERSE</span></h6>
              </Reveal>
              <div className="spacer-10"></div>
              <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600} triggerOnce>

              <h1 className="">Unlock the power to <Typewriter
                options={{
                  strings: ['create NFTs...', 'stake NFTs...', 'trade NFTs...', 'swap NFTs...', 'fractionalize NFTs...'],
                  autoStart: true,
                  loop: true,
                  skipAddStyles: true
                }}         
              />
              </h1>
              </Reveal>
              <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={600} triggerOnce>
              <p className=" lead" style={{marginBottom: "0px"}}>
              on the world’s first decentralized NFT ecosystem on PulseChain.
              </p>
              <br/>
              </Reveal>
              {/* <div className="spacer-10"></div> */}
              <Reveal className='onStep' keyframes={fadeInUp} delay={800} duration={900} triggerOnce>

              <div className="mb-sm-30 flex">
              <Link to={`/#`} className="btn-main inline lead">
              Explore
              </Link>
              {/* {isAuthenticated ?
                            <Link to={`/#`} className="btn-main lead inline white" style={{backgroundColor: "#E5DFFE"}}>
              Create
              </Link> : <Link to={`/#`} className="btn-main lead inline white" style={{backgroundColor: "#E5DFFE"}}>
              Create
              </Link>} */}
              <button className="btn-main lead inline white" style={{backgroundColor: "#E5DFFE"}} onClick={() => {window.open('https://t.me/PulseVerse/5183', '_blank')}}>Read Whitepaper</button>
              </div>

              <div className="mb-sm-30"></div>
              </Reveal>
          </div>
          <div id="banner" style={{marginTop: "50px"}} className="col-md-6 xs-hide">
            <Reveal className='onStep' keyframes={fadeIn} delay={900} duration={1500} triggerOnce>
              <PreviewCard nft={nft} key={0} onImgLoad={onImgLoad} height={325} theme={props.theme}/>
            </Reveal>
          </div>
      </div>
    </div>
  );

};
export default memo(SliderMain);



