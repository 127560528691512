import React, { memo } from "react";
import { createGlobalStyle } from 'styled-components';

const Steps = (props) => {
	const dark = localStorage.getItem('darkMode');
	const GlobalStyles = createGlobalStyle`
	.maincheckout {
		background: ${dark === 'true' ? "white" : "#202225"};
		// color: ${dark === 'true' ? "#202225" : "white"};
	  }
	  h1, h2, h4, h3, h5, h6, .h1_big, .h1, .h2, .h3, .h4, .h5, .h6, span, p, a, input {
		color: ${dark === 'true' ? "#121212" : "white"}
	  }
	`;

    return (
		<div>
			<GlobalStyles/>
				<div className='maincheckout' id="wrongNet">
					<div className='detailcheckout'>
						<div className='listcheckout'>
							<div className='thumb'>
							<img src={`${window.location.origin}/img/cross.png`} className="img-fluid img-rounded mb-sm-30" alt=""/>
							</div>
							<div className='description'>
								<h3>Wrong Network</h3>
								<p>Please switch to the PulseChain Testnet in your wallet!</p>
							</div>
						</div>
						<button className="btn-main" onClick={() => props.signOut()}>Sign Out</button>
					</div>
				</div>
		</div>
    );
};

export default memo(Steps);