import React, { memo } from "react";
import { useMoralisCloudFunction } from "react-moralis";
import DiamondNFTToken from "../../contracts/DiamondNFTToken.json";

const UserCollection = (props) => {
  const { data } = useMoralisCloudFunction("queryCollection",
	  {"address": props.address}
  );

	console.log(props.address)
  console.log(DiamondNFTToken.networks["941"].address)
	console.log(data)
  if (!(props.address === DiamondNFTToken.networks["941"].address)) {
    return (
		  <span>{data && data[0] && (data[0].get('description') ? data[0].get('description') : null)}</span>
    );
  } else {
    return (
		  <span>Universal collection for PulseVerse</span>
    );  
  }

};

export default memo(UserCollection);