import React, { useState } from "react";
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import { useNewMoralisObject, useMoralis, useMoralisCloudFunction, useMoralisFile } from "react-moralis";
import WrongNetwork from "../components/WrongNetwork";
import { Link } from '@reach/router';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const contractAddress = urlParams.get('contract_address')

const Header = (props) => {

  const dark = props.theme;
  const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #fff;
  }
  .mr40{
    margin-right: 40px;
  }
  .mr15{
    margin-right: 15px;
  }
  .btn2{
    background: #f6f6f6;
    color: #EA14BD !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #111;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
  }
  h1, h2, h4, h3, h5, h6, .h1_big, .h1, .h2, .h3, .h4, .h5, .h6, p, a, input {
    color: ${dark === 'true' ? "#121212" : "white"} !important;
  }
  .form-control, textarea, .dropbtn {
    color: ${dark === 'true' ? "#202225" : "white"};
    background: ${dark === 'true' ? "white" : "#202225"};
  }
  .dropdown-content {
    color: black;
    background: #F1F1F1;
  }
  .maincheckout {
		background: ${dark === 'true' ? "white" : "#121212"};
		color: ${dark === 'true' ? "#121212" : "white"};
	}
  .nft__item {
    border: ${dark === 'true' ? "solid 1px #ddd" : "solid 1px grey"};
}
  `;

  const { save } = useNewMoralisObject('PublicUser');
	const { isAuthenticated, user, logout } = useMoralis()

  const [file, setFile] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [fileType, setFileType] = useState([]);

  const [fileBanner, setFileBanner] = useState([]);
  const [fileNameBanner, setFileNameBanner] = useState([]);
  const [fileTypeBanner, setFileTypeBanner] = useState([]);
  const [listener, setListener] = React.useState(false);

  const [saveState, setSaveState] = useState("Save Profile Data");

  const {
    saveFile
  } = useMoralisFile();

  const signOut = () => {
    logout()
    if (document.getElementById("wrongnet")) {
      document.getElementById("wrongnet").style.display = "none";
    }
  }

  const [wrongNetwork, setWrongNetwork] = useState(false);

  if (window.ethereum && !listener) {
    window.ethereum.on('chainChanged', function(networkId){
      if (networkId !== "0x3ac") {
        setWrongNetwork(true);
      } else {
        setWrongNetwork(false);      
      }
    })
    setListener(true);
  }


  const { fetch: userFetch, data } = useMoralisCloudFunction("queryCollection",
    {"address": contractAddress}
  );

  console.log(data && data[0] && data[0].get('ethAddress'))
  console.log(user && user.get('ethAddress'))

  let dataJson = JSON.parse(JSON.stringify(data, null, 2));

  let profilePhoto;
  let profilePhotoBanner;
  if (dataJson && data.length > 0) {
    document.getElementsByName("bio")[0].value = dataJson[0]["description"];
    profilePhoto = dataJson[0]["profilePhoto"];
    profilePhotoBanner = dataJson[0]["profilePhotoBanner"];
  }

  const onChange = (e) => {
    document.getElementById("file_name").style.display = "none";

    const fileTarget = e.target.files[0]
    // var image = document.getElementById('output');
    // image.src = URL.createObjectURL(file);
    var image = document.getElementById('output');
    console.log(URL.createObjectURL(fileTarget));
    image.src = URL.createObjectURL(fileTarget);
    const fileName = fileTarget.name
    const fileExtension = fileTarget.type
    setFileName([fileName])
    setFileType([fileExtension])
    setFile([fileTarget])
  }

  const onChangeBanner = (e) => {
    document.getElementById("file_nameBanner").style.display = "none";

    const fileTarget = e.target.files[0]
    // var image = document.getElementById('output');
    // image.src = URL.createObjectURL(file);
    var image = document.getElementById('outputBanner');
    console.log(URL.createObjectURL(fileTarget));
    image.src = URL.createObjectURL(fileTarget);
    const fileName = fileTarget.name
    const fileExtension = fileTarget.type
    setFileNameBanner([fileName])
    setFileTypeBanner([fileExtension])
    setFileBanner([fileTarget])
  }

  const saveData = async () => {
    setSaveState("Saving...");
    let bio = document.getElementsByName("bio")[0].value;

    try {
      if (data.length > 0) {
        let ethAddress = user.get('ethAddress');
        data[0].save('ethAddress', ethAddress);
        data[0].save('description', bio);
  
        if (file[0]) {
          console.log(fileName[0], file[0])
          let moralisUpload = await saveFile(`${user.get('ethAddress')}.${fileType[0].split('/')[1]}`, file[0])
          console.log(moralisUpload)
          await data[0].save('profilePhoto', moralisUpload)
        }
  
        if (fileBanner[0]) {
          let moralisUpload = await saveFile(`${user.get('ethAddress')}.${fileTypeBanner[0].split('/')[1]}`, fileBanner[0])
          console.log(moralisUpload)
          await data[0].save('profilePhotoBanner', moralisUpload)
        }
  
  
      } else {
        let ethAddress = user.get('ethAddress');
  
        let profilePhoto;
        if (file[0]) {
          profilePhoto = await saveFile(`${user.get('ethAddress')}.${fileType[0].split('/')[1]}`, file[0])
          console.log(profilePhoto)
        }
  
        let profilePhotoBanner;
        if (fileBanner[0]) {
          profilePhotoBanner = await saveFile(`${user.get('ethAddress')}.${fileTypeBanner[0].split('/')[1]}`, fileBanner[0])
          console.log(profilePhotoBanner)
        }
  
  
        await save({ethAddress, bio, profilePhoto, profilePhotoBanner});
        await userFetch();
      }
    } catch (error) {
      console.error(error)
      setSaveState("Error Saving");
    }

    setSaveState("Saved!");
    //code before the pause
    setTimeout(function(){
    setSaveState("Save Profile Data");
    }, 2000);

  }

    return (
      <div>
      <GlobalStyles/>

      <section className='container' style={{"padding-top": "125px"}}>
      <h2 className='text-center'>Edit Collection</h2>
      <Link className="btn-main" style={{margin: "0 auto", marginBottom: "15px"}} to={`/collection/${contractAddress}`}>
        Go to Collection
      </Link>
        <br/>
        <div className="row">
          <div className="col-lg-8 offset-lg-2 mb-5">
              <form id="form-create-item" className="form-border" action="#">
                  <div className="field-set">

                  <h5>Upload collection image</h5>

                  <div className="d-create-file">
                      <p id="file_name">PNG, JPG, GIF, WEBP or MP4. Max 200mb.</p>
                      {file.length > 0 ? <p key="{index}">PNG, JPG, GIF, WEBP or MP4. Max 200mb. <br/>{fileName}</p> : <></>}
                      {profilePhoto ? <p><img src={profilePhoto.url} id="output" width="200" alt=""/></p> : <p><img id="output" width="200" alt=""/></p>}
                      <div className='browse'>
                        <input type="button" id="get_file" className="btn-main" value="Browse"/>
                        <input id='upload_file' type="file" multiple onChange={onChange} />
                      </div>
                      
                  </div>

                  <br/>
                  <h5>Upload collection banner</h5>

                  <div className="d-create-file">
                      <p id="file_nameBanner">PNG, JPG, GIF, WEBP or MP4. Max 200mb.</p>
                      {fileBanner.length > 0 ? <p key="{index}">PNG, JPG, GIF, WEBP or MP4. Max 200mb. <br/>{fileNameBanner}</p> : <></>}
                      {profilePhotoBanner ? <p><img src={profilePhotoBanner.url} id="outputBanner" width="200" alt=""/></p> : <p><img id="outputBanner" width="200" alt=""/></p>}
                      <div className='browse'>
                        <input type="button" id="get_file" className="btn-main" value="Browse"/>
                        <input id='upload_file' type="file" multiple onChange={onChangeBanner} />
                      </div>
                      
                  </div>

                  <div className="spacer-single"></div>

                      {/* <h5>Display Name</h5>
                      <input type="text" name="display_name" id="item_title" className="form-control" placeholder="Enter your display name" />

                      <div className="spacer-10"></div> */}

                      <h5>Description</h5>
                      <textarea data-autoresize name="bio" id="item_desc" className="form-control" placeholder="Tell us about yourself in a few words"></textarea>

                      {/* <div className="spacer-10"></div>

                      <h5>Email</h5>
                      <small>For marketplace notifications (coming soon!) </small>
                      <input type="text" name="email" id="item_price" className="form-control" placeholder="Enter email" /> */}

                      {/* <div className="spacer-10"></div>

                      <h5>Personal Site or Portfolio</h5>
                      <input type="text" name="personal_site" id="item_royalties" className="form-control" placeholder="https://" /> */}

                      <div className="spacer-10"></div>

                      <input type="button" id="submit" className="btn-main" value={saveState} onClick={saveData} disabled={data && data[0] && user.get('ethAddress') !== data[0].get('ethAddress')}/>
                  </div>
              </form>
          </div>
                                 
      </div>

      </section>
        { wrongNetwork && isAuthenticated &&
            <div className='wrongnet'>
                <WrongNetwork signOut={signOut}/>
            </div>
        }

        <Footer />
      </div>
   );
}

export default Header;