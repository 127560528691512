import { React, useState }from 'react';
import ColumnNewListing from '../components/ColumnNewListing';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import TopFilterBar from '../components/TopFilterBar';
import { useMoralisCloudFunction, useMoralis } from "react-moralis";
import WrongNetwork from "../components/WrongNetwork";
import PulseVerseExchange from "../../contracts/PulseVerseExchange.json";
//react functional component

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const contractAddress = urlParams.get('coll');
const userAddress = urlParams.get('user') ? urlParams.get('user').toLowerCase() : urlParams.get('user')
const listing = urlParams.get('saleType')

const Explore = function(props) {



  const dark = props.theme;

  
  const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #fff;
  }
  .mr40{
    margin-right: 40px;
  }
  .mr15{
    margin-right: 15px;
  }
  .btn2{
    background: #f6f6f6;
    color: #EA14BD !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #111;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
  }
  .dropbtn, .dropdownSelect div {
    background: ${dark === 'true' ? "white" : "#202225"};
    color: ${dark === 'true' ? "#202225" : "white"};
  }
  .dropdownSelect div div {
    background: ${dark === 'true' ? "white" : "#202225"};
    color: ${dark === 'true' ? "#202225" : "white"}; 
  }
  `;


	const { isAuthenticated, logout } = useMoralis()
  const [limit, setLimit] = useState(12);
  const [loadingMore, setLoadingMore] = useState(false);

  const web3 = window.web3;
  let errorThrown = false;


  try {
    web3.utils.toChecksumAddress(contractAddress)
  } catch (error) {
    errorThrown = true;
  }


  const { fetch: listingFetch, data: listingData, error: listingError } = useMoralisCloudFunction("queryMakerAskAll", 
    {
      "contractAddress": contractAddress && !errorThrown ? web3.utils.toChecksumAddress(contractAddress) : contractAddress,
      "userAddress": userAddress,
      "listing": listing,
      limit
    }
  );

  

  console.log(listingData)
  console.log(listingError)

  const loadMore = async () => {
    setLimit(limit + 4)
    setLoadingMore(true);
    await listingFetch()
    setLoadingMore(false);
  } 

  const [listener, setListener] = useState(false);

  const signOut = () => {
    logout()
    if (document.getElementById("wrongnet")) {
      document.getElementById("wrongnet").style.display = "none";
    }
  }


  const [wrongNetwork, setWrongNetwork] = useState(false);

  if (window.ethereum && !listener) {
    window.ethereum.on('chainChanged', function(networkId){
      if (networkId !== "0x3ac") {
        setWrongNetwork(true);
      } else {
        setWrongNetwork(false);      
      }
    })
    setListener(true);
  }

// useEffect(() => {
//   (async () => {
//     await populateData();
//   })()
// }, [])




  return (
    <div>
    <GlobalStyles/>


      <section className='container' style={{padding: "125px 0px"}}>
      <h2 className='text-center'>Explore</h2>
      <p className='text-center'>Explore the PulseVerse marketplace!</p>
      <div className="spacer-20"></div>
            <div className='row'  style={{textAlign: "center"}}>

              <div className='col-lg-12' style={{textAlign: "center"}}>
                  <TopFilterBar contractAddress={contractAddress} userAddress={userAddress}/>
              </div>
            </div>
          {<ColumnNewListing loadMore={loadMore} listingData={listingData} loadingMore={loadingMore} theme={dark}/>}
          </section>


      <Footer theme={dark}/>
      { wrongNetwork && isAuthenticated &&
          <div className='wrongnet'>
              <WrongNetwork signOut={signOut}/>
          </div>
      }
    </div>
  );





};

export default Explore;