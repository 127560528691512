import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import UserCollection from "../components/UserCollection"
import CollectionDesc from "../components/CollectionDesc"
import CollectionBanner from "../components/CollectionBanner"
import { Link } from '@reach/router';

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return (
      <div {...props}></div>
    );
  }
}

export default class Responsive extends Component {
  dark = localStorage.getItem('darkMode');
  render() {
    var settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
      ]
    };
    return (
        <div className='nft'>
          <Slider {...settings}>
            {this.props.collections.map((value, index) => {
              return(<CustomSlide className='itm' key={index} index={index}>
              <div className="nft_coll">
                  <div className="nft_wrap">
                      <span style={localStorage.getItem('darkMode') === 'true' ? { "color": "#202225"} : {"color": "white"}}><CollectionBanner address={value.address}/></span>
                  </div>
                  <div className="nft_coll_pp">
                  <Link to={value.link}>
                      <span style={localStorage.getItem('darkMode') === 'true' ? {"color": "#202225"} : {"color": "white"}}><UserCollection small={true} address={value.address}/></span>
                  </Link>
                  </div>
                  <div className="nft_coll_info">
                      <Link to={value.link}>
                        <span style={localStorage.getItem('darkMode') === 'true' ? {"color": "#202225"} : {"color": "white"}}><h4>{value.name}</h4></span>
                      </Link>
                      <span style={localStorage.getItem('darkMode') === 'true' ? { "color": "#202225"} : {"color": "white"}}><CollectionDesc address={value.address}/></span>
                  </div>
              </div>
            </CustomSlide>)})}
          </Slider>
        </div>
    );
  }
}
