import React from 'react';
import ReactDOM from 'react-dom';
import "./assets/animated.css";
import '../node_modules/font-awesome/css/font-awesome.min.css';
import '../node_modules/elegant-icons/style.css';
import '../node_modules/et-line/style.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import './assets/style.scss';
import App from './components/app';
import * as serviceWorker from './serviceWorker';
import { MoralisProvider } from "react-moralis";
import ThemeContextWrapper from './contexts/ThemeContextWrapper';

//redux store
import { Provider } from 'react-redux'
import store from './store';

ReactDOM.render(
	<ThemeContextWrapper>
		<Provider store={store}>
			<MoralisProvider appId="SoYHin4oi7XmjvoHigAqk5X8GoDNbLkhN3rB4f0o" serverUrl="https://cqwfslvskalf.usemoralis.com:2053/server">
				<App />
			</MoralisProvider>
		</Provider>
	</ThemeContextWrapper>, document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();