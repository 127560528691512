import React from 'react';
import { Router, Location, Redirect } from '@reach/router';
import ScrollToTopBtn from './menu/ScrollToTop';
import Header from './menu/header';
import Home from './pages/home';
import { ThemeContext } from '../contexts/ThemeContext';
// import Home1 from './pages/home1';
// import Home2 from './pages/home2';
// import Home3 from './pages/home3';
// import Explore from './pages/explore';
import ExploreListing from "./pages/exploreListing"
import Popular from "./pages/popularCollections";
import MyCollections from "./pages/myCollections";
// import Rangking from './pages/rangking';
import RankingRedux from './pages/RankingRedux';
import Helpcenter from './pages/helpcenter';
import Collection from './pages/collection';
// import ItemDetail from './pages/ItemDetail';
import ItemDetailRedux from './pages/ItemDetailRedux';
import ItemDetail from './pages/ItemDetail';
import Author from './pages/Author';
import Wallet from './pages/wallet';
// import Login from './pages/login';
// import LoginTwo from './pages/loginTwo';
// import Register from './pages/register';
// import Price from './pages/price';
// import Works from './pages/works';
// import News from './pages/news';
import EditProfile from './pages/edit'; 
import EditCollection from './pages/editCollection'; 
import Create from './pages/create';
import Create2 from './pages/create2';
import Create3 from './pages/create3';
import Createoption from './pages/createOptions';
import Activity from './pages/activity';
// import Contact from './pages/contact';
// import ElegantIcons from './pages/elegantIcons';
// import EtlineIcons from './pages/etlineIcons';
// import FontAwesomeIcons from './pages/fontAwesomeIcons';
// import Accordion from './pages/accordion';
// import Alerts from './pages/alerts';
// import Progressbar from './pages/progressbar';
import Tabs from './pages/tabs';

import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0,0), [location])
  return children
}

const PosedRouter = ({ children }) => (

  <Location>
    {({ location }) => (

      <div id='routerhang'>
        <div key={location.key}>
          <Router location={location}>
            {children}
          </Router>
        </div>
      </div>
    )}
  </Location>
);

console.log = function () {};

const app = () => (
  <ThemeContext.Consumer>
  {({ theme }) => (
    

<div className="wraper">
<GlobalStyles />
  <Header theme={theme === "white-content" ? "false" : "true"}/>
  <PosedRouter>
    <ScrollTop exact path="/">
      <Home exact path="/" theme={theme === "white-content" ? "false" : "true"}>
        <Redirect to="/home" />
      </Home>
      {/* <Home1 exact path="/home1" />
      <Home2 exact path="/home2" />
      <Home3 exact path="/home3" /> */}
      {/* <Popular exact path="/popular"  theme={theme === "white-content" ? "false" : "true"}/>
      <MyCollections exact path="/myCollections"  theme={theme === "white-content" ? "false" : "true"}/> */}
      {/* <Explore exact path="/explore" /> */}
      {/* <ExploreListing exact path="/explore"  theme={theme === "white-content" ? "false" : "true"}/> */}
      {/* <Explore2 exact path="/explore2" /> */}
      {/* <RankingRedux exact path="/ranking"  theme={theme === "white-content" ? "false" : "true"}/> */}
      {/* <Auction exact path="/Auction" /> */}
      {/* <Helpcenter exact path="/helpcenter"  theme={theme === "white-content" ? "false" : "true"}/>
      <ItemDetailRedux exact path="/ItemDetail"  theme={theme === "white-content" ? "false" : "true"}/>
      <Author exact path="/Author"  theme={theme === "white-content" ? "false" : "true"}/>
      <Wallet exact path="/wallet"  theme={theme === "white-content" ? "false" : "true"}/> */}
      {/* <Login exact path="/login" />
      <LoginTwo exact path="/loginTwo" /> */}
      {/* <Register exact path="/register" /> */}
      {/* <Price exact path="/price" /> */}
      {/* <Works exact path="/works" />
      <News exact path="/news" /> */}
      {/* <EditProfile exact path="/edit" theme={theme === "white-content" ? "false" : "true"} />
      <Create exact path="/create" />
      <Create2 exact path="/create2" theme={theme === "white-content" ? "false" : "true"}/>
      <Create3 exact path="/create3" theme={theme === "white-content" ? "false" : "true"}/>
      <Createoption exact path="/createOptions" theme={theme === "white-content" ? "false" : "true"} />
      <Activity exact path="/activity" theme={theme === "white-content" ? "false" : "true"} /> */}
      {/* <Contact exact path="/contact" /> */}
      {/* <ElegantIcons exact path="/elegantIcons" />
      <EtlineIcons exact path="/etlineIcons" /> */}
      {/* <FontAwesomeIcons exact path="/fontAwesomeIcons" /> */}
      {/* <Accordion exact path="/accordion" /> */}
      {/* <Alerts exact path="/alerts" /> */}
      {/* <Progressbar exact path="/progressbar" /> */}
      {/* <Tabs exact path="/tabs"  theme={theme === "white-content" ? "false" : "true"}/>
      <EditCollection exact path="/editCollection"  theme={theme === "white-content" ? "false" : "true"}/>
      <Author path="/user/:userId"  theme={theme === "white-content" ? "false" : "true"}/>
      <ItemDetail path="/token/:tokenId"  theme={theme === "white-content" ? "false" : "true"}/> 
      <Collection path="/collection/:collId"  theme={theme === "white-content" ? "false" : "true"}/>  */}
      {/* {!pages.includes(window.location.pathname) && window.location.pathname.split("/")[1].split(":")[0] !== "token" && window.location.pathname.split("/")[1].split(":")[0] !== "edition" && window.location.pathname.split("/")[1].split(":")[0] !== "collection" ? <Author exact path={window.location.pathname}/> : <Tabs exact path="/tabs" />}
      {window.location.pathname.split("/")[1].split(":")[0] === "token" ? <ItemDetail exact path={window.location.pathname}/> : <Tabs exact path="/tabs" />}
      {window.location.pathname.split("/")[1].split(":")[0] === "edition" ? <EditionDetail exact path={window.location.pathname}/> : <Tabs exact path="/tabs" />}
      {window.location.pathname.split("/")[1].split(":")[0] === "collection" ? <Collection exact path={window.location.pathname}/> : <Tabs exact path="/tabs" />} */}
      </ScrollTop>        
    </PosedRouter>
  <ScrollToTopBtn />
</div>
  )}
</ThemeContext.Consumer>

);
export default app;