import React from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import { Link } from '@reach/router';

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const slidermainzero = (props) => (
  <div className="container">
     <div className="row align-items-center">
           <div className="col-md-6 m-auto">

               <div style={{backgroundColor: "rgba(234, 20, 189, 0.9)", borderRadius: "10px"}}>
               <div className="spacer-single"></div>
               <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
               <h6 className="text-center"><span className="text-uppercase color" style={{color: "white"}}>Music</span></h6>
               </Reveal>
               <div className="spacer-half"></div>
               <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600}>
               <h1 className="text-center" style={{color: "white"}}>Music NFT Streaming Service</h1>
               </Reveal>
               <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={600}>
               <p className="lead text-center" style={{color: "white", width: "85%", margin: "0 auto"}}>
                Albums are represented as collections. Each token minted under the collection is a music item/piece. Music files are stored on IPFS or any decentralized file storage system. Music artists can earn royalties from their music and users can seamlessly stream music from their favourite artists, all on the blockchain.
               </p>
               </Reveal>
               <div className="spacer-10"></div>

               <div className="spacer-10"></div>
               <Reveal className='onStep' keyframes={fadeInUp} delay={800} duration={600}>
               <div className='d-flex justify-content-center'>
               <Link to={`/#`} className="btn-main lead inline white" style={{backgroundColor: "#E5DFFE"}}>Explore</Link>
               </div>
               <div className="spacer-single"></div>
               <div className="spacer-half"></div>
       
               </Reveal>
               </div>
               <Reveal className='onStep' keyframes={fadeInUp} delay={1000} duration={600}>
               </Reveal>
           </div>
       </div>
     </div>
 );
 export default slidermainzero;