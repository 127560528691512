import React, { memo } from "react";
import { useMoralisCloudFunction } from "react-moralis";
import Identicon from 'identicon.js';
import SharedStorefront from "../../contracts/SharedStorefront.json";


const UserCollection = (props) => {
  const { data } = useMoralisCloudFunction("queryCollection",
	  {"address": props.address}
  );
  

	console.log(props.address)
	console.log(data)
    return (
      
		<><img className="small-image lazy" style={!props.small ? (!props.smaller ? {width: "150px", height: "150px"} : {width: "50px", height: "50px"}) : {width: "60px", height: "60px"}} src={props.address !== "..." && ((data && data.length === 0) ? (props.address !== SharedStorefront.networks["940"].address ? `data:image/png;base64,${new Identicon(props.address, 420).toString()}` : `${window.location.origin}/img/pulseverse-square-logo.png`) : data && data[0].get('profilePhoto')._url )} alt=""/><br/></>
    );
};

export default memo(UserCollection);