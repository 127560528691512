import React, {useEffect} from "react";
import SignedDrop from "../components/SignedDrop"
import WrongNetwork from "../components/WrongNetwork";
import Clock from "../components/Clock";
import { Link } from '@reach/router';

import { createGlobalStyle } from 'styled-components';
import { useMoralisCloudFunction, useMoralis, useNewMoralisObject } from "react-moralis";
import IPFSGatewayTools from '@pinata/ipfs-gateway-tools/dist/node';
import ERC721 from "../../contracts/ERC721.json";
import ERC1155 from "../../contracts/CustomCollectionERC1155.json";
import IERC165 from "../../contracts/IERC165.json";
import CurrencyManager from "../../contracts/CurrencyManager.json"
import SharedStorefront from "../../contracts/SharedStorefront.json";
import PulseVerseExchange from "../../contracts/PulseVerseExchange.json";
import TransferManagerERC721 from "../../contracts/TransferManagerERC721.json";
import TransferManagerERC1155 from "../../contracts/TransferManagerERC1155.json";
import StrategyStandardSaleForFixedPrice from "../../contracts/StrategyStandardSaleForFixedPrice.json";
import WETH9 from "../../contracts/WETH9.json";




import UserImage from "../components/UserImage"
import UserName from "../components/UserName"
import UserCollection from "../components/UserCollection"
import NftAdditional from "../components/NftAdditional"
import SwapModal from "../components/SwapModal"

import Token from "../../contracts/KnownOriginDigitalAssetV3.json";
import StepsList from "../components/StepsList";
import StepsTransfer from "../components/StepsTransfer";
import IERC20 from "../../contracts/ERC20.json";
import IERC2981 from "../../contracts/IERC2981.json";
import tokens from "../../tokens.json";
import tokensBid from "../../tokensBid.json";
import nameTokens from "../../nameTokens.json";
import nameTokensBid from "../../nameTokensBid.json";
import ItemInteraction from "../components/ItemInteraction";

const ENS_ADDRESS = "0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85";

const ipfsGatewayTools = new IPFSGatewayTools();
const desiredGatewayPrefix = 'https://rarible.mypinata.cloud'
const INTERFACE_ID_ERC721 = "0x80ac58cd";
const INTERFACE_ID_ERC1155 = "0xd9b67a26";

const tokenToImage = {
  "0x7e8c767e460b51fd34ec2ce89d8c4b06bca525a3": "../img/pulsechain-logo.png",
  "0x2b591e99afe9f32eaa6214f7b7629768c40eeb39": "../img/hex-logo.png"
}

console.log(WETH9.networks["941"].address)

const NavLink = props => (
  <Link 
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);

const isNonceCancelled = async (user, nonce) => {
  const web3 = window.web3;
  let exchange = new web3.eth.Contract(PulseVerseExchange.abi, PulseVerseExchange.networks["941"].address)
  let cancelled = await exchange.methods.isUserOrderNonceExecutedOrCancelled(user, nonce).call();
  console.log(cancelled)
  return cancelled;
}

const isOrderValid = (order) => {
  let now = Date.now()
  return (now / 1000) < parseInt(order.get('endTime'));

}

const isApprovedForAllERC721 = async (user) => {
  const subjectAddress= window.location.pathname.split("/")[2].split(":")[0]
    const web3 = window.web3;
    const digitalAsset = new web3.eth.Contract(ERC721.abi, subjectAddress)
    let isApprovedForAll;
    if (user) {
      isApprovedForAll = await digitalAsset.methods.isApprovedForAll(user.get('ethAddress'), TransferManagerERC721.networks["941"].address).call();
    }
    return isApprovedForAll;
  }

  const approveForAllERC721 = async (user) => {
    const subjectAddress= window.location.pathname.split("/")[2].split(":")[0]
    const web3 = window.web3;
    const digitalAsset = new web3.eth.Contract(ERC721.abi, subjectAddress)
      document.getElementById("init").innerHTML = "In Progress...";

      await digitalAsset.methods.setApprovalForAll(TransferManagerERC721.networks["941"].address, true).send({from: user.get('ethAddress')}); 
      document.getElementById("init").innerHTML = "Done";
      return true;

  }

  const isApprovedForAllERC1155 = async (user) => {
    const subjectAddress= window.location.pathname.split("/")[2].split(":")[0]
      const web3 = window.web3;
      const digitalAsset = new web3.eth.Contract(ERC721.abi, subjectAddress)
      let isApprovedForAll;
      if (user) {
        isApprovedForAll = await digitalAsset.methods.isApprovedForAll(user.get('ethAddress'), TransferManagerERC1155.networks["941"].address).call();
      }
      return isApprovedForAll;
    }
  
    const approveForAllERC1155 = async (user) => {
      const subjectAddress= window.location.pathname.split("/")[2].split(":")[0]
      const web3 = window.web3;
      const digitalAsset = new web3.eth.Contract(ERC721.abi, subjectAddress)
        document.getElementById("init").innerHTML = "In Progress...";
  
        await digitalAsset.methods.setApprovalForAll(TransferManagerERC1155.networks["941"].address, true).send({from: user.get('ethAddress')}); 
        document.getElementById("init").innerHTML = "Done";
        return true;
  
    }
  
const transferHandler = async (setWalletInit, setOpenSteps, user) => {
  const subjectAddress= window.location.pathname.split("/")[2].split(":")[0]
  const subjectId = window.location.pathname.split("/")[2].split(":")[1]

  const web3 = window.web3
  let contract = new web3.eth.Contract(IERC165.abi, subjectAddress);
  let isERC721 = await contract.methods.supportsInterface(INTERFACE_ID_ERC721).call();
  let isERC1155 = await contract.methods.supportsInterface(INTERFACE_ID_ERC1155).call();





      let recipitent = document.getElementById("recipitent").value;

            document.getElementById("transfer").innerHTML = "Transferring...";

    if (isERC721) {
      const digitalAsset = new web3.eth.Contract(ERC721.abi, subjectAddress)
      try {
        await digitalAsset.methods.transferFrom(user.get('ethAddress'), recipitent, subjectId).send({from: user.get('ethAddress')})
        document.getElementById("transfer").innerHTML = "Transfer";    
        document.getElementById("errorTransfer").style.color = "green";
        document.getElementById("errorTransfer").innerHTML = "Successful";
      } catch (error) {
          document.getElementById("errorTransfer").innerHTML = error.message;
          document.getElementById("transfer").innerHTML = "Transfer";  
      }
    } else if (isERC1155) {   
      const digitalAsset = new web3.eth.Contract(ERC1155.abi, subjectAddress)
      let amount = document.getElementById("amount").value;
      try {
        await digitalAsset.methods.safeTransferFrom(user.get('ethAddress'), recipitent, subjectId, amount, "0x").send({from: user.get('ethAddress')})
        document.getElementById("transfer").innerHTML = "Transfer";    
        document.getElementById("errorTransfer").style.color = "green";
        document.getElementById("errorTransfer").innerHTML = "Successful";
      } catch (error) {
          document.getElementById("errorTransfer").innerHTML = error.message;
          document.getElementById("transfer").innerHTML = "Transfer";  
      }
    }

}



const Colection= function(props) {

const dark = props.theme;

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #fff;
    border-bottom: solid 1px #dddddd;
  }
  .mr40{
    margin-right: 40px;
  }
  .mr15{
    margin-right: 15px;
  }
  .btn2{
    background: #f6f6f6;
    color: #EA14BD !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #111;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
  }
  h1, h2, h4, h3, h5, h6, .h1_big, .h1, .h2, .h3, .h4, .h5, .h6, p, a {
    color: ${dark === 'true' ? "#121212" : "white"};
  }
  span {
    color: ${dark === 'true' ? "#727272" : "white"};
  }
  .form-control, textarea, .dropbtn {
    color: ${dark === 'true' ? "#121212" : "white"} !important;
    background: ${dark === 'true' ? "white" : "#121212"} !important;
  }
  .pToken {
    color: ${dark === 'true' ? "#121212" : "white"} !important;
    background: ${dark === 'true' ? "white" : "#121212"} !important;
  }
	.maincheckout {
		background: ${dark === 'true' ? "white" : "#121212"};
		color: ${dark === 'true' ? "#121212" : "white"};
	}
  .nft_attr {
    background: ${dark === 'true' ? "#F2EFFC" : "#252A2D"};
  }
  .nft_attr h4 {
    color: ${dark === 'true' ? "#121212" : "white"};
  }
  .de_nav li span {
    background: ${dark === 'true' ? "#F2EFFC" : "#252A2D"};
  }
  .price-modal {
    border: 0.5px solid ${dark === 'true' ? "#121212" : "white"};
    background: ${dark === 'true' ? "#F2EFFC" : "#252A2D"};
  }
`;


    const { save: saveLike } = useNewMoralisObject('Likes');
    const { save: listingSave  } = useNewMoralisObject('Listing');

    const [linkCopied, setLinkCopied] = React.useState(false)
    const [nftName, setNftName] = React.useState(null)
    const [nftOwner, setNftOwner] = React.useState(null)
    const [nftCollection, setNftCollection] = React.useState(null)
    const [nftDescription, setNftDescription] = React.useState(null)
    const [nftCreator, setNftCreator] = React.useState(null)
    const [isAuction, setIsAuction] = React.useState(false);
    const [deadline, setDeadline] = React.useState(0);
    const [subjectAddress, ] = React.useState(window.location.pathname.split("/") && window.location.pathname.split("/")[2].split(":")[0])
    const [subjectId, ] = React.useState(window.location.pathname.split("/") && window.location.pathname.split("/")[2].split(":")[1])
    const [editions, setEditions] = React.useState("...")
    
    const [nftImage, setNftImage] = React.useState(null)
    const [transferEvents, setTransferEvents] = React.useState([]);
    const [nftExtension, setNftExtension] = React.useState("");
    const [openMenu0, setOpenMenu0] = React.useState(true);
    const [openMenu, setOpenMenu] = React.useState(false);
    const [openMenu1, setOpenMenu1] = React.useState(false);
    const [openMenu2, setOpenMenu2] = React.useState(false);

    const [dropDownTrue, setDropDownTrue] = React.useState(false);
    const [onSaleType, setOnSaleType] = React.useState("");
    const [nftAttributes, setNftAttributes] = React.useState([])
    const [listingType, setListingType] = React.useState(0);
    const [token, setToken] = React.useState("PLS");
    const [bidToken, setBidToken] = React.useState("WPLS");
    const [validity, setValidity] = React.useState("1 day");

    const [transfer, setTransfer] = React.useState(false);
    const [walletInit, setWalletInit] = React.useState(false);
    const [nftPreview, setPreview] = React.useState("");
    const [transfer20, setTransfer20] = React.useState(false)
    const [transfer721, setTransfer721] = React.useState(false);
    const [prc20Balances, setPrc20Balances] = React.useState([])
    const [prc721Balance, setPrc721Balance] = React.useState({});
    const [saleKind, setSaleKind] = React.useState("");
    const [openResult, setOpenResult] = React.useState(false);
    const [ownsTokens, setOwnsTokens] = React.useState(false);
    const [offer, setActiveOffer] = React.useState(null)
    const [activeListing, setActiveListing] = React.useState(null)

    const [followed, setFollowed] = React.useState(false);
    const [unfollowed, setUnFollowed] = React.useState(false);
    const [editionId, setEditionId] = React.useState("");

    const [balanceTokens, setBalanceOfTokens] = React.useState(null);
    const [openBuy, setOpenBuy] = React.useState(false);
    const [openBuySpecific, setOpenBuySpecific] = React.useState(false);

    const [changeListing, setChangeListing] = React.useState(false);
    const [composeNFT, setComposeNFT] = React.useState(false);
    const [composePRC20, setComposePRC20] = React.useState(false);
    const [openSteps, setOpenSteps] = React.useState(false);
    const [openCheckout, setOpenCheckout] = React.useState(false);
    const [openCheckoutbid, setOpenCheckoutbid] = React.useState(false);
    const [openCheckoutOnSale, setOpenCheckoutOnSale] = React.useState(false);
    const [openCancelBid, setOpenCancelBid] = React.useState(false);
    const [openAcceptBid, setOpenAcceptBid] = React.useState(false);

    const [editionToken, setEditionToken] = React.useState("");
    const [price, setPrice] = React.useState(0);
    const [wrongNetwork, setWrongNetwork] = React.useState(false);
    const [listener, setListener] = React.useState(false);
    const [isERC1155, setIsERC1155] = React.useState(false);
    const [isERC721, setIsERC721] = React.useState(false);

    const [makerAskData, setMakerAskData] = React.useState(null);
    const [makerAskDataUser, setMakerAskDataUser] = React.useState(null);
    const [makerBidData, setMakerBidData] = React.useState(null);
    const [makerBidDataByPrice, setMakerBidDataByPrice] = React.useState(null);
    const [makerBidDataUser, setMakerBidDataUser] = React.useState(null);
    const [convert, setConvert] = React.useState(false);

    const [editionBidsIsLoading, setEditionBidsIsLoading] = React.useState(true);
    const [historyIsLoading, setHistoryIsLoading] = React.useState(true);

    const { data, error } = useMoralisCloudFunction("queryNFTDataLazy",
        {"objectId": subjectId},
    );

	const { isAuthenticated, user, logout, isInitialized } = useMoralis()
  const { save: makerSave  } = useNewMoralisObject('MakerAsk');
  const { save: makerBidSave  } = useNewMoralisObject('MakerBid');
  console.log(isAuthenticated)


    const { data: listingData, isLoading: listingIsLoading } = useMoralisCloudFunction("queryListingDataNFT",
        {"tokenId": subjectId, "contractAddress": subjectAddress, "itemType": "token"}
    );

    const { data: makerAskDataNotClean, isLoading: makerIsLoading } = useMoralisCloudFunction("queryMakerAskItem",
        {"tokenId": subjectId, "contractAddress": subjectAddress}
    );

    const { data: makerBidDataNotClean, isLoading: makerBidIsLoading } = useMoralisCloudFunction("queryMakerBidItem",
        {"tokenId": subjectId, "contractAddress": subjectAddress}
    );

    const { data: makerBidDataByPriceNotClean, isLoading: makerBidByPriceIsLoading } = useMoralisCloudFunction("queryMakerBidItemByPrice",
        {"tokenId": subjectId, "contractAddress": subjectAddress}
    );

    const { data: makerAskDataUserNotClean, isLoading: makerIsLoadingUser } = useMoralisCloudFunction("queryMakerAskItemUser",
      {"tokenId": subjectId, "contractAddress": subjectAddress, "signer": user && user.get('ethAddress')}
    );
  
    const { data: makerBidDataUserNotClean, isLoading: makerBidIsLoadingUser } = useMoralisCloudFunction("queryMakerBidItemUser",
      {"tokenId": subjectId, "contractAddress": subjectAddress, "signer": user && user.get('ethAddress')}
    );

    const {fetch: fetchLike, data: likeData} = useMoralisCloudFunction("queryLikeData", 
      {"tokenId": subjectId, "contractAddress": subjectAddress, "itemType": "token"}
    );

    const { data: makerAskDataCreated, error: makerAskErrorCreated } = useMoralisCloudFunction("queryMakerAsk",
      {"address": user && user.get('ethAddress')}
    );

    const { data: makerBidDataCreated, error: makerBidErrorCreated } = useMoralisCloudFunction("queryMakerBid",
      {"address": user && user.get('ethAddress')}
    );



    useEffect(() => {

      const loadTokenBalance = async () => {
        try {
          if (user) {
  
              // HEX Balance
              let tokenContract = new web3.eth.Contract(IERC20.abi, "0x2b591e99afe9f32eaa6214f7b7629768c40eeb39")
              let balanceOfHex = await tokenContract.methods.balanceOf(user.get('ethAddress')).call();

              // WETH balance
              const weth = new web3.eth.Contract(WETH9.abi, WETH9.networks["941"].address)
              let balance = await weth.methods.balanceOf(user.get('ethAddress')).call();

              setBalanceOfTokens({
                "PLS": await web3.eth.getBalance(user.get('ethAddress')),
                "HEX": balanceOfHex,
                "WPLS": balance
              })
          }
        } catch (error) {
          setBalanceOfTokens(null)
        }
      }

      (async () => {
        await loadTokenBalance()
      })()
    }, [user])


      // Fetching history data from ERC721 contract
      useEffect(() => {

        (async () => {

          let makerAskDataClean = []
        
          if (makerAskDataNotClean) {
            for (let i = 0; i < makerAskDataNotClean.length; i++) {
              let nonceCancelled = await isNonceCancelled(makerAskDataNotClean[i].get("signer"), makerAskDataNotClean[i].get("nonce"))
              let orderValid = isOrderValid(makerAskDataNotClean[i]);
                if (!nonceCancelled && orderValid) {
                  makerAskDataClean.push(makerAskDataNotClean[i])
                }
      
            }
      
            
            setMakerAskData([...makerAskDataClean]);
          }
          console.log(makerAskDataClean)
          
      
          let makerAskDataUserClean = []
          if (makerAskDataUserNotClean && user) {
            for (let i = 0; i < makerAskDataUserNotClean.length; i++) {
              let nonceCancelled = await isNonceCancelled(user.get('ethAddress'), makerAskDataUserNotClean[i].get("nonce"))
              let orderValid = isOrderValid(makerAskDataUserNotClean[i]);
                if (!nonceCancelled && orderValid) {
                  makerAskDataUserClean.push(makerAskDataUserNotClean[i])
                }
      
            }
            setMakerAskDataUser([...makerAskDataUserClean]);
          }
      
          console.log(makerAskDataNotClean)

          let makerBidDataClean = []
          if (makerBidDataNotClean) {
            for (let i = 0; i < makerBidDataNotClean.length; i++) {
              let nonceCancelled = await isNonceCancelled(makerBidDataNotClean[i].get("signer"), makerBidDataNotClean[i].get("nonce"))
              let orderValid = isOrderValid(makerBidDataNotClean[i]);
                if (!nonceCancelled && orderValid) {
                  makerBidDataClean.push(makerBidDataNotClean[i])
                }
      
            }
            setMakerBidData([...makerBidDataClean]);
          }
      
          console.log(makerBidDataClean)    
          
          
          

          let makerBidDataCleanByPrice = []
          if (makerBidDataByPriceNotClean) {
            for (let i = 0; i < makerBidDataByPriceNotClean.length; i++) {
              let nonceCancelled = await isNonceCancelled(makerBidDataByPriceNotClean[i].get("signer"), makerBidDataByPriceNotClean[i].get("nonce"))
              let orderValid = isOrderValid(makerBidDataByPriceNotClean[i]);
                if (!nonceCancelled && orderValid) {
                  makerBidDataCleanByPrice.push(makerBidDataByPriceNotClean[i])
                }
      
            }
            setMakerBidDataByPrice([...makerBidDataCleanByPrice]);
          }
      
          console.log(makerBidDataCleanByPrice)    

          let makerBidDataUserClean = []
          if (makerBidDataUserNotClean && user) {
            for (let i = 0; i < makerBidDataUserNotClean.length; i++) {
              let nonceCancelled = await isNonceCancelled(user.get('ethAddress'), makerBidDataUserNotClean[i].get("nonce"))
              let orderValid = isOrderValid(makerBidDataUserNotClean[i]);
                if (!nonceCancelled && orderValid) {
                  makerBidDataUserClean.push(makerBidDataUserNotClean[i])
                }
      
            }
            setMakerBidDataUser([...makerBidDataUserClean]);
          }
      
          console.log(makerBidDataUserClean)


          
          setEditionBidsIsLoading(false);      
      

          let contract = new web3.eth.Contract(IERC165.abi, subjectAddress);

          let is721 = await contract.methods.supportsInterface(INTERFACE_ID_ERC721).call();
          let is1155 = await contract.methods.supportsInterface(INTERFACE_ID_ERC1155).call();
          setIsERC721(is721)
          setIsERC1155(is1155)


          
        let transferEvs = []
           
        // If token id is a number and not ENS then fetch tokenURI
        if (subjectAddress !== ENS_ADDRESS) {
            try {
              
                const web3 = window.web3;
                let contract = new web3.eth.Contract(IERC165.abi, subjectAddress);
    
                let isERC721 = await contract.methods.supportsInterface(INTERFACE_ID_ERC721).call()
                let isERC1155 = await contract.methods.supportsInterface(INTERFACE_ID_ERC1155).call()
                    if (isERC721) {
                      const token = new web3.eth.Contract(ERC721.abi, subjectAddress)
                      token.getPastEvents(
                        'Transfer',
                      {
                          "fromBlock": 14360999,
                          "toBlock": 'latest',
                          'filter': {"id": subjectId}
                      },
                            async (err, events) => { 
                              console.error(error)
                                if (events.length > 0) {
                                    let data = await web3.eth.getBlock(events[0].blockNumber);
                                    events[0]["timestamp"] = data.timestamp;
                                    transferEvs.push(events[0])    
                                    setNftCreator(`${events[0].returnValues.to}`)           
                                }
                                
                                setTransferEvents(transferEvs)   
                                setHistoryIsLoading(false);
                            }
                      )
                      let name = await token.methods.name().call()
                          setNftCollection(name)  

                      let owner = await token.methods.ownerOf(subjectId).call();
                          setNftOwner(owner)  

                      setEditions(1);
          
                      let uri = await token.methods.tokenURI(subjectId).call()
                        console.log(uri)
                        console.log(ipfsGatewayTools.convertToDesiredGateway(uri, desiredGatewayPrefix))
                        let finalURI;
                        try {
                          finalURI = `https://thawing-peak-27665.herokuapp.com/${ipfsGatewayTools.convertToDesiredGateway(uri, desiredGatewayPrefix)}`;
                        } catch (error) {
                            if (!uri.startsWith("data:application")) {
                              finalURI = `https://thawing-peak-27665.herokuapp.com/${uri}`;
                            } else {
                              finalURI = uri
                            }
                        }
                          console.log(finalURI)
                              fetch(finalURI
                                  ,{
                                    headers : { 
                                      'Content-Type': 'application/json',
                                      'Accept': 'application/json'
                                     }
                                  }
                                  )
                                    .then(function(response){
                                      console.log(response)
                                      if (response.ok) {
                                        return response.json();
                                      }
          
                                    })
                                    .then(async function(myJson) {
                                      let name = await token.methods.name().call()
                                      console.log(myJson)
                                      if (myJson) {
                                        let desc = myJson["description"];
                                        if (myJson["description"]) {
                                          myJson["description"] = desc.replaceAll("\\n", "\n");
                                        }
                                        if (myJson["name"]) {
                                          console.log(myJson["name"])
                                          console.log(typeof myJson["name"])
                                          setNftName(myJson["name"])
                                        } else {
                                          setNftName(`${name} #${subjectId}`)
                                        }
                                        if (nftAttributes.length === 0 && myJson["attributes"] && myJson["attributes"].length !== 0) {
                                          if (Array.isArray(myJson["attributes"])) {
                                            setNftAttributes(myJson["attributes"])
                                          } else {
                                            let attributes = []
                                            for (const [key, value] of Object.entries(myJson["attributes"])) {
                                              attributes.push({"trait_type": key, "value": value})
                                            } 
                                            setNftAttributes(attributes)
                                          }
                                        }
          
                                        console.log(makerAskDataClean)
          
                                        if (makerAskDataClean && makerAskDataClean[0]) {
                                              let listing = makerAskDataClean[0].get('listing');
                                              setSaleKind(listing)
                                              setOnSaleType(`${makerAskDataClean[0].get('price')/10**tokens[makerAskDataClean[0].get('currency').toLowerCase()][1]} ${tokens[makerAskDataClean[0].get('currency').toLowerCase()][0]} `)
                                              setPrice(makerAskDataClean[0].get('price'))
                                              if (listing === "buy") {
                                                setEditionToken(makerAskDataClean[0].get('currency'))
                                              } else if (listing === "reserve") {
          
          
                                              }
                                            } else if (!makerIsLoading) {
                           
                                                setOnSaleType("Unlisted")        
                                            }
          
                                        setNftDescription(myJson["description"])
          
                              let image;
                              let imagePreview;
                              console.log(myJson)
                              if (myJson["image"]) {
                                try {
                                  image = ipfsGatewayTools.convertToDesiredGateway(myJson["image"], desiredGatewayPrefix);
                                } catch (error) {
                                  image = myJson["image"];
                                }
                              } else if (myJson["image_url"]) {
                                  try {
                                    image = ipfsGatewayTools.convertToDesiredGateway(myJson["image_url"], desiredGatewayPrefix);
                                  } catch (error) {
                                    image = myJson["image_url"];
                                  }  
                              }
          
                              try {
                                imagePreview = ipfsGatewayTools.convertToDesiredGateway(myJson["image_preview"], desiredGatewayPrefix)
                              } catch (error) {
                                imagePreview = myJson["image_preview"]
                              }
                              setPreview(imagePreview);
                              console.log(image)
                            var xhr = new XMLHttpRequest();
                            if (!image.startsWith("data:image")) {
                              xhr.open('HEAD', `https://thawing-peak-27665.herokuapp.com/${image}`, true);
                            } else {
                              xhr.open('HEAD', `${image}`, true);
                            }
          
          
                            xhr.onload = function() {
                                var contentType = xhr.getResponseHeader('Content-Type');
                                console.log(contentType)
                                setNftExtension(contentType)
                            };
          
                            xhr.send();
                                          setNftImage(image)
                                          
                                          console.log(transferEvents)
                                      }
          
          
                                    });
              
                    } else if (isERC1155) {
                      const token = new web3.eth.Contract(ERC1155.abi, subjectAddress)
                      token.getPastEvents(
                        'TransferSingle',
                      {
                          "fromBlock": 14360999,
                          "toBlock": 'latest',
                          'filter': {"id": subjectId}
                      },
                            async (err, events) => { 
                              console.error(error)
                              
                                if (events.length > 0) {
                                    let data = await web3.eth.getBlock(events[0].blockNumber);
                                    events[0]["timestamp"] = data.timestamp;
                                    transferEvs.push(events[0])    
                                    setNftCreator(`${events[0].returnValues.to}`)           
                                }
                                setTransferEvents(transferEvs)   
                                setHistoryIsLoading(false);
                            }
                      )
                      let name = await token.methods.name().call()
                          setNftCollection(name)  

                      let totalSupply = await token.methods.totalSupply(subjectId).call();
                      setEditions(totalSupply);


                      if (user) {
                        token.methods.balanceOf(user.get('ethAddress'), subjectId).call().then((balanceOf) => {
                          if (balanceOf > 0) {
                            console.log(balanceOf)
                            setOwnsTokens(true)
                          }
                        });
                      }
    
          
                      let uri = await token.methods.uri(subjectId).call();
                        console.log(uri)
                        let finalURI;
                        try {
                          finalURI = `https://thawing-peak-27665.herokuapp.com/${ipfsGatewayTools.convertToDesiredGateway(uri, desiredGatewayPrefix)}`;
                        } catch (error) {
                            if (!uri.startsWith("data:application")) {
                              finalURI = `https://thawing-peak-27665.herokuapp.com/${uri}`;
                            } else {
                              finalURI = uri
                            }
                        }
                          console.log(finalURI)
                              fetch(finalURI
                                  ,{
                                    headers : { 
                                      'Content-Type': 'application/json',
                                      'Accept': 'application/json'
                                     }
                                  }
                                  )
                                    .then(function(response){
                                      console.log(response)
                                      if (response.ok) {
                                        return response.json();
                                      }
          
                                    })
                                    .then(async function(myJson) {
                                      let name = await token.methods.name().call()
                                      console.log(myJson)
                                      if (myJson) {
                                        let desc = myJson["description"];
                                        if (myJson["description"]) {
                                          myJson["description"] = desc.replaceAll("\\n", "\n");
                                        }
                                        if (myJson["name"]) {
                                          console.log(myJson["name"])
                                          console.log(typeof myJson["name"])
                                          setNftName(myJson["name"])
                                        } else {
                                          setNftName(`${name} #${subjectId}`)
                                        }
                                        if (nftAttributes.length === 0 && myJson["attributes"] && myJson["attributes"].length !== 0) {
                                          if (Array.isArray(myJson["attributes"])) {
                                            setNftAttributes(myJson["attributes"])
                                          } else {
                                            let attributes = []
                                            for (const [key, value] of Object.entries(myJson["attributes"])) {
                                              attributes.push({"trait_type": key, "value": value})
                                            } 
                                            setNftAttributes(attributes)
                                          }
                                        }
          
                                        console.log(makerAskDataClean)
                                        if (makerAskDataClean && makerAskDataClean[0]) {
                                              let listing = makerAskDataClean[0].get('listing');
                                              setSaleKind(listing)
                                              console.log(makerAskDataClean[0].get('currency').toLowerCase())
                                              console.log(tokens[makerAskDataClean[0].get('currency').toLowerCase()][0])
                                              setOnSaleType(`${makerAskDataClean[0].get('price')/10**tokens[makerAskDataClean[0].get('currency').toLowerCase()][1]} ${tokens[makerAskDataClean[0].get('currency').toLowerCase()][0]} `)
                                              setPrice(makerAskDataClean[0].get('price'))
                                              if (listing === "buy") {
                                                setEditionToken(makerAskDataClean[0].get('currency'))
                                              } else if (listing === "reserve") {
          
          
                                              }
                                            } else if (!makerIsLoading) {
              
                                                setOnSaleType("Unlisted")        
                                            }
          
                                        setNftDescription(myJson["description"])
          
                              let image;
                              let imagePreview;
                              console.log(myJson)
                              if (myJson["image"]) {
                                try {
                                  image = ipfsGatewayTools.convertToDesiredGateway(myJson["image"], desiredGatewayPrefix);
                                } catch (error) {
                                  image = myJson["image"];
                                }
                              } else if (myJson["image_url"]) {
                                  try {
                                    image = ipfsGatewayTools.convertToDesiredGateway(myJson["image_url"], desiredGatewayPrefix);
                                  } catch (error) {
                                    image = myJson["image_url"];
                                  }  
                              }
          
                              try {
                                imagePreview = ipfsGatewayTools.convertToDesiredGateway(myJson["image_preview"], desiredGatewayPrefix)
                              } catch (error) {
                                imagePreview = myJson["image_preview"]
                              }
                              setPreview(imagePreview);
                              console.log(image)
                            var xhr = new XMLHttpRequest();
                            if (!image.startsWith("data:image")) {
                              xhr.open('HEAD', `https://thawing-peak-27665.herokuapp.com/${image}`, true);
                            } else {
                              xhr.open('HEAD', `${image}`, true);
                            }
          
          
                            xhr.onload = function() {
                                var contentType = xhr.getResponseHeader('Content-Type');
                                console.log(contentType)
                                setNftExtension(contentType)
                            };
          
                            xhr.send();
                                          setNftImage(image)
                                          
                                          console.log(transferEvents)
                                      }
          
          
                                    });
              
                    }
        
                
    
    
            } catch (error) {
    
                console.error(error)
            }
        }
    

        })()



               


        

      
        }, [makerAskDataNotClean, makerAskDataUserNotClean, makerBidDataNotClean, makerBidDataUserNotClean, makerBidDataByPriceNotClean, data, user, isAuthenticated]) /* eslint-disable-line */

    let followers;
    let followerList = [];
    if (likeData) {
      followerList = likeData[0] ? likeData[0].get('followerList') : []
      followers = likeData[0] ? likeData[0].get('followers') : 0
    }

    const signMakerBid = async (collection, price, tokenId, amount, nonce, startTime, endTime, currency, strategy) => {
      const web3 = window.web3;
      const exchangeAddress = PulseVerseExchange.networks["941"].address;
        const msgParams = JSON.stringify({
          domain: {
            name: 'LooksRareExchange',
            version: '1',
            chainId: 941,
            verifyingContract: exchangeAddress,
          },
          message: {
            isOrderAsk: false,
            signer: user.get('ethAddress'),
            collection: collection,
            price: price,
            tokenId: tokenId,
            amount: amount,
            strategy: strategy,
            currency: currency,
            nonce: nonce,
            startTime: startTime,
            endTime: endTime,
            minPercentageToAsk: "8500",
            params: "0x",
          },
          primaryType: 'MakerOrder',
          types: {
            EIP712Domain: [
              { name: 'name', type: 'string' },
              { name: 'version', type: 'string' },
              { name: 'chainId', type: 'uint256' },
              { name: 'verifyingContract', type: 'address' }
            ],
            MakerOrder: [
              { name: 'isOrderAsk', type: 'bool' },
              { name: 'signer', type: 'address' },
              { name: 'collection', type: 'address' },
              { name: 'price', type: 'uint256' },
              { name: 'tokenId', type: 'uint256' },
              { name: 'amount', type: 'uint256' },
              { name: 'strategy', type: 'address' },
              { name: 'currency', type: 'address' },
              { name: 'nonce', type: 'uint256' },
              { name: 'startTime', type: 'uint256' },
              { name: 'endTime', type: 'uint256' },
              { name: 'minPercentageToAsk', type: 'uint256' },
              { name: 'params', type: 'bytes' },
            ],
          }
        });
      
        var from = user.get('ethAddress')
      
        var params = [from, msgParams]
        var method = 'eth_signTypedData_v4'
      
        await web3.currentProvider.sendAsync({
          method,
          params,
          from,
        }, async function (err, result) {
          if (err) {
            console.error(err)
            document.getElementById("errorBid").innerHTML = err.message;
            document.getElementById("bid").innerHTML = "Make Offer";
            return;
          }
  
          if (result.error) {
            console.error(result.error)
            document.getElementById("errorBid").innerHTML = result.error.message;
            document.getElementById("bid").innerHTML = "Make Offer";
            return;
          }
  
          console.log('TYPED SIGNED:' + JSON.stringify(result.result))
  
          var r = result.result.slice(0, 66)
          var s = '0x' + result.result.slice(66, 130)
          var v = '0x' + result.result.slice(130, 132)
          v = web3.utils.toDecimal(v);
  
          console.log(r)
          console.log(s)
          console.log(v)
  
          // await buyNowMarketplace.methods.listForBuyNow(user.get('ethAddress'), id, parsedPrice.toString(), 0, tokenContractAddress).send({from: user.get('ethAddress')});
  
          await makerBidSave({
            isOrderAsk: false,
            signer: user.get('ethAddress'),
            collection: collection,
            price: price,
            tokenId: tokenId,
            amount: amount,
            strategy: StrategyStandardSaleForFixedPrice.networks["941"].address,
            currency: currency,
            nonce: nonce,
            startTime: startTime,
            endTime: endTime,
            minPercentageToAsk: "8500",
            params: "0x",
            listing: "offer",
            r,
            s,
            v
          });

          document.getElementById("errorBid").style.color = "green";
          document.getElementById("errorBid").innerHTML = "Successful";
          document.getElementById("bid").innerHTML = "Place Bid";
      
        })
      }


    const signMakerOrderPriceChange = async (collection, price, tokenId, amount, nonce, startTime, endTime, currency, strategy) => {
      const web3 = window.web3;
      const exchangeAddress = PulseVerseExchange.networks["941"].address;
        const msgParams = JSON.stringify({
          domain: {
            name: 'LooksRareExchange',
            version: '1',
            chainId: 941,
            verifyingContract: exchangeAddress,
          },
          message: {
            isOrderAsk: true,
            signer: user.get('ethAddress'),
            collection: collection,
            price: price,
            tokenId: tokenId,
            amount: amount,
            strategy: strategy,
            currency: currency,
            nonce: nonce,
            startTime: startTime,
            endTime: endTime,
            minPercentageToAsk: "8500",
            params: "0x",
          },
          primaryType: 'MakerOrder',
          types: {
            EIP712Domain: [
              { name: 'name', type: 'string' },
              { name: 'version', type: 'string' },
              { name: 'chainId', type: 'uint256' },
              { name: 'verifyingContract', type: 'address' }
            ],
            MakerOrder: [
              { name: 'isOrderAsk', type: 'bool' },
              { name: 'signer', type: 'address' },
              { name: 'collection', type: 'address' },
              { name: 'price', type: 'uint256' },
              { name: 'tokenId', type: 'uint256' },
              { name: 'amount', type: 'uint256' },
              { name: 'strategy', type: 'address' },
              { name: 'currency', type: 'address' },
              { name: 'nonce', type: 'uint256' },
              { name: 'startTime', type: 'uint256' },
              { name: 'endTime', type: 'uint256' },
              { name: 'minPercentageToAsk', type: 'uint256' },
              { name: 'params', type: 'bytes' },
            ],
          }
        });
      
        var from = user.get('ethAddress')
      
        var params = [from, msgParams]
        var method = 'eth_signTypedData_v4'
      
        await web3.currentProvider.sendAsync({
          method,
          params,
          from,
        }, async function (err, result) {
          if (err) {
            document.getElementById("errorPrice").innerHTML = err.message
            document.getElementById("listing").innerHTML = "Change"
            return;
          }
  
          if (result.error) {
            document.getElementById("errorPrice").innerHTML = result.error.message
            document.getElementById("listing").innerHTML = "Change"
            return;
          }
  
          console.log('TYPED SIGNED:' + JSON.stringify(result.result))
  
          var r = result.result.slice(0, 66)
          var s = '0x' + result.result.slice(66, 130)
          var v = '0x' + result.result.slice(130, 132)
          v = web3.utils.toDecimal(v);
  
          console.log(r)
          console.log(s)
          console.log(v)
  
          // await buyNowMarketplace.methods.listForBuyNow(user.get('ethAddress'), id, parsedPrice.toString(), 0, tokenContractAddress).send({from: user.get('ethAddress')});
  
          await makerSave({
            isOrderAsk: true,
            signer: user.get('ethAddress'),
            collection: collection,
            price: price,
            tokenId: tokenId,
            amount: amount,
            strategy: StrategyStandardSaleForFixedPrice.networks["941"].address,
            currency: currency,
            nonce: nonce,
            startTime: startTime,
            endTime: endTime,
            minPercentageToAsk: "8500",
            params: "0x",
            listing: "buy",
            r,
            s,
            v
          });

          document.getElementById("errorPrice").style.color = "green";
          document.getElementById("errorPrice").innerHTML = "Successful";
          document.getElementById("listing").innerHTML = "Change"
      
        })
      }

    const signMakerOrder = async (collection, price, tokenId, amount, nonce, startTime, endTime, currency, strategy) => {
      const web3 = window.web3;
      const exchangeAddress = PulseVerseExchange.networks["941"].address;
        const msgParams = JSON.stringify({
          domain: {
            name: 'LooksRareExchange',
            version: '1',
            chainId: 941,
            verifyingContract: exchangeAddress,
          },
          message: {
            isOrderAsk: true,
            signer: user.get('ethAddress'),
            collection: collection,
            price: price,
            tokenId: tokenId,
            amount: amount,
            strategy: strategy,
            currency: currency,
            nonce: nonce,
            startTime: startTime,
            endTime: endTime,
            minPercentageToAsk: "8500",
            params: "0x",
          },
          primaryType: 'MakerOrder',
          types: {
            EIP712Domain: [
              { name: 'name', type: 'string' },
              { name: 'version', type: 'string' },
              { name: 'chainId', type: 'uint256' },
              { name: 'verifyingContract', type: 'address' }
            ],
            MakerOrder: [
              { name: 'isOrderAsk', type: 'bool' },
              { name: 'signer', type: 'address' },
              { name: 'collection', type: 'address' },
              { name: 'price', type: 'uint256' },
              { name: 'tokenId', type: 'uint256' },
              { name: 'amount', type: 'uint256' },
              { name: 'strategy', type: 'address' },
              { name: 'currency', type: 'address' },
              { name: 'nonce', type: 'uint256' },
              { name: 'startTime', type: 'uint256' },
              { name: 'endTime', type: 'uint256' },
              { name: 'minPercentageToAsk', type: 'uint256' },
              { name: 'params', type: 'bytes' },
            ],
          }
        });
      
        var from = user.get('ethAddress')
      
        var params = [from, msgParams]
        var method = 'eth_signTypedData_v4'
      
        await web3.currentProvider.sendAsync({
          method,
          params,
          from,
        }, async function (err, result) {
          if (err) {
            document.getElementById("error").innerHTML = err.message;
            console.error(err)
            document.getElementById("list").innerHTML = "Failed";
            return;
          }
  
          if (result.error) {
            document.getElementById("error").innerHTML = result.error.message;
            console.error(result.error)
            document.getElementById("list").innerHTML = "Failed";
            return;
          }
  
          console.log('TYPED SIGNED:' + JSON.stringify(result.result))
  
          var r = result.result.slice(0, 66)
          var s = '0x' + result.result.slice(66, 130)
          var v = '0x' + result.result.slice(130, 132)
          v = web3.utils.toDecimal(v);
  
          console.log(r)
          console.log(s)
          console.log(v)
  
          // await buyNowMarketplace.methods.listForBuyNow(user.get('ethAddress'), id, parsedPrice.toString(), 0, tokenContractAddress).send({from: user.get('ethAddress')});
  
          await makerSave({
            isOrderAsk: true,
            signer: user.get('ethAddress'),
            collection: collection,
            price: price,
            tokenId: tokenId,
            amount: amount,
            strategy: StrategyStandardSaleForFixedPrice.networks["941"].address,
            currency: currency,
            nonce: nonce,
            startTime: startTime,
            endTime: endTime,
            minPercentageToAsk: "8500",
            params: "0x",
            listing: "buy",
            r,
            s,
            v
          });

          document.getElementById("list").innerHTML = "Done";
      
        })
    }

    const handleShow = () =>{
        document.getElementById("tab_opt_1").classList.add("show");
        document.getElementById("tab_opt_1").classList.remove("hide");
  
        document.getElementById("tab_opt_3").classList.add("hide");
        document.getElementById("tab_opt_3").classList.remove("show");
  
        document.getElementById("tab_opt_2").classList.remove("show");
        document.getElementById("tab_opt_2").classList.add("hide");
  
        document.getElementById("btn1").classList.add("active");
        document.getElementById("btn2").classList.remove("active");
        document.getElementById("btn3").classList.remove("active");
        setListingType(0);
    }
     const handleShow1 = ()=>{
        document.getElementById("tab_opt_2").classList.add("show");
        document.getElementById("tab_opt_2").classList.remove("hide");
  
        document.getElementById("tab_opt_1").classList.add("hide");
        document.getElementById("tab_opt_1").classList.remove("show");
  
        document.getElementById("tab_opt_3").classList.remove("show");
        document.getElementById("tab_opt_3").classList.add("hide");
  
        document.getElementById("btn1").classList.remove("active");
        document.getElementById("btn2").classList.add("active");
        document.getElementById("btn3").classList.remove("active");
        setListingType(1);
    }
     const handleShow2 = ()=>{
        document.getElementById("tab_opt_3").classList.add("show");
        document.getElementById("tab_opt_3").classList.remove("hide");
  
        document.getElementById("tab_opt_1").classList.add("hide");
        document.getElementById("tab_opt_1").classList.remove("show");
  
        document.getElementById("tab_opt_2").classList.remove("show");
        document.getElementById("tab_opt_2").classList.add("hide");
  
        document.getElementById("btn1").classList.remove("active");
        document.getElementById("btn2").classList.remove("active");
        document.getElementById("btn3").classList.add("active");
        setListingType(2);
    }

    var followIsRunning = false;

    const follow = async () => {
      if (!followIsRunning) {
        followIsRunning = true;
        if (likeData.length > 0) {
          await likeData[0].save('followers', likeData[0].get('followers')+1);
          let followerList = await likeData[0].get('followerList');
          followerList.push(user.get('ethAddress'))
          await likeData[0].save('followerList', followerList);
          await setFollowed(true);
          await setUnFollowed(false);
          followIsRunning = false;      
        } else {
          let followers = 1;
          let followerList = [user.get('ethAddress')];
          let tokenId = subjectId;
          let contractAddress = subjectAddress;
          let itemType = "token"
          await saveLike({followers, tokenId, contractAddress, followerList, itemType})
          await fetchLike();
          followIsRunning = false;
        }

      }
    }


    var unfollowIsRunning = false;
    const unfollow = async () => {
        if (!unfollowIsRunning) {
          unfollowIsRunning = true;
          if (likeData.length > 0) {
            let followerList = await likeData[0].get('followerList');
            await likeData[0].save('followers', likeData[0].get('followers')-1);
            var removedFollower = followerList.filter(function(value, index, arr){ 
              return value !== user.get('ethAddress');
            });
            
            await likeData[0].save('followerList', removedFollower);
            await setUnFollowed(true);
            await setFollowed(false);
            unfollowIsRunning = false;
          }
        }

    }

    const web3 = window.web3

    const digitalAsset = new web3.eth.Contract(SharedStorefront.abi, SharedStorefront.networks["941"].address);

  const signOut = () => {
    logout()
    if (document.getElementById("wrongnet")) {
      document.getElementById("wrongnet").style.display = "none";
    }
  }

  if (window.ethereum && !listener) {
    window.ethereum.on('chainChanged', function(networkId){
      if (networkId !== "0x3ac") {
        setWrongNetwork(true);
      } else {
        setWrongNetwork(false);      
      }
    })
    setListener(true);
  }

  const buyNowSpecific = async () => {
    document.getElementById("errorBuy").innerHTML = ""
    document.getElementById("buyNow").innerHTML = "Facilitating purchase...";
    let exchange = new web3.eth.Contract(PulseVerseExchange.abi, PulseVerseExchange.networks["941"].address)
    try {

    let tokenContract = new web3.eth.Contract(IERC20.abi, editionToken);
    let weth = new web3.eth.Contract(WETH9.abi, WETH9.networks["941"].address)

    let makerAsk = {
      isOrderAsk: activeListing.get("isOrderAsk"),
      signer: activeListing.get("signer"),
      collection: activeListing.get("collection"),
      price: activeListing.get("price"),
      tokenId: activeListing.get("tokenId"),
      amount: activeListing.get("amount"),
      strategy: activeListing.get("strategy"),
      currency: activeListing.get("currency"),
      nonce: activeListing.get("nonce"),
      startTime: activeListing.get("startTime"),
      endTime: activeListing.get("endTime"),
      minPercentageToAsk: activeListing.get("minPercentageToAsk"),
      params: "0x",
      r: activeListing.get("r"),
      s: activeListing.get("s"),
      v: activeListing.get("v")
    };

    console.log(makerAsk)
    let takerBid = {
      isOrderAsk: false,
      taker: user.get('ethAddress'),
      price: price,
      tokenId: subjectId,
      minPercentageToAsk: "8500",
      params: "0x"
    }

    if (parseInt(balanceTokens["WPLS"]) < makerAskData[0].get("price") && parseInt(balanceTokens["PLS"]) < makerAskData[0].get("price")) {
      throw {
        "message": "Not enough PLS or WPLS to facilitate transaction"
      }
    }

    let listing = activeListing.get('listing')

      if (listing === "buy") {
        
        // Buy Now
        if (editionToken.toLowerCase() === WETH9.networks["941"].address.toLowerCase()) {
          let approvedAmount = await weth.methods.allowance(user.get("ethAddress"), PulseVerseExchange.networks["941"].address).call();
          if (parseInt(approvedAmount) >= parseInt(price)) {
            await exchange.methods.matchAskWithTakerBidUsingETHAndWETH(
              takerBid,
              makerAsk
            ).send({from: user.get('ethAddress'), value: price})
          } else {
            await weth.methods.approve(PulseVerseExchange.networks["941"].address, price).send({from: user.get('ethAddress')})
            await exchange.methods.matchAskWithTakerBidUsingETHAndWETH(
              takerBid,
              makerAsk
            ).send({from: user.get('ethAddress'), value: price})
          }

        } else {
          let approvedAmount = await tokenContract.methods.allowance(user.get("ethAddress"), PulseVerseExchange.networks["941"].address).call();
          if (parseInt(approvedAmount) >= parseInt(price)) {
            await exchange.methods.matchAskWithTakerBid(
              takerBid,
              makerAsk
            ).send({from: user.get('ethAddress')})
          } else {
            await tokenContract.methods.approve(PulseVerseExchange.networks["941"].address, price).send({from: user.get('ethAddress')})
            await exchange.methods.matchAskWithTakerBid(
              takerBid,
              makerAsk
            ).send({from: user.get('ethAddress')})
          }


        }

      }
    document.getElementById("errorBuy").style.color = "green";
    document.getElementById("errorBuy").innerHTML = "Successful";    
    document.getElementById("buyNow").innerHTML = "Checkout";     
    } catch (error) {
      console.error(error)
      document.getElementById("errorBuy").innerHTML = error.message;
      document.getElementById("buyNow").innerHTML = "Checkout";
    }


  }

    const buyNow = async () => {
      document.getElementById("errorBuy").innerHTML = ""
      document.getElementById("buyNow").innerHTML = "Facilitating purchase...";
      let exchange = new web3.eth.Contract(PulseVerseExchange.abi, PulseVerseExchange.networks["941"].address)
      try {

      let tokenContract = new web3.eth.Contract(IERC20.abi, editionToken);
      let weth = new web3.eth.Contract(WETH9.abi, WETH9.networks["941"].address)

      let makerAsk = {
        isOrderAsk: makerAskData[0].get("isOrderAsk"),
        signer: makerAskData[0].get("signer"),
        collection: makerAskData[0].get("collection"),
        price: makerAskData[0].get("price"),
        tokenId: makerAskData[0].get("tokenId"),
        amount: makerAskData[0].get("amount"),
        strategy: makerAskData[0].get("strategy"),
        currency: makerAskData[0].get("currency"),
        nonce: makerAskData[0].get("nonce"),
        startTime: makerAskData[0].get("startTime"),
        endTime: makerAskData[0].get("endTime"),
        minPercentageToAsk: makerAskData[0].get("minPercentageToAsk"),
        params: "0x",
        r: makerAskData[0].get("r"),
        s: makerAskData[0].get("s"),
        v: makerAskData[0].get("v")
      };

      console.log(makerAsk)
      let takerBid = {
        isOrderAsk: false,
        taker: user.get('ethAddress'),
        price: price,
        tokenId: subjectId,
        minPercentageToAsk: "8500",
        params: "0x"
      }

      if (parseInt(balanceTokens["WPLS"]) < makerAskData[0].get("price") && parseInt(balanceTokens["PLS"]) < makerAskData[0].get("price")) {
        throw {
          "message": "Not enough PLS or WPLS to facilitate transaction"
        }
      }

      let listing = makerAskData[0].get('listing')

        if (listing === "buy") {
          
          // Buy Now
          if (editionToken.toLowerCase() === WETH9.networks["941"].address.toLowerCase()) {
            let approvedAmount = await weth.methods.allowance(user.get("ethAddress"), PulseVerseExchange.networks["941"].address).call();
            if (parseInt(approvedAmount) >= parseInt(price)) {
              await exchange.methods.matchAskWithTakerBidUsingETHAndWETH(
                takerBid,
                makerAsk
              ).send({from: user.get('ethAddress'), value: price})
            } else {
              await weth.methods.approve(PulseVerseExchange.networks["941"].address, price).send({from: user.get('ethAddress')})
              await exchange.methods.matchAskWithTakerBidUsingETHAndWETH(
                takerBid,
                makerAsk
              ).send({from: user.get('ethAddress'), value: price})
            }

          } else {
            let approvedAmount = await tokenContract.methods.allowance(user.get("ethAddress"), PulseVerseExchange.networks["941"].address).call();
            if (parseInt(approvedAmount) >= parseInt(price)) {
              await exchange.methods.matchAskWithTakerBid(
                takerBid,
                makerAsk
              ).send({from: user.get('ethAddress')})
            } else {
              await tokenContract.methods.approve(PulseVerseExchange.networks["941"].address, price).send({from: user.get('ethAddress')})
              await exchange.methods.matchAskWithTakerBid(
                takerBid,
                makerAsk
              ).send({from: user.get('ethAddress')})
            }


          }

        }
      document.getElementById("errorBuy").style.color = "green";
      document.getElementById("errorBuy").innerHTML = "Successful";    
      document.getElementById("buyNow").innerHTML = "Checkout";     
      } catch (error) {
        console.error(error)
        document.getElementById("errorBuy").innerHTML = error.message;
        document.getElementById("buyNow").innerHTML = "Checkout";
      }


    }

    const removeFromSale = async () => {
      document.getElementById("remove").innerHTML = "Removing..."
      let exchange = new web3.eth.Contract(PulseVerseExchange.abi, PulseVerseExchange.networks["941"].address)

      let orderNonces = []
      for (let i = 0; i < makerAskDataUser.length; i++) {
        orderNonces.push(makerAskDataUser[i].get("nonce"))
      }

      console.log(orderNonces)

      try {
        await exchange.methods.cancelMultipleMakerOrders(orderNonces).send({from: user.get('ethAddress')})

        document.getElementById("errorRemove").style.color = "green";
        document.getElementById("errorRemove").innerHTML = "Successful";
        document.getElementById("remove").innerHTML = "Remove"
      } catch (error) {
        console.error(error)
        document.getElementById("errorRemove").innerHTML = error.message;
        document.getElementById("remove").innerHTML = "Remove"
      }


    }

    const acceptBid = async () => {
      document.getElementById("accept").innerHTML = "Accepting offer...";
      let exchange = new web3.eth.Contract(PulseVerseExchange.abi, PulseVerseExchange.networks["941"].address)
      try {

      let tokenContract = new web3.eth.Contract(IERC20.abi, offer.get("currency"));

      let makerBid = {
        isOrderAsk: offer.get("isOrderAsk"),
        signer: offer.get("signer"),
        collection: offer.get("collection"),
        price: offer.get("price"),
        tokenId: offer.get("tokenId"),
        amount: offer.get("amount"),
        strategy: offer.get("strategy"),
        currency: offer.get("currency"),
        nonce: offer.get("nonce"),
        startTime: offer.get("startTime"),
        endTime: offer.get("endTime"),
        minPercentageToAsk: offer.get("minPercentageToAsk"),
        params: "0x",
        r: offer.get("r"),
        s: offer.get("s"),
        v: offer.get("v")
      };

      console.log(makerBid)
      let takerAsk = {
        isOrderAsk: true,
        taker: user.get('ethAddress'),
        price: offer.get("price"),
        tokenId: subjectId,
        minPercentageToAsk: "8500",
        params: "0x"
      }

      let listing = makerAskData[0].get('listing')

        if (listing === "buy") {
          
          // Buy Now
            let approvedAmount = await tokenContract.methods.allowance(user.get("ethAddress"), PulseVerseExchange.networks["941"].address).call();
            if (parseInt(approvedAmount) >= parseInt(offer.get("price"))) {
              await exchange.methods.matchBidWithTakerAsk(
                takerAsk,
                makerBid
              ).send({from: user.get('ethAddress')})
            } else {
              await tokenContract.methods.approve(PulseVerseExchange.networks["941"].address, offer.get("price")).send({from: user.get('ethAddress')})
              await exchange.methods.matchBidWithTakerAsk(
                takerAsk,
                makerBid
              ).send({from: user.get('ethAddress')})
            }

        }
      document.getElementById("errorAccept").style.color = "green";
      document.getElementById("errorAccept").innerHTML = "Successful";    
      document.getElementById("accept").innerHTML = "Accept";     
      } catch (error) {
        console.error(error)
        document.getElementById("errorAccept").innerHTML = error.message;
        document.getElementById("accept").innerHTML = "Accept";
      }

    }

    const bid = async () => {
      document.getElementById("errorBid").innerHTML = ""
      document.getElementById("bid").innerHTML = "Making offer...";

      try {
        const blockNumber = await web3.eth.getBlockNumber();
        console.log(blockNumber)
        const timestamp = (await web3.eth.getBlock(blockNumber)).timestamp
        const secondsValid = parseInt(validity.split(" ")[0]) * 24 * 60 * 60;
        console.log(timestamp)
        console.log(secondsValid)
        let tokenContract = new web3.eth.Contract(IERC20.abi, nameTokensBid[bidToken][0])
        let balance = await tokenContract.methods.balanceOf(user.get('ethAddress')).call();
        let offer = (document.getElementById("bid_offer").value*10**nameTokensBid[bidToken][1]).toString();
        let tokenId = subjectId;
        console.log(makerAskDataCreated.length + makerBidDataCreated.length)

        if (parseInt(balance) < parseInt(offer)) {
          throw {
            "message": "Token balance not sufficient to make offer."
          }
        }

        if (document.getElementById("bid_offer").value === "") {
          throw {
            "message": "Must specify offer value."
          }     
        }

        let approvedAmount = await tokenContract.methods.allowance(user.get("ethAddress"), PulseVerseExchange.networks["941"].address).call();
        if (parseInt(approvedAmount) >= parseInt(offer)) {
          await signMakerBid(subjectAddress, offer.toString(), tokenId.toString(), "1", (makerAskDataCreated.length + makerBidDataCreated.length).toString(), "0", (timestamp+secondsValid).toString(), nameTokensBid[bidToken][0], StrategyStandardSaleForFixedPrice.networks["941"].address);
        } else {
          await tokenContract.methods.approve(PulseVerseExchange.networks["941"].address, offer).send({from: user.get('ethAddress')})
          await signMakerBid(subjectAddress, offer.toString(), tokenId.toString(), "1", (makerAskDataCreated.length + makerBidDataCreated.length).toString(), "0", (timestamp+secondsValid).toString(), nameTokensBid[bidToken][0], StrategyStandardSaleForFixedPrice.networks["941"].address);
        }
      } catch (error) {
        console.error(error)
        document.getElementById("errorBid").innerHTML = error.message;
        document.getElementById("bid").innerHTML = "Make Offer";
      }


      // placeBidOnReserveAuction
      // placeEditionBid
    }
    
    const cancelBid = async () => {
      document.getElementById("cancel").innerHTML = "Cancelling Bid..."
      let exchange = new web3.eth.Contract(PulseVerseExchange.abi, PulseVerseExchange.networks["941"].address)

      let orderNonces = []
      for (let i = 0; i < makerBidDataUser.length; i++) {
        orderNonces.push(makerBidDataUser[i].get("nonce"))
      }

      console.log(orderNonces)

      try {
        await exchange.methods.cancelMultipleMakerOrders(orderNonces).send({from: user.get('ethAddress')})

        document.getElementById("errorCancel").style.color = "green";
        document.getElementById("errorCancel").innerHTML = "Successful";
        document.getElementById("cancel").innerHTML = "Cancel"
      } catch (error) {
        console.error(error)
        document.getElementById("errorCancel").innerHTML = error.message;
        document.getElementById("cancel").innerHTML = "Cancel"
      }

    }

    const changeListingPrice = async () => {
      document.getElementById("listing").innerHTML = "Changing listing price..."
      let tokenId = subjectId;
      let tokenContractAddress = editionToken.toLowerCase();
      let amount = makerAskData[0].get("amount")
      try {
          if (saleKind === "buy") {
            let newprice = document.getElementById("newprice").value;
            let parsedPrice = newprice * 10 ** tokens[tokenContractAddress][1];
            await signMakerOrderPriceChange(subjectAddress, parsedPrice.toString(), tokenId.toString(), amount.toString(), makerAskDataCreated.length.toString(), "0", "8329132183", tokenContractAddress, StrategyStandardSaleForFixedPrice.networks["941"].address);
            // Buy Now
          } else if (saleKind === "reserve") {
            // Reserve Auction
          }

      } catch (error) {
        console.error(error)
        document.getElementById("errorPrice").innerHTML = error.message
        document.getElementById("listing").innerHTML = "Change"
      }
  

    }

const putOnSale = async (setWalletInit, setOpenSteps) => {
    
    setOpenSteps(true)
    let tokenId = subjectId;
    let tokenContractAddress = nameTokens[token][0];
    try {
        let contract = new web3.eth.Contract(IERC165.abi, subjectAddress);
        let check;
        let isERC721 = await contract.methods.supportsInterface(INTERFACE_ID_ERC721).call();
        let isERC1155 = await contract.methods.supportsInterface(INTERFACE_ID_ERC1155).call();

        if (isERC721) {
          let approvedForAll;
          approvedForAll = await isApprovedForAllERC721(user);
          if (!approvedForAll) {
              await approveForAllERC721(user);
          }
  
          check = await isApprovedForAllERC721(user);
          setWalletInit(check);
        } else if (isERC1155) {
          let approvedForAll;
          approvedForAll = await isApprovedForAllERC1155(user);
          if (!approvedForAll) {
              await approveForAllERC1155(user);
          }
  
          check = await isApprovedForAllERC1155(user);
          setWalletInit(check);     
        }



        document.getElementById("list").innerHTML = "In Progress...";

        if (listingType === 0) {
            const blockNumber = await web3.eth.getBlockNumber();
            console.log(blockNumber)
            const timestamp = (await web3.eth.getBlock(blockNumber)).timestamp
            const secondsValid = parseInt(validity.split(" ")[0]) * 24 * 60 * 60;
            console.log(timestamp)
            console.log(secondsValid)
            let price = document.getElementById("item_price").value;
            let amount = editions;
            if (document.getElementById("item_amount").value) {
              amount = document.getElementById("item_amount").value;
            }
            let parsedPrice = price * 10 ** tokens[tokenContractAddress][1];
            console.log(web3.utils.toWei(price, "ether"))
            // buy Now
            await signMakerOrder(subjectAddress, parsedPrice.toString(), tokenId.toString(), amount.toString(), (makerAskDataCreated.length + makerBidDataCreated.length).toString(), "0", (timestamp+secondsValid).toString(), tokenContractAddress, StrategyStandardSaleForFixedPrice.networks["941"].address);
          } else if (listingType === 1) {
            // Reserve Auction
          }

    } catch(error) {
    document.getElementById("error").innerHTML = error.message;
    console.error(error)
    document.getElementById("list").innerHTML = "Failed";
  }   

}

    const handleBtnClick0 = () => {
        setOpenMenu0(!openMenu0);
        setOpenMenu(false);
        setOpenMenu1(false);
        setOpenMenu2(false);
         document.getElementById("Mainbtn0").classList.add("active");
        document.getElementById("Mainbtn").classList.remove("active");
        document.getElementById("Mainbtn1").classList.remove("active");
        if (isERC1155) {
          document.getElementById("Mainbtn2").classList.remove("active");
        }

    };

const handleBtnClick = () => {
  setOpenMenu(!openMenu);
  setOpenMenu1(false);
    setOpenMenu0(false);
    setOpenMenu2(false);
  document.getElementById("Mainbtn").classList.add("active");
  document.getElementById("Mainbtn1").classList.remove("active");
    document.getElementById("Mainbtn0").classList.remove("active");
    if (isERC1155) {
      document.getElementById("Mainbtn2").classList.remove("active");
    }
};
const handleBtnClick1 = () => {
  setOpenMenu1(!openMenu1);
  setOpenMenu(false);
  setOpenMenu0(false);
  setOpenMenu2(false);
  document.getElementById("Mainbtn1").classList.add("active");
  document.getElementById("Mainbtn").classList.remove("active");
  document.getElementById("Mainbtn0").classList.remove("active");
  if (isERC1155) {
    document.getElementById("Mainbtn2").classList.remove("active");
  }
};

const handleBtnClick2 = () => {
  setOpenMenu2(!openMenu2);
  setOpenMenu(false);
  setOpenMenu0(false);
  setOpenMenu1(false);
  if (isERC1155) {
    document.getElementById("Mainbtn2").classList.add("active");
  }

  document.getElementById("Mainbtn").classList.remove("active");
  document.getElementById("Mainbtn0").classList.remove("active");
  document.getElementById("Mainbtn1").classList.remove("active");
};

const closePop = (e) => {
  console.log(e.target)
  if (e.target.id !== "drop") {
    setDropDownTrue(false);
  }

}

const copyLink = (link) => {
  window.navigator.clipboard.writeText(link)
  setLinkCopied(true);
  setTimeout(function () {
    setLinkCopied(false);
  }, 2000)
}

    return (
    
      <div>
      <GlobalStyles/>
          <section className='container' style={{paddingTop: "50px"}} onClick={closePop}>
              <div className='row mt-md-5 pt-md-4'>
                  {nftImage ? <div className="col-md-6 text-center" id="nft-wrap" >
                        {nftExtension.split("/")[0] === "image" ? <p><img src={nftImage} className="skeleton img-fluid img-rounded mb-sm-30 vertical-center" alt=""/></p> : null}
                        {nftExtension.split("/")[0] === "video" || nftExtension.split("/")[0] === "audio" ? <video src={nftImage} preload="none" style={{"height": "450px"}} poster={nftPreview} className="img-fluid img-rounded mb-sm-30 loading vertical-center" controls controlsList="nodownload">
                        Your browser does not support the video tag.
                        </video> : null}

                  </div> : <div className="col-md-6 text-center" id="nft-wrap" >
                      <img src="../img/loader.gif" className="image-load" alt=""/>
                    </div>}

                  <div className="col-md-6" id="nft-info">
                      <div className="item_info">
                      {isAuction && (isAuction === "before" ? <><span>Auction starts in</span> 
                                <div className="de_countdown">
                                  <Clock deadline={deadline} />
                                </div></> : (isAuction === "during" ? <><span>Auction ends in</span> 
                                <div className="de_countdown">
                                  <Clock deadline={deadline} />
                                </div></> : <><span>Auction ended</span></>))}
                          <div style={{display: "flex"}}>
                              <h2>{nftName && nftCollection && (nftName.length <= 24 ? nftName : `${nftName.slice(0, 24)}...`)}</h2>  
                              <p style={{marginLeft: "5px"}}>1/{editions}</p>
                            </div>
                            <div className="price-modal" style={{padding: "10px", paddingBottom: onSaleType !== "Unlisted" ? "20px" : "10px"}}>
                              {onSaleType !== "Unlisted" && <p>Price</p>}
                              {onSaleType !== "Unlisted" && <img width="25" src={`${tokenToImage[editionToken.toLowerCase()]}`} alt=""></img>}
                              &nbsp;<span style={{"font-weight": "bold"}}>
                                  {onSaleType}
                              </span>
                            </div>
                          {makerBidDataByPrice && <div className="price-modal" style={{padding: "10px"}}>
                          {makerBidDataByPrice[0] ? <span style={{"font-weight": "bold"}}>
                              Top Offer <img width="25" src={`${tokenToImage[makerBidDataByPrice[0].get("currency").toLowerCase()]}`} alt=""></img>&nbsp;{makerBidDataByPrice[0].get("price")/10**tokensBid[makerBidDataByPrice[0].get("currency").toLowerCase()][1]} {tokensBid[makerBidDataByPrice[0].get("currency").toLowerCase()][0]} {!isOrderValid(makerBidDataByPrice[0]) && <><br/><small>Expired</small></>}
                          </span> : <span style={{"font-weight": "bold"}}>No offers yet.</span> }
                          </div>}

                          <div className="spacer-10"></div>
                          <div class="dropdowninfo" style={{"float":"right"}}>
                              <div className="item_info_counts">
                                  {(user && !followerList.includes(user.get('ethAddress'))) || unfollowed ? <button  style={{borderRadius: "15px"}} onClick={async () => await follow()}  className="item_info_like inter"><i className="fa fa-heart"></i>{followers}</button> : <></>}
                                  {(user && followerList.includes(user.get('ethAddress'))) || followed ? <button  style={{borderRadius: "15px"}} onClick={async () => await unfollow()} className="item_info_like inter"><i style={{color: "pink"}} className="fa fa-heart"></i>{followers}</button> : <></>}
                              </div>
                                {user && nftOwner && user.get('ethAddress') === nftOwner.toLowerCase() ? <button id="drop" onClick={() => setDropDownTrue(!dropDownTrue)} type="button" data-marker="root/appPage/collection:sidebar/header/actions/trigger" className="more"><svg viewBox="0 0 14 4" id="drop" fill="none" width="16" height="16" xlmns="http://www.w3.org/2000/svg" class="sc-bdvvtL sc-hKwDye esgSbr"><path id="drop" fill-rule="evenodd" clip-rule="evenodd" d="M3.5 2C3.5 2.82843 2.82843 3.5 2 3.5C1.17157 3.5 0.5 2.82843 0.5 2C0.5 1.17157 1.17157 0.5 2 0.5C2.82843 0.5 3.5 1.17157 3.5 2ZM8.5 2C8.5 2.82843 7.82843 3.5 7 3.5C6.17157 3.5 5.5 2.82843 5.5 2C5.5 1.17157 6.17157 0.5 7 0.5C7.82843 0.5 8.5 1.17157 8.5 2ZM11.999 3.5C12.8274 3.5 13.499 2.82843 13.499 2C13.499 1.17157 12.8274 0.5 11.999 0.5C11.1706 0.5 10.499 1.17157 10.499 2C10.499 2.82843 11.1706 3.5 11.999 3.5Z" fill="currentColor"></path></svg></button> : <button type="button" onClick={() => copyLink(window.location.href)} class="more sc-bdvvtL sc-gKclnd sc-iCfMLu sc-cidDSM sc-caiLqq kMXwoE eehqFj hkkKrN cwSyIc"><svg id="drop" viewBox="0 0 15 16" fill="none" width="16" height="16" xlmns="http://www.w3.org/2000/svg" class="sc-bdvvtL sc-hKwDye esgSbr"><path id="drop" fill-rule="evenodd" clip-rule="evenodd" d="M11.7086 5.2928L7.00146 0.585693L2.29436 5.2928C1.90383 5.68332 1.90383 6.31649 2.29436 6.70701C2.68488 7.09754 3.31805 7.09754 3.70857 6.70701L6.00146 4.41412V11C6.00146 11.5523 6.44918 12 7.00146 12C7.55375 12 8.00146 11.5523 8.00146 11V4.41412L10.2944 6.70701C10.6849 7.09754 11.318 7.09754 11.7086 6.70701C12.0991 6.31649 12.0991 5.68332 11.7086 5.2928ZM1.00146 10C1.55375 10 2.00146 10.4477 2.00146 11V14H12.0015V11C12.0015 10.4477 12.4492 10 13.0015 10C13.5538 10 14.0015 10.4477 14.0015 11V15C14.0015 15.5523 13.5538 16 13.0015 16H1.00146C0.44918 16 0.00146484 15.5523 0.00146484 15V11C0.00146484 10.4477 0.44918 10 1.00146 10Z" fill="currentColor"></path></svg></button>}
                                {linkCopied && <p>Link copied!</p>}
                                    {isAuthenticated && (user && nftOwner && user.get('ethAddress') === nftOwner.toLowerCase()) && dropDownTrue && <div class="dropdown-contentinfo" style={{"top": "30px"}}>
                                      <SignedDrop 
                                        transferNFT={subjectAddress === SharedStorefront.networks["941"].address && prc721Balance.nft !== "0x0000000000000000000000000000000000000000" && prc721Balance.nft ? () => setTransfer721(true) : null}
                                        composePRC20={subjectAddress === SharedStorefront.networks["941"].address ? () => setComposePRC20(true) : null} 
                                        transfer={subjectAddress === SharedStorefront.networks["941"].address && prc20Balances.length > 0 ? () => setTransfer20(true) : null} 
                                        composeNFT={subjectAddress === SharedStorefront.networks["941"].address ? () => setComposeNFT(true) : null} 
                                        notOffer={saleKind === "offers" ? false : true}
                                        changePrice={makerAskData && makerAskData[0] ? () => setChangeListing(true) : null}
                                      />
                                </div>}
                          </div>
                          <p style={{"white-space": "pre-line", "vertical-align": "bottom"}}>{nftDescription && nftDescription}</p>
                          <NftAdditional editionId={editionId} user={user} nftOwner={nftOwner}/>
                          {prc20Balances.length > 0 && <span style={{"font-weight": "bold"}}>PRC20s available:</span>}
                          <ul>
                            <span style={{"font-weight": "bold"}}>
                              {prc20Balances.map((prc20, index) => {
                                return(

                                  <li>
                                    <span style={{"font-weight": "normal"}}>Token Name: {prc20.name}</span>
                                    &nbsp;<span style={{"font-weight": "bold"}}>|</span>&nbsp;<span style={{"font-weight": "normal"}}>Amount: {prc20.balance / 10 ** prc20.decimals}</span>
                                    &nbsp;|&nbsp;<span style={{"font-weight": "normal"}}>Contract Address: {prc20.address.substring(0, 10)}...{prc20.address.substring(35, 42)}</span>
                                  </li>

                                )
                              })}
                            </span>
                          </ul>
                          {prc721Balance.nft !== "0x0000000000000000000000000000000000000000" && prc721Balance.nft && <><span style={{"font-weight": "bold"}}>Nested NFT available: </span><span style={{"font-weight": "bold"}}>
                              <span style={{"font-weight": "normal"}}>Collection Name: {prc721Balance.name}</span>
                              &nbsp;<span style={{"font-weight": "bold"}}>|</span>&nbsp;<span style={{"font-weight": "normal"}}>Token ID: {prc721Balance.tokenId}</span>
                              &nbsp;|&nbsp;<span style={{"font-weight": "normal"}}>Contract Address: {prc721Balance.nft.substring(0, 10)}...{prc721Balance.nft.substring(35, 42)}</span>&nbsp;|
                              &nbsp;<a href={`${window.location.origin}/collection:${prc721Balance.nft}:${prc721Balance.tokenId}`}><span style={{"font-weight": "normal"}}>View Item</span></a>
                            </span></>}
                          <div className="d-flex flex-row">
                              <div className="mr40">
                                  <h6 style={{"font-size": "15px"}}>Creator</h6>
                                  <div className="item_author">                                    
                                      <div className="author_list_pp">
                                          <Link to={`/user/${nftCreator}`}>
                                              {nftCreator && <UserImage address={nftCreator}/>}
                                          </Link>
                                          {nftCreator && <UserName address={nftCreator} length={false}/>}
                                      </div>                                    
                                  </div>
                              </div>
                              <div className="mr40">
                                  <h6 style={{marginLeft: "80px", "font-size": "15px"}}>Collection</h6>
                                  <div className="item_author">                                    
                                      <div className="author_list_pp" style={{marginLeft: "100px"}}>
                                          <Link to={`/collection/${subjectAddress}`}>
                                            <UserCollection smaller={true} address={subjectAddress}/>
                                          </Link>
                                          <span>{nftCollection}</span>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div className="spacer-40"></div>

                          <div className="de_tab">

                          <ul className="de_nav">
                              <li id='Mainbtn0' className="active"><span onClick={handleBtnClick0}>Details</span></li>
                              <li id='Mainbtn' ><span onClick={handleBtnClick}>Offers</span></li>
                              <li id='Mainbtn1' className=''><span onClick={handleBtnClick1}>History</span></li>
                              {isERC1155 && <li id='Mainbtn2' className=''><span onClick={handleBtnClick2}>Listings</span></li>}
                          </ul>
                                      
                          <div className="de_tab_content">
                              {openMenu0  && (  
                              <div className="tab-1 onStep fadeIn">
                                  <div className="d-block">
                                      <div className="mr40">
                                          <h6 style={{"font-size": "15px"}}>Owner</h6>
                                          <div className="item_author">                                    
                                              <div className="author_list_pp">
                                              <Link to={`/user/${nftOwner}`}>
                                                {nftOwner && <UserImage address={nftOwner}/>}
                                              </Link>
                                              {nftOwner && <UserName address={nftOwner} length={true}/>}
                                              </div>                                    
                                          </div>
                                      </div>


                                      {nftAttributes && nftAttributes.length > 0 && <div className="row mt-5 mb-5">
                                          {nftAttributes && nftAttributes.map((attribute, index) => {
                                              return(<div className="col-lg-4 col-md-6 col-sm-6">
                                                  <div className="nft_attr">
                                                      <h5>{attribute["trait_type"]}</h5>
                                                      <h4>{attribute["value"]}</h4>
                                                  </div>
                                              </div>);
                                          })}                                                                            
                                      </div>}

                                  </div>
                              </div>
                              )}

                              {openMenu && (!editionBidsIsLoading ? (  
                              <div className="tab onStep fadeIn">
                                  {makerBidData && makerBidData.map((bid, index) => {
                                    return (
                                      <div className="p_list" key={index}>
                                               <div className="author_list_pp">
                                                   <span>
                                                   <UserImage address={bid.get('signer')}/>
                                                   </span>
                                               </div>                                   
                                          <div className="p_list_info">
                                              <span>Offer placed <img width="20" src={`${tokenToImage[bid.get('currency').toLowerCase()]}`} alt=""/>&nbsp;<b>{bid.get('price') / 10 ** tokens[bid.get('currency').toLowerCase()][1]} {tokensBid[bid.get('currency').toLowerCase()][0]}</b></span>
                                              <span>by <b><UserName address={bid.get('signer')}/></b> at {new Date(bid.get('createdAt')).toString()}</span>
                                              {user && (nftOwner === user.get('ethAddress') || ownsTokens) && <button className="btn-main mt-2 p-2" onClick={() => {setActiveOffer(bid); setOpenAcceptBid(true)}}>Accept Offer</button>}
                                          </div>
                                      </div>
                                    );
                                  })}
                              </div>
                              ) : <img src="../img/loader.gif" className="image-center" alt=""/>)}

                              {openMenu1 && (!historyIsLoading ? (
                              <div className="tab-2 onStep fadeIn">

                                       {transferEvents && transferEvents.map((value, index) => {
                                          console.log(value)
                                          return(
                                           <div className="p_list">
                                               <div className="author_list_pp">
                                                   <span>
                                                        {value.returnValues.to && <UserImage address={value.returnValues.to.toLowerCase()}/>}
                                                        {value.returnValues._seller && !value.returnValues._buyer && !value.returnValues._bidder && !value.returnValues._winner && <UserImage address={value.returnValues._seller.toLowerCase()}/>}
                                                        {value.returnValues._buyer && <UserImage address={value.returnValues._buyer.toLowerCase()}/>}
                                                        {value.returnValues._currentOwner && !value.returnValues._buyer && <UserImage address={value.returnValues._currentOwner.toLowerCase()}/>}
                                                        {value.returnValues._bidder && <UserImage address={value.returnValues._bidder.toLowerCase()}/>}
                                                        {value.returnValues._winner && <UserImage address={value.returnValues._winner.toLowerCase()}/>}
                                                   </span>
                                               </div>                                    
                                               <div className="p_list_info">
                                                   {["TransferSingle", "Transfer"].includes(value.event) && <><span>Created <a href={`https://scan.v2.testnet.pulsechain.com/tx/${value.transactionHash}`} target="_blank" rel="noreferrer" title="View transaction on blockchain explorer" class="sc-bdvvtL sc-hGPBjI sc-dlVxhl sc-kfPuZi ieSfBq eIynwo gUFucs"><svg viewBox="0 0 12 12" fill="none" width="16" height="16" xlmns="http://www.w3.org/2000/svg" class="sc-bdvvtL sc-hKwDye vZMA-D"><path d="M3.69233 0.92308V0.92308C2.16292 0.92308 0.923096 2.16291 0.923096 3.69231V8.07693C0.923096 9.73378 2.26624 11.0769 3.9231 11.0769H8.30771C9.83712 11.0769 11.0769 9.8371 11.0769 8.3077V8.3077" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M6.46143 0.92308H11.0768V5.53847" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M11.077 0.92308L5.53857 6.46154" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path></svg></a></span></>}
                                                   {["TransferSingle", "Transfer"].includes(value.event) && <span>by <b>{value.returnValues.to.substring(0, 15)}...</b> at {new Date(value.timestamp*1000).toString()}</span>}

                                                   {["ListedForBuyNowToken", "ListedForReserveAuctionToken", "TokenAcceptingOffer"].includes(value.event) && <><span>Listed <a href={`https://scan.v2.testnet.pulsechain.com/tx/${value.transactionHash}`} target="_blank" rel="noreferrer" title="View transaction on blockchain explorer" class="sc-bdvvtL sc-hGPBjI sc-dlVxhl sc-kfPuZi ieSfBq eIynwo gUFucs"><svg viewBox="0 0 12 12" fill="none" width="16" height="16" xlmns="http://www.w3.org/2000/svg" class="sc-bdvvtL sc-hKwDye vZMA-D"><path d="M3.69233 0.92308V0.92308C2.16292 0.92308 0.923096 2.16291 0.923096 3.69231V8.07693C0.923096 9.73378 2.26624 11.0769 3.9231 11.0769H8.30771C9.83712 11.0769 11.0769 9.8371 11.0769 8.3077V8.3077" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M6.46143 0.92308H11.0768V5.53847" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M11.077 0.92308L5.53857 6.46154" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path></svg></a></span></>}
                                                   {["ListedForBuyNowToken", "ListedForReserveAuctionToken", "TokenAcceptingOffer"].includes(value.event) && <span>by <b>{value.returnValues._currentOwner ? value.returnValues._currentOwner.substring(0, 15) : value.returnValues._seller.substring(0, 15)}...</b> at {new Date(value.timestamp*1000).toString()}</span>}

                                                   {["BuyNowDeListedToken", "ReserveAuctionRemovedToken"].includes(value.event) && <><span>Delisted <a href={`https://scan.v2.testnet.pulsechain.com/tx/${value.transactionHash}`} target="_blank" rel="noreferrer" title="View transaction on blockchain explorer" class="sc-bdvvtL sc-hGPBjI sc-dlVxhl sc-kfPuZi ieSfBq eIynwo gUFucs"><svg viewBox="0 0 12 12" fill="none" width="16" height="16" xlmns="http://www.w3.org/2000/svg" class="sc-bdvvtL sc-hKwDye vZMA-D"><path d="M3.69233 0.92308V0.92308C2.16292 0.92308 0.923096 2.16291 0.923096 3.69231V8.07693C0.923096 9.73378 2.26624 11.0769 3.9231 11.0769H8.30771C9.83712 11.0769 11.0769 9.8371 11.0769 8.3077V8.3077" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M6.46143 0.92308H11.0768V5.53847" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M11.077 0.92308L5.53857 6.46154" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path></svg></a></span></>}
                                                   {["BuyNowDeListedToken", "ReserveAuctionRemovedToken"].includes(value.event) && <span>by <b>{value.returnValues._seller.substring(0, 15)}...</b> at {new Date(value.timestamp*1000).toString()}</span>}

                                                   {["BuyNowPurchasedToken"].includes(value.event) && <><span>Sold <a href={`https://scan.v2.testnet.pulsechain.com/tx/${value.transactionHash}`} target="_blank" rel="noreferrer" title="View transaction on blockchain explorer" class="sc-bdvvtL sc-hGPBjI sc-dlVxhl sc-kfPuZi ieSfBq eIynwo gUFucs"><svg viewBox="0 0 12 12" fill="none" width="16" height="16" xlmns="http://www.w3.org/2000/svg" class="sc-bdvvtL sc-hKwDye vZMA-D"><path d="M3.69233 0.92308V0.92308C2.16292 0.92308 0.923096 2.16291 0.923096 3.69231V8.07693C0.923096 9.73378 2.26624 11.0769 3.9231 11.0769H8.30771C9.83712 11.0769 11.0769 9.8371 11.0769 8.3077V8.3077" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M6.46143 0.92308H11.0768V5.53847" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M11.077 0.92308L5.53857 6.46154" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path></svg></a></span></>}
                                                   {["BuyNowPurchasedToken"].includes(value.event) && <span>by <b>{value.returnValues._buyer.substring(0, 15)}...</b> at {new Date(value.timestamp*1000).toString()}</span>}

                                                   {["TokenBidAccepted"].includes(value.event) && <><span>Sold <a href={`https://scan.v2.testnet.pulsechain.com/tx/${value.transactionHash}`} target="_blank" rel="noreferrer" title="View transaction on blockchain explorer" class="sc-bdvvtL sc-hGPBjI sc-dlVxhl sc-kfPuZi ieSfBq eIynwo gUFucs"><svg viewBox="0 0 12 12" fill="none" width="16" height="16" xlmns="http://www.w3.org/2000/svg" class="sc-bdvvtL sc-hKwDye vZMA-D"><path d="M3.69233 0.92308V0.92308C2.16292 0.92308 0.923096 2.16291 0.923096 3.69231V8.07693C0.923096 9.73378 2.26624 11.0769 3.9231 11.0769H8.30771C9.83712 11.0769 11.0769 9.8371 11.0769 8.3077V8.3077" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M6.46143 0.92308H11.0768V5.53847" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M11.077 0.92308L5.53857 6.46154" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path></svg></a></span></>}
                                                   {["TokenBidAccepted"].includes(value.event) && <span>by <b>{value.returnValues._bidder.substring(0, 15)}...</b> at {new Date(value.timestamp*1000).toString()}</span>}

                                                   {["ReserveAuctionResultedToken"].includes(value.event) && <><span>Sold <a href={`https://scan.v2.testnet.pulsechain.com/tx/${value.transactionHash}`} target="_blank" rel="noreferrer" title="View transaction on blockchain explorer" class="sc-bdvvtL sc-hGPBjI sc-dlVxhl sc-kfPuZi ieSfBq eIynwo gUFucs"><svg viewBox="0 0 12 12" fill="none" width="16" height="16" xlmns="http://www.w3.org/2000/svg" class="sc-bdvvtL sc-hKwDye vZMA-D"><path d="M3.69233 0.92308V0.92308C2.16292 0.92308 0.923096 2.16291 0.923096 3.69231V8.07693C0.923096 9.73378 2.26624 11.0769 3.9231 11.0769H8.30771C9.83712 11.0769 11.0769 9.8371 11.0769 8.3077V8.3077" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M6.46143 0.92308H11.0768V5.53847" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M11.077 0.92308L5.53857 6.46154" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path></svg></a></span></>}
                                                   {["ReserveAuctionResultedToken"].includes(value.event) && <span>by <b>{value.returnValues._winner.substring(0, 15)}...</b> at {new Date(value.timestamp*1000).toString()}</span>}
                                               </div>
                                           </div>
                                       );
                                       })}  
                              </div>
                              ) : <img src="../img/loader.gif" className="image-center" alt=""/>)}


                              {isERC1155 && openMenu2 && <div className="tab onStep fadeIn">
                                  {makerAskData && makerAskData.map((bid, index) => {
                                    return (
                                      <div className="p_list" key={index}>
                                               <div className="author_list_pp">
                                                   <span>
                                                    <UserImage address={bid.get('signer')}/>
                                                   </span>
                                               </div>                                   
                                          <div className="p_list_info">
                                              <span>Token listed <img width="20" src={`${tokenToImage[bid.get('currency').toLowerCase()]}`} alt=""/>&nbsp;<b>{bid.get('price') / 10 ** tokens[bid.get('currency').toLowerCase()][1]} {tokens[bid.get('currency').toLowerCase()][0]}</b></span>
                                              <span>by <b><UserName address={bid.get('signer')}/></b> at {new Date(bid.get('createdAt')).toString()}</span>
                                              {user && (nftOwner !== user.get('ethAddress') && !ownsTokens) && <button className="btn-main mt-2 p-2" onClick={() => {setActiveListing(bid); setOpenBuySpecific(true)}}>Buy Now</button>}
                                          </div>
                                      </div>
                                    );
                                  })}
                              </div>}



                              {/* button for checkout */}
                              {makerAskDataUser && makerAskData && makerBidDataUser && (user ? <ItemInteraction 
                                user={user} 
                                nftOwner={nftOwner} 
                                ownsTokens={ownsTokens} 
                                makerAskDataUser={makerAskDataUser} 
                                makerBidDataUser={makerBidDataUser}
                                makerAskData={makerAskData}
                                setOpenCheckout={setOpenCheckout}
                                setChangeListing={setChangeListing}
                                setOpenResult={setOpenResult}
                                setOpenCheckoutOnSale={setOpenCheckoutOnSale}
                                setTransfer={setTransfer}
                                setOpenCheckoutbid={setOpenCheckoutbid}
                                setOpenBuy={setOpenBuy}
                                setOpenCancelBid={setOpenCancelBid}
                                /> : 
                                           <NavLink to="/wallet">
                                            <button style={{width: "100%"}} className='btn-secondary lead mb-5 mt-4'>Sign In</button>
                                             </NavLink>)}
                              

                          </div>     
                      </div>          
                  </div>
              </div>
          </div>
      </section>
      {openBuy &&  <div className='checkoutNft'>
          <div className='maincheckout'>
          <button className='btn-close' onClick={() => setOpenBuy(false)}>x</button>
              <div className='heading'>
                  <h3>Checkout</h3>
              </div>
            <p>You are about to purchase a <span className="bold">{nftName}</span> 
            <span className="bold"> from <UserName address={nftOwner} length={true}/></span></p>
              <div className='heading mt-3'>
                  <p>Your balance</p>
                  <div className='subtotal'>
                  {balanceTokens[tokens[editionToken.toLowerCase()][0]] / 10 ** tokens[editionToken.toLowerCase()][1]} {tokens[editionToken.toLowerCase()][0]}
                  </div>
              </div>
              {tokens[editionToken.toLowerCase()][0] === "PLS" && <div className='heading'>
                  <p>Your balance (WPLS)</p>
                  <div className='subtotal'>
                  {balanceTokens["WPLS"] / 10 ** 18} WPLS
                  </div>
              </div>}
            <div className='heading'>
              <p>Total</p>
              <div className='subtotal'>
              {price / 10 ** tokens[editionToken.toLowerCase()][1]} {tokens[editionToken.toLowerCase()][0]}
              </div>
            </div>
            <button className="btn-main" style={{width: "75%"}} onClick={() => setConvert(true)}>Convert PLS/WPLS</button>
              <div className="spacer-10"></div>
              <button onClick={buyNow} id="buyNow" className='btn-main lead mb-5'>Checkout</button>
              <p id="errorBuy" style={{color: "red"}}></p>
          </div>
          </div>
        }
        {openBuySpecific &&  <div className='checkoutNft'>
          <div className='maincheckout'>
          <button className='btn-close' onClick={() => setOpenBuySpecific(false)}>x</button>
              <div className='heading'>
                  <h3>Checkout</h3>
              </div>
            <p>You are about to purchase a <span className="bold">{nftName}</span> 
            <span className="bold"> from <UserName address={nftOwner} length={true}/></span></p>
              <div className='heading mt-3'>
                  <p>Your balance</p>
                  <div className='subtotal'>
                  {balanceTokens[tokens[activeListing.get('currency').toLowerCase()][0]] / 10 ** tokens[activeListing.get('currency').toLowerCase()][1]} {tokens[activeListing.get('currency').toLowerCase()][0]}
                  </div>
              </div>
              {tokens[editionToken.toLowerCase()][0] === "PLS" && <div className='heading'>
                  <p>Your balance (WPLS)</p>
                  <div className='subtotal'>
                  {balanceTokens["WPLS"] / 10 ** 18} WPLS
                  </div>
              </div>}
            <div className='heading'>
              <p>Total</p>
              <div className='subtotal'>
              {price / 10 ** tokens[activeListing.get('currency').toLowerCase()][1]} {tokens[activeListing.get('currency').toLowerCase()][0]}
              </div>
            </div>
            <button className="btn-main" style={{width: "75%"}} onClick={() => setConvert(true)}>Convert PLS/WPLS</button>
              <div className="spacer-10"></div>
              <button onClick={buyNowSpecific} id="buyNow" className='btn-main lead mb-5'>Checkout</button>
              <p id="errorBuy" style={{color: "red"}}></p>
          </div>
          </div>
      }
      { openCheckout &&
          <div className='checkoutNft'>
          <div className='maincheckout'>
          <button className='btn-close' onClick={() => setOpenCheckout(false)}>x</button>
              <div className='heading'>
                  <h3>Confirm</h3>
              </div>
            <p>Are you sure you want to <span className="bold">cancel this listing (requires gas fee)?</span></p>
              <button onClick={removeFromSale} id="remove" className='btn-main lead mb-5'>Cancel listing</button>
              <p id="errorRemove" style={{color: "red"}}></p>
          </div>
          </div>
      }
      { openCancelBid &&
          <div className='checkoutNft'>
          <div className='maincheckout'>
          <button className='btn-close' onClick={() => setOpenCancelBid(false)}>x</button>
              <div className='heading'>
                  <h3>Confirm</h3>
              </div>
            <p>Are you sure you want to <span className="bold">cancel this bid (requires gas fee)?</span></p>
              <button onClick={cancelBid} id="cancel" className='btn-main lead mb-5'>Cancel bid</button>
              <p id="errorCancel" style={{color: "red"}}></p>
          </div>
          </div>
      }
      { convert &&
          <div className='checkoutNft' style={{"z-index": "9000"}}>
          <div className='maincheckout'>
          <button className='btn-close' onClick={() => setConvert(false)}>x</button>
              <div className='heading'>
                  <h3>Convert PLS/WPLS</h3>
              </div>
            <SwapModal/>
          </div>
          </div>
      }
      { openAcceptBid &&
          <div className='checkoutNft'>
          <div className='maincheckout'>
          <button className='btn-close' onClick={() => setOpenAcceptBid(false)}>x</button>
              <div className='heading'>
                  <h3>Confirm</h3>
              </div>
            <p>Are you sure you want to <span className="bold">accept this offer (requires gas fee)?</span></p>
              <button onClick={acceptBid} id="accept" className='btn-main lead mb-5'>Accept offer</button>
              <p id="errorAccept" style={{color: "red"}}></p>
          </div>
          </div>
      }
      
      { openCheckoutbid &&
          <div className='checkoutNft'>
          <div className='maincheckout'>
          <button className='btn-close' onClick={() => setOpenCheckoutbid(false)}>x</button>
              <div className='heading'>
                  <h3>Make an Offer</h3>
              </div>
            <p>You are about to make an offer for <span className="bold">{nftName}</span></p>
            <h5>Payment Token</h5>
                                              <div class="dropdown">
                                                <input class="dropbtn" value={bidToken}></input>
                                                <div class="dropdown-content">
                                                  <button onClick={(e) => {e.preventDefault(); setBidToken("WPLS")}}>WPLS <img width="25" src="../img/pulsechain-logo.png" alt=""></img></button>
                                                  <button onClick={(e) => {e.preventDefault(); setBidToken("HEX")}}>HEX <img width="25" src="../img/hex-logo.png" alt=""></img></button>
                                                  <button>PLSV (COMING SOON)</button>
                                                </div>
                                              </div>

              <div className='detailcheckout mt-4'>
                  <div className='listcheckout'>
                      <h6>
                       Your offer
                      </h6>
                      <input type="text" id="bid_offer" className="form-control" placeholder="Bid Amount"/>
                  </div>
              </div>
              <h5>Validity</h5>
              <div class="dropdown">
                <input class="dropbtn" value={validity}></input>
                <div class="dropdown-content" style={{width: "100%"}} >
                  <button style={{width: "100%"}} onClick={(e) => {e.preventDefault(); setValidity("1 day")}}>1 day</button>
                  <button style={{width: "100%"}} onClick={(e) => {e.preventDefault(); setValidity("3 days")}}>3 days</button>
                  <button style={{width: "100%"}} onClick={(e) => {e.preventDefault(); setValidity("7 days")}}>7 days</button>
                  <button style={{width: "100%"}} onClick={(e) => {e.preventDefault(); setValidity("14 days")}}>14 days</button>
                  <button style={{width: "100%"}} onClick={(e) => {e.preventDefault(); setValidity("90 days")}}>90 days</button>
                  <button style={{width: "100%"}} onClick={(e) => {e.preventDefault(); setValidity("180 days")}}>180 days</button>
                </div>
              </div>
              <div className='heading mt-3'>
                  <p>Your balance</p>
                  <div className='subtotal'>
                  {balanceTokens[bidToken] / 10 ** nameTokensBid[bidToken][1]} {bidToken}
                  </div>
              </div>
              <button className="btn-main" style={{width: "75%"}} onClick={() => setConvert(true)}>Convert PLS/WPLS</button>
              <div className="spacer-10"></div>
              <button onClick={bid} id="bid" className='btn-main lead mb-5'>Make Offer</button>
              <p id="errorBid" style={{color: "red"}}></p>
          </div>
          </div>
      }
      { openCheckoutOnSale &&
          <div className='checkoutNft'>
          <div className='maincheckout'>
          <button className='btn-close' onClick={() => setOpenCheckoutOnSale(false)}>x</button>
              <div className='heading'>
                  <h3>Put on sale</h3>
              </div>
              <><h5>Select method</h5>
                                  <div className="de_tab tab_methods">
                                      <ul className="de_nav">
                                          <li id='btn1' style={{width:"31%"}} className="active" onClick={handleShow}><span><p style={{marginBottom: "0px"}}><i className="fa fa-tag"></i></p><p style={{marginBottom: "0px"}}>Fixed price</p></span>
                                          </li>
                                          <li id='btn2' style={{width:"31%"}} onClick={null}><span><p style={{marginBottom: "0px"}}><i className="fa fa-hourglass-1"></i></p><p style={{marginBottom: "0px"}}>Reserve auction (coming soon)</p></span>
                                          </li>
                                      </ul>

                                      <div className="de_tab_content pt-3">
                                
                                        <div id="tab_opt_1">
                                              <h5>Price</h5>
                                              <input type="text" name="item_price" id="item_price" className="form-control" placeholder={`enter price for one item (${token})`}/>
                                              {isERC1155 && <h5>Tokens up for sale</h5>}
                                              {isERC1155 && <input type="text" name="item_amount" id="item_amount" className="form-control" placeholder={`enter amount to put up for sale`}/>}
                                              <h5>Payment Token</h5>
                                              <div class="dropdown">
                                                <input class="dropbtn" value={token}></input>
                                                <div class="dropdown-content">
                                                  <button onClick={(e) => {e.preventDefault(); setBidToken("PLS")}}>PLS <img width="25" src="../img/pulsechain-logo.png" alt=""></img></button>
                                                  <button onClick={(e) => {e.preventDefault(); setBidToken("HEX")}}>HEX <img width="25" src="../img/hex-logo.png" alt=""></img></button>
                                                  <button>PLSV (COMING SOON)</button>
                                                </div>
                                              </div>
                                              <div className="spacer-20"></div>
                                              <h5>Validity</h5>
                                              <div class="dropdown">
                                                <input class="dropbtn" value={validity}></input>
                                                <div class="dropdown-content" style={{width: "100%"}} >
                                                  <button style={{width: "100%"}} onClick={(e) => {e.preventDefault(); setValidity("1 day")}}>1 day</button>
                                                  <button style={{width: "100%"}} onClick={(e) => {e.preventDefault(); setValidity("3 days")}}>3 days</button>
                                                  <button style={{width: "100%"}} onClick={(e) => {e.preventDefault(); setValidity("7 days")}}>7 days</button>
                                                  <button style={{width: "100%"}} onClick={(e) => {e.preventDefault(); setValidity("14 days")}}>14 days</button>
                                                  <button style={{width: "100%"}} onClick={(e) => {e.preventDefault(); setValidity("90 days")}}>90 days</button>
                                                  <button style={{width: "100%"}} onClick={(e) => {e.preventDefault(); setValidity("180 days")}}>180 days</button>
                                                </div>
                                              </div>
                                        </div>

                                          <div id="tab_opt_2" className='hide'>
                                              <h5>Minimum bid</h5>
                                              <input type="text" name="item_price_bid" id="item_price_bid" className="form-control" placeholder="enter minimum bid"/>
                                              <h5>Payment Token</h5>
                                              <div class="dropdown">
                                                <input class="dropbtn" value={token}></input>
                                                <div class="dropdown-content">
                                                  <button onClick={(e) => {e.preventDefault(); setToken("PLS")}}>PLS <img width="25" src="../img/pulsechain-logo.png" alt=""></img></button>
                                                  <button onClick={(e) => {e.preventDefault(); setToken("HEX")}}>HEX <img width="25" src="../img/hex-logo.png" alt=""></img></button>
                                                  <button>PLSV (COMING SOON)</button>
                                                </div>
                                              </div>
                                              <div className="spacer-20"></div>

                                              <div className="row">
                                                  <div className="col-md-6">
                                                      <h5>Starting date</h5>
                                                      <input type="date" name="bid_starting_date" id="bid_starting_date" className="form-control" min="1997-01-01" />
                                                  </div>
                                                  <div className="col-md-6">
                                                      <h5>Expiration date</h5>
                                                      <input type="date" name="bid_expiration_date" id="bid_expiration_date" className="form-control" />
                                                  </div>
                                              </div>
                                          </div>

                                          <div id="tab_opt_3" className='hide'>
                                              <h5>Payment Token</h5>
                                              <div class="dropdown">
                                                <input class="dropbtn" value={token}></input>
                                                <div class="dropdown-content">
                                                  <button onClick={(e) => {e.preventDefault(); setToken("PLS")}}>PLS <img width="25" src="../img/pulsechain-logo.png" alt=""></img></button>
                                                  <button onClick={(e) => {e.preventDefault(); setToken("HEX")}}>HEX <img width="25" src="../img/hex-logo.png" alt=""></img></button>
                                                  <button>PLSV (COMING SOON)</button>
                                                </div>
                                              </div>
                                              <div className="spacer-20"></div>

                                              <div className="row">
                                                  <div className="col-md-6">
                                                      <h5>Starting date</h5>
                                                      <input type="date" name="bid_starting_date" id="bid_starting_offer" className="form-control" min="1997-01-01" />
                                                  </div>
                                              </div>
                                          </div>

                                      </div>

                                  </div>

                                  <div className="spacer-20"></div></>
              <button className='btn-main lead mb-5' onClick={() => putOnSale(setWalletInit, setOpenSteps)}>Put on sale</button>
          </div>
          </div>
      }
      { transfer &&
          <div className='checkoutNft'>
          <div className='maincheckout'>
          <button className='btn-close' onClick={() => setTransfer(false)}>x</button>
              <div className='heading'>
                  <h3>Transfer</h3>
              </div>
              <div className='detailcheckout mt-4'>
                  <div className='listcheckout'>
                      <h6>
                       Recipitent
                      </h6>
                      <input placeholder="0x00000000000...0000" type="text" id="recipitent" className="form-control"/>
                  </div>
                  <div className='listcheckout'>
                      <h6>
                       Amount
                      </h6>
                      <input placeholder="Amount to transfer (only for ERC1155)" type="text" id="amount" className="form-control"/>
                  </div>
              </div>
              <button className='btn-main lead mb-5' id="transfer" onClick={() => transferHandler(setWalletInit, setOpenSteps, user)}>Transfer</button>
              <p id="errorTransfer" style={{color: "red"}}></p>
          </div>
          </div>
      }
      { changeListing &&
          <div className='checkoutNft'>
          <div className='maincheckout'>
          <button className='btn-close' onClick={() => setChangeListing(false)}>x</button>
              <div className='heading'>
                    {saleKind === "reserve" && <h3>Change reserve price</h3>}
                    {saleKind === "buy" && <h3>Change buy price</h3>}
              </div>
              <div className='detailcheckout mt-4'>
                  <div className='listcheckout'>
                      <h6>
                        Set pricing
                      </h6>
                      {saleKind === "stepped" ? <><input placeholder="base price" type="text" id="baseprice" className="form-control"/>
                      <input placeholder="step price" type="text" id="step" className="form-control"/></> : <input placeholder="New listing price" type="text" id="newprice" className="form-control"/>}

                  </div>
              </div>
              <button className='btn-main lead mb-5' id="listing" onClick={changeListingPrice}>Change</button>
              <p id="errorPrice" style={{color: "red"}}></p>
          </div>
          </div>
      }
      { openSteps &&
          <div className='checkout'>
              {openCheckout && <StepsList walletInit={walletInit}/>}
              {openCheckoutOnSale && <StepsList walletInit={walletInit}/>}
              {openCheckoutbid && <StepsList walletInit={walletInit}/>}
              {transfer && <StepsTransfer walletInit={walletInit}/>}
              <button className='btn-close' onClick={() => setOpenSteps(false)}>x</button>
          </div>
      }
      { wrongNetwork && isAuthenticated &&
          <div className='wrongnet'>
              <WrongNetwork signOut={signOut}/>
          </div>
      }

      </div>
  );

}
export default Colection;